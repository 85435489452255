import React, { useEffect, useState } from "react";
import TitleBar from "../TitleBar";
import { useLocation, useNavigate } from "react-router-dom";
import { BiConversation, BiTrash, } from "react-icons/bi";
import { FaMicrophone, FaRegStopCircle } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { onFetchConStaffData, onFetchMessage, onClearConversation, onCreateConversation, onFetchOptions, onCreateMessage, onFetchHireData, onFetchHiredStaff, unmountStaff } from "../../Actions/StaffAction";
import ConversationPanel from "./ConversationPanel";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import ChatCreateBox from "./ChatCreateBox";
import VoiceTypes from "./VoiceTypes";
import SweetAlertTwo from "../SweetAlertTwo";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import GodModal from "./GodModal";
import { onCheckAPI, onSetInitialInfo, onStopGodMode } from "../../Actions/GodModeAction";


let audio = false
const Chat = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation();
    const auth = useSelector(state => state.auth)
    const searchParams = new URLSearchParams(location.search);
    const sId = searchParams.get('sId');
    const cId = searchParams.get('cId');
    const hId = searchParams.get('hId');

    const staff = useSelector(state => state.staff.data)
    const godMode = useSelector(state => state.godMode)
    const setting = useSelector(state => state.setting.data)
    const { transcript, listening, resetTranscript, browserSupportsSpeechRecognition } = useSpeechRecognition();

    const [pageLoader, setPageLoader] = useState(false)
    const [selectedcId, setSelectedcId] = useState(false);
    const [conversationData, setConversationData] = useState([])

    const [messages, setMessages] = useState([])
    const [messageLoader, setMessageLoader] = useState(false)
    const [typingActive, setTypingActive] = useState(false)
    const [activeBlink, setActiveBlink] = useState(false)
    const [isOpen, setIsOpen] = useState(false)

    const [state, setState] = useState({
        staffId: sId,
        hireId: hId,
        cid: cId ? cId : selectedcId,
        messages: "",
        type: "user",
        user_response_lang: "English",
        user_record_lang: "en-US",
        user_writing_style: "",
        user_tone: "",
        clickPrompt: "",
        services: "",
        audioInResponse: false,
        staffType: staff.staffType
    })

    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)

    const [loader, setLoader] = useState({
        conLoader: false,
        btnLoader: false,
    })

    const [info, setInfo] = useState({
        id: -1,
        enable: false,
    })

    // const [utterance, setUtterance] = useState(new SpeechSynthesisUtterance());

    const handlePlay = (audioFile) => {
        audio = new Audio(audioFile)
        if (audio !== false) {
            audio.play()
        }

    };

    const handleStop = () => {
        if (audio) {
            audio.pause()
            audio = false
        }
        // const synth = window.speechSynthesis;
        // synth.cancel();
    };

    const handleCancel = () => {
        setInfo({
            ...info,
            id: -1,
            enable: false,
        })
    }
    const handleStartChat = (isActiveLoader) => {
        if (isOpen && +staff.isfired === 0 && !godMode.isGodModeActive) {
            if (isActiveLoader) {
                setLoader({ ...loader, btnLoader: true })
            }
            let data = {
                staffId: sId,
                hireId: hId,
                type: staff.type,
                staffType: staff.staffType
            }
            dispatch(onCreateConversation(data, fetchConversation, setSelectedcId, setActiveBlink, loader, setLoader))
        }
    }


    //Clear Chat Messages
    const clearConversation = (Swal) => {
        let data = {
            cid: selectedcId,
            staffId: sId,
            hireId: hId,
        }
        dispatch(onClearConversation(data, fetchMessage, Swal))
        setInfo({
            ...info,
            id: -1,
            enable: false,
        })
    }

    const fetchConversation = () => {
        setLoader({ ...loader, conLoader: true })
        let data = {
            staffId: sId,
            hireId: hId
        }
        dispatch(onFetchConStaffData(data, setConversationData, handleStartChat, setLoader, loader))
    }


    const handleKeyDown = (e) => {
        if (setting.isEnter) {
            if (e.key === 'Enter' && !e.isShiftKey) {
                handleSubmit(e)
            }
        }
    }

    const getOpeningTime = () => {
        if (setting.openingHours.length > 0) {

            const now = new Date();
            const dayOfWeek = now.getDay()
            const currentHour = now.getHours();
            const todayHours = setting.openingHours[dayOfWeek]

            if (todayHours.enable) {
                const fromHour = todayHours.fromMeridiem === "AM" ? todayHours.fromTime === "12:00" ? 0 : +todayHours.fromTime.split(":")[0] :
                    todayHours.fromTime === "12:00" ? 12 : +todayHours.fromTime.split(":")[0] + 12;

                const toHour = todayHours.toMeridiem === "AM" ? todayHours.toTime === "12:00" ? 0 : +todayHours.toTime.split(":")[0] :
                    todayHours.toTime === "12:00" ? 24 : +todayHours.toTime.split(":")[0] + 12;

                if (toHour < fromHour) {
                    if (currentHour >= fromHour || currentHour < toHour) {
                        setIsOpen(true)
                    } else {
                        setIsOpen(false)
                    }
                }
                else if (toHour === fromHour) {
                    if (currentHour < fromHour) {
                        setIsOpen(false)
                    } else {
                        setIsOpen(true)
                    }
                }
                else {
                    if (currentHour >= fromHour && currentHour < toHour) {
                        setIsOpen(true)
                    } else {
                        setIsOpen(false)
                    }
                }
            }
        }
    }


    const handleSubmit = (e) => {
        handleStop()
        if (e) {
            e.preventDefault()
        }
        if (state.messages !== "" || state.services !== "") {
            let obj = {
                messages: state.messages,
                type: state.type,
            }
            messages.push(obj)
            let scrollDiv = document.getElementById("chat-data-box")
            setTimeout(() => {
                if (scrollDiv.clientHeight < scrollDiv.scrollHeight) {
                    scrollDiv.scrollBy(0, scrollDiv.scrollHeight)
                }
            }, 1000)
            setState({
                ...state,
                messages: "",
                services: "",
                clickPrompt: "",
                audioInResponse: false
            })
            setTimeout(() => {
                setTypingActive(true)
            }, 3000)
            dispatch(onCreateMessage(state, messages, setMessages, setTypingActive, conversationData, setConversationData, setSelectedcId, setActiveBlink, handlePlay))
        }
    }

    const handleChange = (e) => {
        if (audio) {
            handleStop()
        }
        setState({
            ...state,
            messages: e.target.value
        })
    }

    const showDeleteConversation = () => {
        if (selectedcId) {
            setInfo({
                ...info,
                enable: true
            })
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please select conversation to clear it!',
                confirmButtonColor: "#d03e3e"
            })
        }
    }


    const fetchMessage = () => {
        let data = {
            staffId: sId,
            hireId: hId,
            cid: selectedcId,
            staffType: staff.staffType
        }
        setMessageLoader(true)
        dispatch(onFetchMessage(data, setMessages, setMessageLoader))
    }

    const handleGodMode = () => {
        dispatch(onCheckAPI(setShow, selectedcId, staff, Swal, navigate))
        // setShow(true)
        // dispatch(onSetInitialInfo(selectedcId, staff))
    }
    const handleStopGodMode = () => {
        dispatch(onStopGodMode())
    }

    useEffect(() => {
        if (staff) {
            setState({
                ...state,
                cid: selectedcId,
                user_writing_style: staff.hire_style,
                user_tone: staff.hire_tone,
                role: staff.hire_category,
                staffType: staff.staffType
            })
            if (selectedcId && staff?.staffType) {
                fetchMessage()
            }
        }
    }, [selectedcId, staff])

    useEffect(() => {
        if (hId) {
            fetchStaff()
        }
    }, [hId])

    useEffect(() => {
        if (staff) {
            fetchConversation()
        }
    }, [staff])

    useEffect(() => {
        if (cId) {
            setSelectedcId(cId)
        }
    }, [cId])

    useEffect(() => {
        if (transcript) {
            setState({
                ...state,
                messages: transcript
            })
        }
    }, [transcript])


    useEffect(() => {
        if (setting.isDataFetch) {
            getOpeningTime()
        }
    }, [setting])

    const fetchStaff = () => {
        let data = {
            hireId: hId
        }
        dispatch(onFetchHiredStaff(data, setPageLoader))
    }

    let div = document.getElementById("beacon-container")

    useEffect(() => {
        if (div) {
            div.style.display = "none"
        }

        return () => {
            if (div) {
                div.style.display = "block"
            }
        }
    }, [div])


    const startInterval = (e) => {
        setTimeout(() => {
            let scrollDiv = document.getElementById("chat-data-box")
            if (scrollDiv.clientHeight < scrollDiv.scrollHeight) {
                scrollDiv.scrollBy(0, scrollDiv.scrollHeight)
            }
        }, 1000);
    }

    useEffect(() => {
        if (!listening && setting.autoSubmit === true) {
            handleSubmit()
        }
    }, [listening])

    useEffect(() => {
        return () => {
            dispatch(unmountStaff())
            dispatch(onStopGodMode())
            if (audio) {
                handleStop()
            }
        }
    }, [])

    return (
        <>
            <TitleBar title="Chat" />
            {pageLoader ?
                <section className="chat-wrap" style={{ background: godMode.isGodModeActive ? "#C99200" : "" }}>
                    <div className="chat-left">
                        <div className="chat_profile">
                            <span className="chat_pro_img mb-2">
                                <img src={staff.hire_image} alt="" />
                            </span>
                            <span className="chat_pro_name mb-1 text-capitalize" style={{ fontSize: 15, fontWeight: "bold" }}>
                                {staff.hire_name}
                            </span>
                            <span className="chat_pro_category mb-3  text-capitalize" style={{ fontSize: 13 }}>
                                {staff.hire_category}
                            </span>
                            {godMode.isGodModeActive ?
                                <span className="chat_pro_category mb-3  text-capitalize" style={{ fontSize: 20, fontWeight: "bold" }}>
                                    God Mode Enabled
                                </span> : ""}

                        </div>
                        <div className="new-chat">
                            {+auth.user.isClient === 1 ? "" :
                                <button
                                    className="demoLink full mt-0"
                                    style={{ background: isOpen && staff.isfired === "0" && !godMode.isGodModeActive ? "linear-gradient(to right, var(--primary-btn-color) 0%, var(--secondary-btn-color) 100%)" : "rgb(159 157 157)" }}
                                    onClick={() => handleStartChat(true)}
                                >
                                    <BiConversation />
                                    {loader.btnLoader ? <>Creating <i className="fa fa-spinner fa-spin mx-1" /> </> : "New Conversation"}
                                </button>}
                        </div>

                        <div className="chat-scroll">
                            <div className="chat-list">
                                {
                                    conversationData.length > 0 ?
                                        conversationData.map((curElem, ind) => {
                                            return (
                                                <React.Fragment key={ind}>
                                                    <ConversationPanel
                                                        curElem={curElem}
                                                        conversationId={curElem.id}
                                                        selectedcId={selectedcId}
                                                        setSelectedcId={setSelectedcId}
                                                        fetchConversation={fetchConversation}
                                                        fetchMessage={fetchMessage}
                                                        firstTileId={conversationData[0].id}
                                                        setConversationData={setConversationData}
                                                    />
                                                </React.Fragment>
                                            )
                                        })
                                        : loader.conLoader ?
                                            <div className="text-center mt-1 text-light">
                                                <i className="fa fa-spinner fa-spin" style={{ fontSize: "30px", color: "#c238a4", marginTop: "50px" }} />
                                            </div> : ""
                                }
                            </div>
                        </div>

                        <div className="clear-conv">
                            {+auth.user.isClient === 1 ? "" :
                                <button
                                    disabled={conversationData.length === 0 || godMode.isGodModeActive ? true : false}
                                    className="btnGrey full"
                                    onClick={() => showDeleteConversation()}
                                ><BiTrash />
                                    Clear conversations
                                </button>
                            }
                        </div>
                    </div>

                    <div className="chat-right">
                        {/* {!messageLoader ?
                            <div style={{ display: "flex", height: "calc(100vh - 270px)", justifyContent: "center", alignItems: "center" }}>
                                <div className="loader">
                                </div>
                            </div>
                            :
                            <> */}
                        <div className="chat-area" style={{ display: messageLoader ? "block" : "" }}>
                            {messageLoader ?
                                <div style={{ display: "flex", height: `calc(100vh - ${+staff.staffType === 2 ? "165px" : "270px"})`, justifyContent: "center", alignItems: "center" }}>
                                    <div className="loader">
                                    </div>
                                </div> :
                                <ChatCreateBox
                                    messages={messages}
                                    setMessages={setMessages}
                                    setTypingActive={setTypingActive}
                                    setActiveBlink={setActiveBlink}
                                    activeBlink={activeBlink}
                                    typingActive={typingActive}
                                    selectedcId={selectedcId}
                                    staff={staff}
                                    conversationData={conversationData}
                                />}
                        </div>


                        <div style={{ position: "relative" }}>
                            {+auth.user.isClient === 1 ? "" :
                                <div className="chat-area-bottom">
                                    <div className="chat-area-bottom-wrap">
                                        <div className="chat-bottom-bar">
                                            {+staff.staffType === 1 ?
                                                <div className="chat-bottom-left">
                                                    {listening ?
                                                        <button
                                                            className={`chat_record_btn full mt-0 ${listening ? "speech-record-anim" : ""}`}
                                                            onClick={() => SpeechRecognition.stopListening().then(() => {
                                                                resetTranscript()
                                                                handleStop()
                                                                setState({
                                                                    ...state,
                                                                    audioInResponse: true
                                                                })
                                                            })}
                                                        >
                                                            <FaRegStopCircle />
                                                        </button>
                                                        :
                                                        <button
                                                            className={`chat_record_btn full mt-0 ${listening ? "speech-record-anim" : ""}`}
                                                            onClick={() => SpeechRecognition.startListening(
                                                                {
                                                                    continuous: setting.autoSubmit === true ? false : true,
                                                                    language: "en-US"
                                                                }
                                                            ).then(() => {
                                                                handleStop()
                                                                setState({
                                                                    ...state,
                                                                    audioInResponse: true
                                                                })
                                                            })}
                                                        >
                                                            <FaMicrophone />
                                                        </button>
                                                    }
                                                </div> : ""}
                                        </div>
                                        <form className="chat-inp" onSubmit={handleSubmit}>
                                            <div className="god_mode" >
                                                <div className="god_mode_btn" type="button" onClick={() => handleGodMode()}>
                                                    <span className="icon"><i className="fa-sharp fa-regular fa-comment-dots"></i></span>
                                                    <span className="text"> Enable God Mode</span>
                                                </div>
                                            </div>
                                            <a href="#" className="button">

                                            </a>
                                            <textarea
                                                value={state.messages}
                                                onChange={handleChange}
                                                className="chat_typing_box"
                                                onKeyDown={handleKeyDown}
                                                rows={+(state.messages.length / 100) > 1 ? +(state.messages.length / 100) > 4 ? 4 : +(state.messages.length / 100) : 1}
                                            />

                                            <button
                                                className="chat-send"
                                                type="submit"
                                                style={{ background: "none", outline: "none", border: "none" }}
                                            >
                                                <img src={require('../../images/chat-send.png')} />
                                            </button>
                                        </form>
                                        {+staff.staffType === 1 ?
                                            <div className="option-group">
                                                <VoiceTypes
                                                    state={state}
                                                    setState={setState}
                                                    sId={sId}
                                                    handleSubmit={handleSubmit}
                                                />
                                            </div>
                                            : ""}
                                        <div className="Chat_disclaimer">
                                            <p className="mb-0">AIStaffs app & its agents may display inaccurate or offensive information about people, places, or facts that doesn’t represent VineaSX's views.</p>
                                            <p>Response can take upto 5-10 minutes.</p>
                                        </div>
                                    </div>
                                </div>}
                            {godMode.isGodModeActive ?
                                <div className="chat-area-bottom-disable text-light text-center">
                                    <div>
                                        <h4>God Mode Enabled</h4>
                                        <button className="demoLink" onClick={handleStopGodMode}>Stop</button>
                                    </div>
                                </div>

                                : ""
                            }

                            {+auth.user.isClient === 1 ? "" :
                                isOpen && +staff.isfired === 0 ? "" :
                                    <div className="chat-area-bottom-disable">
                                        {+staff.isfired === 1 ?
                                            <span>Hire staff first for continue chatting.</span>
                                            :
                                            <span>This is not opening hour.</span>
                                        }
                                    </div>
                            }
                        </div>

                    </div>
                    <SweetAlertTwo
                        show={info.enable}
                        title="Are You Sure?"
                        text="You won't be able to revert this!"
                        icon="warning"
                        showCancelButton={true}
                        confirmButtonColor='#b32f3d'
                        cancelButtonColor='#2a2c3a'
                        confirmButtonText='Yes, clear it!'
                        confirmTitle='Clearing...'
                        confirmText="This won't be revert!"
                        confirmIcon='success'
                        handlePerform={clearConversation}
                        onCancel={handleCancel}
                    />
                </section>
                :
                <div className="loader-sec">
                    <div className="loader">
                    </div>
                </div>
            }

            <GodModal
                show={show}
                handleClose={handleClose}
                selectedcId={selectedcId}
                startInterval={startInterval}
                messages={messages}
                setMessages={setMessages}
            />

        </>
    )
}

export default Chat;