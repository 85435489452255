import { commonAxios } from "../Global/CommonAxios"
import { setAlert } from "./AlertAction"

export const fetchAccData = (setTeamData, setClientData) => (dispatch, getState) => {
    commonAxios("fetch-clients", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setTeamData(res.data.team_members)
                setClientData(res.data.clients)
            }
        }).catch((err) => {
            console.log(err)
        })
}

export const addAccData = (userDetails, setUserDetails, fetchClientTeam, setLoader, setPasswordMsg, passwordMsg) => (dispatch, getState) => {
    commonAxios("create-client", userDetails, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, 'success'));
                fetchClientTeam()
                setUserDetails({
                    ...userDetails,
                    type: 'team',
                    email: '',
                    name: '',
                    password: '',
                    conversationsId: ""
                })
            } else {
                dispatch(setAlert(res.msg, 'danger'));
            }
            setLoader(false)
            setPasswordMsg({ ...passwordMsg, validation: false, msg: "" })
        }).catch((err) => {
            console.log(err)
            setLoader(false)
        })

}

export const onEditClient = (accountDetails, fetchClientTeam, setLoader, setToggle, setPasswordMsg, passwordMsg) => (dispatch, getState) => {
    commonAxios("edit-client-account", accountDetails, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchClientTeam()
                dispatch(setAlert(res.msg, 'success'));
            } else {
                dispatch(setAlert(res.msg, 'danger'));
            }
            setLoader(false);
            setToggle(false)
            setPasswordMsg({ ...passwordMsg, validation: false, msg: "" })
        }).catch((err) => {
            console.log(err)
            setLoader(false);
        })
}


export const onDeleteAcc = (data, fetchClientTeam, setLoader, Swal) => (dispatch, getState) => {
    commonAxios("delete-team", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchClientTeam()
                Swal.close()
                dispatch(setAlert(res.msg, 'success'))
            } else {
                dispatch(setAlert(res.msg, 'danger'))
            }
            setLoader(false)
        }).catch((err) => {
            dispatch(setAlert(err.msg, 'danger'))
            setLoader(false);
        })

}

export const onFetchConversation = (data, setConversationsOptions) => (dispatch, getState) => {
    commonAxios("view-conversation", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let arr = [];
                if (res.data.conversations.length > 0) {
                    res.data.conversations.map((curElem) => {
                        arr.push({ label: curElem.name, value: curElem.id });
                    });
                    // arr.unshift({ label: "Select Conversations", value: "" })
                }
                setConversationsOptions(arr);
            } else {
                setConversationsOptions([]);
            }
        })
        .catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
        })
}
