import React, { useEffect, useState } from 'react';
import ContentEditable from 'react-contenteditable';
import { HiDotsHorizontal } from "react-icons/hi"
import { MdOutlineDownloadForOffline, MdFileCopy } from "react-icons/md"
import { IoMdGlobe } from "react-icons/io"
import { onGenerateImage, onInternetAccess } from '../../Actions/StaffAction';
import { useDispatch } from 'react-redux';
import { usePDF } from '@react-pdf/renderer';
import { Document, Page, View, Text, StyleSheet, Font } from '@react-pdf/renderer';
import Mukta from "../../fonts/Mukta-Regular.ttf"
import { BsArrowDownCircleFill } from 'react-icons/bs';
import html2pdf from 'html2pdf.js/dist/html2pdf.min';

let interval = false, typingIndex = 0
Font.register({ family: 'Mukta', src: Mukta });

const ChatBox = ({ curElem, auth, index, length, activeBlink, setActiveBlink, typingActive, staff, selectedcId, messages, setMessages, setTypingActive, showIntTyping, setShowIntTyping, conversationData, }) => {
    const dispatch = useDispatch()
    let arr = ["reading", "thinking", "analysing", "researching", "checking on internet", "typing"]
    const [selectedMsg, setSelectedMsg] = useState(false)
    const [text, setText] = useState("")
    const [resMes, setResMes] = useState(arr[0])
    const [typingInterval, setTypingInterval] = useState(false)
    const [pdf, setPdf] = useState(false)
    const [loader, setLoader] = useState({
        image: false,
        selectedIndex: -1
    })
    const [instance, updateInstance] = usePDF({
        document: pdf
    })

    const handleGeneratePDF = (text) => {
        const styles = StyleSheet.create({
            section: {
                padding: 5,
                flexGrow: 1,
                fontSize: 10,
                border: "1px solid black"
            },
            footer: {
                fontSize: 11,
                position: "absolute",
                bottom: 11,
                right: 12,
            }
        })
        setPdf(<Document >
            <Page style={{ padding: 10 }}>
                <View style={styles.section}>
                    <View style={{ marginBottom: "10px", paddingLeft: "5px", fontFamily: "Mukta" }}>
                        <Text>{text}</Text>
                    </View>
                    <View style={styles.footer}>
                        <Text>This PDF is generated by AiStaffs.</Text>
                    </View>
                </View>
            </Page>
        </Document >)
    }

    useEffect(() => {
        if (pdf && (index === selectedMsg)) {
            updateInstance({
                document: pdf
            })
        }
    }, [pdf])

    useEffect(() => {
        if (instance.url && (index === selectedMsg)) {
            let a = document.createElement('a')
            a.href = instance.url
            a.download = staff.hire_name + 7 + ".pdf"
            a.click()
            setSelectedMsg(false)
        }
    }, [instance])

    const handleAction = (type, ind) => {
        let chatName = conversationData.find((ele) => +ele.id === +selectedcId)
        let copyText = document.getElementById(`message${index}`)
        if (type === "copy") {
            let p = document.createElement("textarea")
            let data = copyText.firstChild.innerHTML
            data = data.replaceAll(`<pre style="background-color:black;color:white;width:100%,overflow-x:scroll;border-radius:10px;padding:10px">`, "").replaceAll(`<pre style="background-color:black;color:white">`, "").replaceAll("</pre>", "").replaceAll("&lt;", "<").replaceAll("&gt;", ">").replaceAll("<br>", "\n");
            p.value = data
            p.select();
            p.setSelectionRange(0, 99999);
            navigator.clipboard.writeText(p.value);
            p.remove()
        } else if (type === "doc") {
            let documentName = staff.hire_name + chatName.name
            let fileDownload = document.createElement("a");
            let data = copyText.firstChild.innerHTML
            data = data
                .replaceAll(`<pre style="background-color:black;color:white;width:100%,overflow-x:scroll;border-radius:10px;padding:10px">`, "")
                .replaceAll(`<pre style="background-color:black;color:white">`, "")
                .replaceAll("</pre>", "").replaceAll("<br>", "\n")
                .replaceAll("&lt;", "\u2039").replaceAll("&gt;", "\u203A")
            fileDownload.href = `data:application/vnd.ms-word;charset=utf-8,` + encodeURIComponent(data)
            fileDownload.download = `${documentName}.doc`;
            fileDownload.click();

        } else {
            //handleGeneratePDF(copyText.firstChild.innerText)

            //------------Rehan Codes Start Here------------

            printHandle(copyText.firstChild.innerHTML);
            setSelectedMsg(ind)
        }
    }
    //-------Rehan PDF CODE--------

    const formatText = (text) => {
        const regex = /(.{70})/g;
        let data = text.replaceAll(/</g, '&lt;').replaceAll(/>/g, '&gt;');
        data = data.replaceAll(regex, '$1<br>')
        data = data.replaceAll(`&lt;pre style="background-color:black;color:white;width:100%,overflow-<br>x:scroll;border-radius:10px;padding:10px"&gt;`, "").replaceAll("&lt;/pre&gt;", "");

        // console.log(data.getS);
        return data
    };
    ////////////////////////////////////////////
    function displayTextWithLineBreaks(text, font) {
        let canvas = displayTextWithLineBreaks.canvas || (displayTextWithLineBreaks.canvas = document.createElement("canvas"));
        let context = canvas.getContext("2d");
        context.font = font;

        const maxLineWidth = 700; // 20px width constraint
        text = text.replaceAll(`<pre style="background-color:black;color:white;width:100%,overflow-x:scroll;border-radius:10px;padding:10px">`, "")
        text = text.replaceAll(`<pre style="background-color:black;color:white">`, "")
        text = text.replaceAll("</pre>", "");
        //console.log(text);
        let words = text.split(' ');
        let lines = [];
        let currentLine = '';

        words.forEach(word => {
            const wordWidth = context.measureText(word).width;
            const currentLineWidth = context.measureText(currentLine).width;

            if (currentLine === '' || currentLineWidth + wordWidth < maxLineWidth) {
                currentLine += (currentLine === '' ? '' : ' ') + word;
            } else {
                lines.push(currentLine);
                currentLine = word;
            }
        });

        lines.push(currentLine);
        //console.log(lines);
        for (let index = 0; index < lines.length; index++) {
            // if (lines[index].includes(`<pre style="background-color:black;color:white;width:100%,overflow-<br>x:scroll;border-radius:10px;padding:10px";>`)) {
            //     console.log(1);
            // } else {
            //     console.log(0);
            // }
            //console.log(lines[index]);
        }
        //console.log(lines.length);
        //let formattedText = lines.replaceAll(`style="background-color:black;color:white;width:100%,overflow-<br>x:scroll;border-radius:10px;padding:10px";`, "").replaceAll("</pre>;", "");
        let formattedText = lines.join(' <br /> ');
        //console.log(formattedText);


        return formattedText;
    }


    ////////////////////////////////////////////
    const printHandle = (messageText) => {
        const element = document.createElement('div');
        element.innerHTML = `<pre >${displayTextWithLineBreaks(messageText, "20px Arial")}</pre><br><p style="text-align:right;z-index:1;position:absolute;bottom:10px;right:10px"><b style="color:black;">This Pdf is generated by AiStaff</b></p>`;
        // element.innerHTML = `<pre >${formatText(messageText)}</pre><br><p style="text-align:right;z-index:1;position:absolute;bottom:10px;right:10px"><b style="color:black;">This Pdf is generated by AiStaff</b></p>`;
        element.style.fontFamily = 'Arial';
        element.style.border = "2px solid black"
        element.style.fontSize = '15px';
        element.style.color = 'black';
        element.style.padding = '10px';
        element.style.minHeight = "165vh"
        element.style.whiteSpace = 'pre-wrap';
        const options = {
            margin: 10,
            filename: staff.hire_name,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        };
        html2pdf().set(options).from(element).save();
    }
    /////////////////////////////////////////////////////////////////


    const InternetAccess = (index) => {
        if (index !== 0) {
            setTypingActive(true)
            setShowIntTyping(true)
            let data = {
                cid: selectedcId,
                staffId: staff.staffId,
                type: staff.type,
                hireId: staff.hire_id,
                message: messages[index - 1].messages
            }
            let obj = {
                messages: messages[index - 1].messages,
                type: "user"
            }
            messages.push(obj)
            let scrollDiv = document.getElementById("chat-data-box")
            if (scrollDiv.clientHeight < scrollDiv.scrollHeight) {
                scrollDiv.scrollBy(0, scrollDiv.scrollHeight)
            }
            dispatch(onInternetAccess(data, setTypingActive, setShowIntTyping, setActiveBlink, messages))
        }
    }

    const handleGenerateImage = (val, graphicIndex) => {
        let data = {
            id: val.id,
            prompts: val.messages ? val.messages : val.prompts,
            cId: selectedcId,
            hireId: staff.hire_id
        }
        setLoader({
            ...loader,
            image: true,
            selectedIndex: graphicIndex
        })
        dispatch(onGenerateImage(data, messages, setMessages, index, graphicIndex, loader, setLoader))
    }

    const handleDownloadImage = (e, val) => {
        e.stopPropagation()
        let a = document.createElement('a')
        a.href = val
        a.target = "_blank"
        a.download = 'Download.png';
        a.click()
    }

    useEffect(() => {
        if (typingActive && (index === length - 1)) {
            setTypingInterval(
                setInterval(() => {
                    setResMes(arr[typingIndex])
                    typingIndex++
                    if (typingIndex === 6) {
                        typingIndex = 0
                    }
                }, 5000)
            )
        } else {
            typingIndex = 0
            clearInterval(typingInterval)
            setTypingInterval(false)
        }

        return () => {
            clearInterval(typingInterval)
            setTypingInterval(false)
        }

    }, [typingActive])

    useEffect(() => {
        return () => {
            clearInterval(typingInterval)
            setTypingInterval(false)
        }
    }, [])

    // useEffect(() => {
    //     if (curElem.type === "assistant" && activeBlink) {
    //         setPdf(curElem.messages)
    //         let arr = new Array(curElem.messages)
    //         let i = 0
    //         let finalText = ""
    //         if (length - 1 === index) {
    //             interval = setInterval(() => {
    //                 finalText += arr[0][i]
    //                 setText(finalText)
    //                 i++
    //                 let scrollDiv = document.getElementById("chat-data-box")
    //                 if (scrollDiv.clientHeight < scrollDiv.scrollHeight) {
    //                     scrollDiv.scrollBy(0, scrollDiv.scrollHeight)
    //                 }
    //                 if (i >= curElem.messages.length) {
    //                     setActiveBlink(false)
    //                     clearInterval(interval)
    //                     interval = false
    //                 }
    //             }, 20)
    //         }
    //         return () => {
    //             clearInterval(interval)
    //         }
    //     }
    // }, [curElem])

    useEffect(() => {
        if (curElem.type === "assistant" && activeBlink) {
            setPdf(curElem.messages);
            let arr = new Array(curElem.messages);
            let i = 0;
            let finalText = "";
            if (length - 1 === index) {
                interval = setInterval(() => {
                    finalText += arr[0][i];
                    setText(finalText);
                    i++;
                    let scrollDiv = document.getElementById("chat-data-box");
                    const isScrolledToBottom = scrollDiv.clientHeight + scrollDiv.scrollTop >= scrollDiv.scrollHeight - 10;

                    if (isScrolledToBottom) {
                        scrollDiv.scrollBy(0, scrollDiv.scrollHeight);
                    }

                    if (i >= curElem.messages.length) {
                        setActiveBlink(false);
                        clearInterval(interval);
                        interval = false;
                    }
                }, 20);
            }
            return () => {
                clearInterval(interval);
            };
        }
    }, [curElem]);


    return (
        curElem.type === "user" ?
            <>
                <div className="chat-single bot" style={{ marginBottom: typingActive && (length - 1 === index) ? "10px" : "" }}>
                    <div className="chat-name"><img src={auth.user.image} alt="" /></div>
                    <div className="chat-txt">
                        <div className="chat-txt-in">
                            <ContentEditable
                                html={curElem.messages.replaceAll('\n', '<br />').replaceAll("```html", "<pre>").replaceAll("```css", "<pre>").replaceAll("```", "</pre>")}
                                disabled={true}
                                tagName='span'
                            // dangerouslySetInnerHTML={{__html:curElem.messages}}
                            />
                        </div>
                    </div>
                </div>

                {typingActive && (length - 1 === index) ?
                    <p style={{ color: "#d7d5d5", display: "flex", marginBottom: "2px", paddingBottom: 5, fontSize: "14px" }}>
                        {showIntTyping ?
                            <span className="mx-1">Accessing Internet</span>
                            :
                            <> <span className="text-capitalize mx-1">{staff.hire_name} </span>is {resMes}</>
                        }

                        <div className="typing">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </p> : ""}
            </>
            :
            <div className="chat-single ">
                <div className="chat-txt" style={{ position: "relative" }}>
                    <div className="chat-txt-in">
                        <div className="chat-txt-inner" id={`message${index}`}>
                            {+staff.staffType === 1 ?
                                <>
                                    <ContentEditable
                                        html={length - 1 === index && activeBlink ? text.replaceAll('\n', '<br />').replaceAll("```html", "<pre>").replaceAll("```css", "<pre>").replaceAll("```", "</pre>").replaceAll("</pre>vba", `<pre style="background-color:black;color:white">`).replaceAll("End Sub", "End Sub</pre>") : curElem.messages.replaceAll('\n', '<br />').includes("```html") ? curElem.messages.replaceAll("```html", "<pre>").replaceAll("```css", "<pre>").replaceAll("```", "</pre>").replaceAll("\n", "<br />") : curElem.messages.includes("</pre>vba") ? curElem.messages.replaceAll("</pre>vba", `<pre style="background-color:black;color:white">`).replaceAll("End Sub", "End Sub</pre>") : curElem.messages}
                                        disabled={true}
                                        tagName='span'
                                    // dangerouslySetInnerHTML={{__html:curElem.messages}}
                                    />
                                    {curElem.sources && !interval && curElem.sources !== "" ?
                                        curElem.sources.link !== "" ?
                                            <p className='mt-3 access-link'>
                                                <a href={curElem.sources.link} target='_blank' >Source 1</a>
                                            </p>
                                            : ""
                                        : ""
                                    }
                                </> :
                                index === 0 ?
                                    <ContentEditable
                                        html={length - 1 === index && activeBlink ? text.replaceAll('\n', '<br />').replaceAll("```html", "<pre>").replaceAll("```css", "<pre>").replaceAll("```", "</pre>").replaceAll("</pre>vba", `<pre style="background-color:black;color:white">`).replaceAll("End Sub", "End Sub</pre>") : curElem.messages.replaceAll('\n', '<br />').replaceAll("```html", "<pre>").replaceAll("```css", "<pre>").replaceAll("```", "</pre>").replaceAll("</pre>vba", `<pre style="background-color:black;color:white">`).replaceAll("End Sub", "End Sub</pre>")}
                                        disabled={true}
                                        tagName='span'
                                    />
                                    :
                                    <>

                                        <ContentEditable
                                            html={length - 1 === index && activeBlink ? text.replaceAll('\n', '<br />').replaceAll("```html", "<pre>").replaceAll("```css", "<pre>").replaceAll("```", "</pre>").replaceAll("</pre>vba", `<pre style="background-color:black;color:white">`).replaceAll("End Sub", "End Sub</pre>") : curElem.messages.replaceAll('\n', '<br />').replaceAll("```html", "<pre>").replaceAll("```css", "<pre>").replaceAll("```", "</pre>").replaceAll("</pre>vba", `<pre style="background-color:black;color:white">`).replaceAll("End Sub", "End Sub</pre>")}
                                            disabled={true}
                                            tagName='span'
                                        />
                                        {
                                            length - 1 === index && activeBlink ?
                                                ""
                                                :
                                                curElem.graphics.length > 0 ?
                                                    curElem.graphics.map((msg, index) => {
                                                        return (
                                                            <div
                                                                onClick={() => handleGenerateImage(msg, index)}
                                                                className='cursor-pointer graphic-text my-3'
                                                                key={index}
                                                            >
                                                                <ContentEditable
                                                                    html={msg.prompts ? msg.prompts : msg.messages}
                                                                    disabled={true}
                                                                    tagName='span'
                                                                />
                                                                <br />
                                                                <br />
                                                                <div className='text-center my-2'>
                                                                    {loader.image && loader.selectedIndex === index ?

                                                                        <i className="fa fa-spinner fa-spin" style={{ color: "#fff", fontSize: 20 }} />
                                                                        :
                                                                        msg.image !== "" && msg.image ?
                                                                            <div>
                                                                                <img src={msg.image} alt="" width={"70%"} /><br />
                                                                                <button className='demoLink' onClick={(e) => handleDownloadImage(e, msg.image)}>Download</button>
                                                                            </div>
                                                                            : ""
                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                    :
                                                    <ContentEditable
                                                        html={length - 1 === index && activeBlink ? text.replaceAll('\n', '<br />').replaceAll("```html", "<pre>").replaceAll("```css", "<pre>").replaceAll("```", "</pre>") : curElem.messages.replaceAll('\n', '<br />').replaceAll("```html", "<pre>").replaceAll("```css", "<pre>").replaceAll("```", "</pre>")}
                                                        disabled={true}
                                                        tagName='span'
                                                    />
                                        }
                                    </>


                            }
                            <span className={(activeBlink && (index === length - 1) && curElem.type === "assistant") ? "typed" : ""}></span>
                        </div>
                    </div>
                    {+staff.staffType === 1 ?
                        <div className="dropdown" style={{ position: "absolute", top: "0", right: "30px" }}>
                            <div className="dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                <HiDotsHorizontal style={{ fontSize: "25px", color: "#fff" }} />
                            </div>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <span
                                    className="dropdown-item d-flex  cursor-pointer"
                                    onClick={() => handleAction("copy")}
                                    style={{ fontSize: "16px", color: "#000", fontFamily: "arial", fontWeight: "500", }}
                                >
                                    <MdFileCopy className='mr-2' size={20} />  COPY
                                    {/* <i className="fa-sharp fa-solid fa-copy"></i> */}
                                </span>
                                <span
                                    className="dropdown-item cursor-pointer"
                                    onClick={() => handleAction("doc")}
                                    style={{ fontSize: "15px", color: "#000", fontFamily: "arial", fontWeight: "500" }}
                                >
                                    <MdOutlineDownloadForOffline className='mr-1' size={20} />   DOC
                                </span>
                                <span
                                    className="dropdown-item cursor-pointer"
                                    onClick={() => handleAction("pdf", index)}
                                    style={{ fontSize: "15px", color: "#000", fontFamily: "arial", fontWeight: "500" }}
                                >
                                    <MdOutlineDownloadForOffline className='mr-1' size={20} />   PDF
                                </span>
                                {index !== 0 && auth.user.isClient === "0" ?
                                    <span
                                        className="dropdown-item cursor-pointer"
                                        onClick={() => InternetAccess(index)}
                                        style={{ fontSize: "15px", color: "#000", fontFamily: "arial", fontWeight: "500" }}
                                    >
                                        <IoMdGlobe className='mr-1' size={20} />   Access Internet
                                    </span> : ""}
                            </div>
                        </div> : ""}
                </div>
                <div className="bot-name"><img src={staff.hire_image} /></div>
            </div >
    )
}

export default ChatBox;