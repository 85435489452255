import { commonAxios } from "../Global/CommonAxios"
import { setAlert } from "./AlertAction"

export const onfetchFolder = (setState, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-training", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState(res.data)
            } else {
                setState([])
            }
            if (setLoader) {
                setLoader(false)
            }
        }).catch((err) => {
            if (setLoader) {
                setLoader(false)
            }
            console.log(err)
        })
}

export const onCreateFolder = (data, handleClose, fetchFolders, setLoader) => (dispatch, getState) => {
    commonAxios("create-training", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchFolders()
                handleClose()
                dispatch(setAlert(res.msg, "success"))
            }
            else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err)
            setLoader(false)
        })
}

export const onDeleteFolder = (data, fetchFolders, Swal) => (dispatch, getState) => {
    commonAxios("delete-training", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchFolders()
            }
            else {
                dispatch(setAlert(res.msg, "danger"))
            }
            Swal.close()
        }).catch((err) => {
            console.log(err)
            Swal.close()
        })
}
export const onWriteDoc = (data, fetchDocList, setDataToShow, setLoader) => (dispatch, getState) => {
    commonAxios("write-document", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                setDataToShow(false)
                fetchDocList()
            }
            else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err)
            setLoader(false)
        })
}


export const onUploadFileDoc = (ep, data, fetchFolders, setLoader) => (dispatch, getState) => {
    commonAxios(ep, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                fetchFolders()
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err)
            setLoader(false)
        })
}

export const onImportDoc = (data, fetchFolders, setLoader) => (dispatch, getState) => {
    commonAxios("import-website", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                fetchFolders()
            }
            else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err)
            setLoader(false)
        })
}



export const onDeleteDoc = (data, fetchFolders, Swal) => (dispatch, getState) => {
    commonAxios("delete-document", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchFolders()
                dispatch(setAlert(res.data, "success"))
            }
            else {
                dispatch(setAlert(res.data, "danger"))
            }
            Swal.close()
        }).catch((err) => {
            console.log(err)
            Swal.close()
        })
}

export const onUploadDocs = (formData, data, fetchFolders, setUploadLoader, isDocs) => (dispatch, getState) => {
    commonAxios("upload-file", formData, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {

                let formData = isDocs ? new FormData() : data
                if (isDocs) {
                    formData.append("files", data.file)
                    formData.append("name", data.name)
                    formData.append("type", "docx")
                    formData.append("folderId", data.folderId)
                    formData.append("url", res.data.path)
                } else {
                    data.file = res.data.path
                }
                let ep = isDocs ? "upload-docx" : "upload-document"
                dispatch(onUploadFileDoc(ep, formData, fetchFolders, setUploadLoader))
            } else {
                dispatch(setAlert(res.msg, "danger"))
                setUploadLoader(false)
            }
        })
        .catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            setUploadLoader(false)
        })

}