import { combineReducers } from "redux";
import { AlertReducer } from "./AlertReducer";
import { AuthReducer } from "./AuthReducer";
import { RebrandReducer } from "./RebrandReducer";
import { SupportReducer } from "./SupportReducer";
import { StaffReducer } from "./StaffReducer";
import { SocialResponder } from "./SocialResponder";
import { GodModeReducer } from "./GodModeReducer";
import { UserSettingsReducer } from "./UserSettingsReducer";

const appReducer = combineReducers({
    alert: AlertReducer,
    auth: AuthReducer,
    support: SupportReducer,
    rebrand: RebrandReducer,
    staff: StaffReducer,
    social: SocialResponder,
    godMode: GodModeReducer,
    setting: UserSettingsReducer
})

const rootReducers = (state, action) => {
    if (action.type === 'LOGOUT') {
        localStorage.clear();
        state = undefined
    }
    return appReducer(state, action)
}

export default rootReducers;