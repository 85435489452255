import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { onImportDoc } from '../../Actions/TrainingAction'
import { useEffect } from 'react'

const WebsiteDoc = ({ id, fetchFolders }) => {
    const dispatch = useDispatch()
    const [loader, setLoader] = useState(false)
    const [state, setState] = useState({
        folderId: id,
        url: "",
        type: "import"
    })
    const handleChange = (e) => {
        setState({
            ...state,
            url: e.target.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        dispatch(onImportDoc(state, fetchFolders, setLoader))
    }
    useEffect(() => {
        setState({
            ...state,
            folderId: id
        })
    }, [id])


    return (
        <form onSubmit={handleSubmit}>
            <div className="row align-items-center">
                <div className="col-md-10">
                    <div className="form-group form-group-alt">
                        <label className="form-text text-muted" htmlFor="">Website URL</label>
                        <input
                            className="form-control"
                            type="url"
                            placeholder="Enter Website URL"
                            value={state.url}
                            name='url'
                            onChange={handleChange}
                            required
                        />

                    </div>
                </div>
                <div className="col-md-2">
                    <div className="form-group text-center mb-0">
                        <button className="demoLink" type='submit'> {loader ? <> Importing <i className="fa fa-spinner fa-spin mx-1" /></> : "Import"}</button>
                    </div>
                </div>
            </div>



        </form>
    )
}

export default WebsiteDoc