import React, { useState } from 'react'
import { FaLessThan } from 'react-icons/fa'
import SummerNote from './SummerNote'
import { onWriteDoc } from '../../Actions/TrainingAction'
import { useDispatch } from 'react-redux'

const EditDocContent = ({ dataToShow, setDataToShow, fetchFolders }) => {
  const dispatch = useDispatch()
  const [loader, setLoader] = useState(false)
  const handleUpdate = () => {
    setLoader(true)
    dispatch(onWriteDoc(dataToShow, fetchFolders, setDataToShow, setLoader))
  }

  return (
    <>

      <div className="editDocHeading ">
        <span onClick={() => setDataToShow(false)} className='cursor-pointer'><FaLessThan style={{ fontSize: "20px" }} /></span>
        <h1>{dataToShow.name}</h1>
      </div>
      <hr />


      <div className="editDoc_show_content" >
        <SummerNote
          state={dataToShow}
          setState={setDataToShow}
          type="edit"
        />
        <button className='demoLink' onClick={handleUpdate}>{loader ? <>Updating <i className="fa fa-spinner fa-spin mx-1" /></> : "Update"}</button>
      </div>

    </>
  )
}

export default EditDocContent