import React, { useEffect, useRef, useState } from 'react'
import { BiTrash, BiEdit } from "react-icons/bi";
import { useDispatch } from 'react-redux';
import { onChatEdit, onDeleteChat } from '../../Actions/StaffAction';
import SweetAlertTwo from '../SweetAlertTwo';
import { useSelector } from 'react-redux';


const ConversationPanel = ({ curElem, conversationId, selectedcId, setSelectedcId, fetchConversation, fetchMessage, firstTileId,setConversationData }) => {

    const dispatch = useDispatch();
    const convRef = useRef()
    const auth = useSelector(state => state.auth)
    const godMode = useSelector(state => state.godMode)
    const [isReadOnly, setIsReadOnly] = useState(true)
    const [conversationName, setConversationName] = useState("")
    const [info, setInfo] = useState({
        id: -1,
        enable: false,
    })

    const selectConversation = (convId) => {
        if (!godMode.isGodModeActive) {
            setSelectedcId(convId)
        }
    }
    const handleUpdate = () => {
        if (!isReadOnly) {
            if (conversationName !== "") {
                let data = {
                    cid: conversationId,
                    name: conversationName
                }
                dispatch(onChatEdit(data, setIsReadOnly, fetchConversation))
            }
        }
    }

    const handleCancel = () => {
        setInfo({
            ...info,
            id: -1,
            enable: false,
        })
    }

    const handleDelete = (e, id) => {
        e.stopPropagation()
        setInfo({
            ...info,
            id: id,
            enable: true
        })
    }

    const handleEdit = (e) => {
        if (+auth.user.isClient === 1) { }  
        else {
            e.stopPropagation()
            convRef.current.focus()
            setIsReadOnly(false)
        }
    }

    const deleteAgencyData = (swal) => {
        let data = {
            cid: info.id
        }
        dispatch(onDeleteChat(data, fetchConversation, setSelectedcId, fetchMessage, swal,false,false,setConversationData))
        setInfo({
            ...info,
            id: -1,
            enable: false,
        })
    }

    useEffect(() => {
        setConversationName(curElem.name)
    }, [conversationId])

    useEffect(() => {
        if (selectedcId === false) {
            selectConversation(firstTileId)
        }
    }, [selectedcId])

    return (
        <>
            <div
                className={`${+conversationId === +selectedcId ? "chat_active" : ""} chat-list-single cursor-pointer`}
                onClick={() => selectConversation(conversationId)}
                onDoubleClick={handleEdit}
            >
                <input
                    ref={convRef}
                    type='text'
                    value={conversationName}
                    onChange={(e) => setConversationName(e.target.value)}
                    className='conver-input-field'
                    readOnly={isReadOnly}
                    onBlur={handleUpdate}
                    title={conversationName}
                    maxLength={100}
                    style={{ cursor: isReadOnly ? "pointer" : "" }}
                    
                />
                {+auth.user.isClient === 1 ?
                    "" :
                    <div className="chat-list-action">
                        <button onClick={(e) => handleEdit(e)} className='button-hover-effect'><BiEdit title="Edit" /></button>
                        <button onClick={(e) => handleDelete(e, curElem.id)} className='button-hover-effect'><BiTrash title="Delete" /></button>
                    </div>}
            </div >
            <SweetAlertTwo
                show={info.enable}
                title="Are You Sure?"
                text="You won't be able to revert this!"
                icon="warning"
                showCancelButton={true}
                confirmButtonColor='#b32f3d'
                cancelButtonColor='#2a2c3a'
                confirmButtonText='Yes, delete it!'
                confirmTitle='Deleting...'
                confirmText="This won't be revert!"
                confirmIcon='success'
                handlePerform={deleteAgencyData}
                onCancel={handleCancel}
            />
        </>
    )
}

export default ConversationPanel