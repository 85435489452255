import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import TitleBar from "../TitleBar";
import { BsVectorPen, BsUpload, BsLink45Deg } from "react-icons/bs"
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import FolderStuff from "./FolderStuff";
import { onUploadDocs, onfetchFolder } from "../../Actions/TrainingAction";
import { useDispatch } from "react-redux";
import WriteDoc from "./WriteDoc";
import WebsiteDoc from "./WebsiteDoc";
import DocListTable from "./DocListTable";
import EditDocContent from "./EditDocContent";
import Swal from "sweetalert2";

const TrainingPage = () => {
    const dispatch = useDispatch()
    const [folder, setFolder] = useState([])
    const [loader, setLoader] = useState(false)
    const [uploadLoader, setUploadLoader] = useState(false)

    const [dataToShow, setDataToShow] = useState(false)

    const fetchFolders = () => {
        setLoader(true)
        dispatch(onfetchFolder(setFolder, setLoader))
    }

    const handleUploadDoc = (e, id) => {
        const formData = new FormData()
        let uploadImgType = ['image/png', 'image/jpg', 'image/jpeg']
        let data = {
            folderId: id,
            type: "upload",
            name: e.target.files[0].name,
            file: e.target.files[0],
            content: ""
        }
        formData.append("file", e.target.files[0])
        if (e.target.files[0].size <= 300000000) {
            if (uploadImgType.includes(e.target.files[0].type)) {
                setUploadLoader(true)
                formData.append("upload_type", "images")
                dispatch(onUploadDocs(formData, data, fetchFolders, setUploadLoader))

            } else if (e.target.files[0].type === "application/pdf") {
                setUploadLoader(true)
                formData.append("upload_type", "docx")
                dispatch(onUploadDocs(formData, data, fetchFolders, setUploadLoader))
            } else {
                setUploadLoader(true)
                formData.append("upload_type", "docx")
                dispatch(onUploadDocs(formData, data, fetchFolders, setUploadLoader, true))
            }
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Max allowed size is 300MB!',
                confirmButtonColor: "#d03e3e"
            })
        }
    }

    useEffect(() => {
        fetchFolders()
    }, [])

    return (
        <>
            <TitleBar title="Training" />
            <Navbar />
            <section className="siteWrap">
                <div className="training-sec">
                    <Tab.Container id="left-tabs-example" defaultActiveKey="folder_1">
                        <FolderStuff
                            folder={folder}
                            loader={loader}
                            fetchFolders={fetchFolders}
                        />
                        <div className="training-sec-right">
                            <Tab.Content className="first-level">
                                {
                                    dataToShow ?
                                        <EditDocContent
                                            setDataToShow={setDataToShow}
                                            dataToShow={dataToShow}
                                            fetchFolders={fetchFolders}
                                        />
                                        :
                                        folder.length > 0 ?
                                            folder.map((curElem, index) => {
                                                return (
                                                    <Tab.Pane eventKey={`folder_${curElem.id}`} key={index}>
                                                        <div className="tab-title text-capitalize">{curElem.name}:</div>
                                                        <div className="tab-content-in">
                                                            <div className="tab-content-in-title">
                                                                <h2>Create Training Documents</h2>
                                                            </div>
                                                            <div className="tab-inner">
                                                                <Tab.Container id="left-tabs-example" defaultActiveKey="first-1">
                                                                    <Nav variant="g" className="">
                                                                        <Nav.Item>
                                                                            <Nav.Link eventKey="first-1">
                                                                                <span className="icon"><BsVectorPen /></span>
                                                                                <h5>Write</h5>
                                                                                <p>Write training document</p>
                                                                            </Nav.Link>
                                                                        </Nav.Item>

                                                                        <Nav.Item>
                                                                            <Nav.Link eventKey="second-1">
                                                                                <span className="icon"><BsUpload /></span>
                                                                                <h5>Upload</h5>
                                                                                <p>Upload your document</p>
                                                                            </Nav.Link>
                                                                        </Nav.Item>

                                                                        <Nav.Item>
                                                                            <Nav.Link eventKey="third-1">
                                                                                <span className="icon"><BsLink45Deg /></span>
                                                                                <h5>Import Website</h5>
                                                                                <p>Import from website URL</p>
                                                                            </Nav.Link>
                                                                        </Nav.Item>
                                                                    </Nav>
                                                                    <Tab.Content>
                                                                        <Tab.Pane eventKey="first-1">
                                                                            <WriteDoc
                                                                                id={curElem.id}
                                                                                fetchFolders={fetchFolders}
                                                                            />
                                                                        </Tab.Pane>

                                                                        <Tab.Pane eventKey="second-1">
                                                                            <div className="upload-box " style={{ padding: 0, height: "148px" }}>
                                                                                <div className="upload-box-in " style={{ margin: "auto", height: "50%" }} >
                                                                                    {uploadLoader ?
                                                                                        <i className="fa fa-spinner fa-spin" style={{ fontSize: "30px", color: "#c238a4" }} />
                                                                                        :
                                                                                        <>
                                                                                            <BsUpload />
                                                                                            <p>Click to upload or drag and drop <br />file like image, PDF & DOCX. </p>
                                                                                            <input
                                                                                                className="cursor-pointer"
                                                                                                type="file"
                                                                                                onChange={(e) => handleUploadDoc(e, curElem.id)}
                                                                                            />
                                                                                        </>
                                                                                    }

                                                                                </div>
                                                                            </div>
                                                                        </Tab.Pane>

                                                                        <Tab.Pane eventKey="third-1">
                                                                            <WebsiteDoc
                                                                                id={curElem.id}
                                                                                fetchFolders={fetchFolders}
                                                                            />
                                                                        </Tab.Pane>

                                                                    </Tab.Content>
                                                                </Tab.Container>

                                                                <div className="tab-content-in-title mt-5">
                                                                    <h2>Stored Documents</h2>
                                                                </div>

                                                                <div className="doc-table">
                                                                    <div className="table-responsive">
                                                                        <DocListTable
                                                                            setDataToShow={setDataToShow}
                                                                            tableData={curElem.totalDoc}
                                                                            fetchFolders={fetchFolders}
                                                                        />
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </Tab.Pane>
                                                )
                                            })

                                            : ""}

                            </Tab.Content>
                        </div>
                    </Tab.Container>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default TrainingPage;