import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import videoThumb from "../../images/fav-new.png";
import modalCloseIcon from "../../images/modal-close.png";
import webTraning from "../../images/web_1.jpg";
import webTraning2 from "../../images/web_2.jpg";
import webTraning3 from "../../images/web_3.jpg";

const HelpVideos = () => {
  const supportData = useSelector((state) => state.support);

  const [videosData, setVideosDara] = useState(supportData.videos);
  const [url, setUrl] = useState("");
  const [showModal, setShowModal] = useState(false);

  const playVideo = (url) => {
    setUrl(url);
    setShowModal(true);
  };

  const closeModal = () => {
    setUrl("");
    setShowModal(false);
  };

  useEffect(() => {
    setVideosDara(supportData.videos);
  }, [supportData.videos]);

  return (
    <>
      <section className="popularVideo">
        <div className="container">
          <div className="text-center">
            <h2>Popular Video Tutorials</h2>
          </div>
          <div className="row">
            {videosData.length > 0
              ? videosData.map((video, index) => {
                  return (
                    <div
                      className="col-xl-3 col-lg-4 col-sm-6"
                      key={index}
                      style={{ cursor: "pointer" }}
                      onClick={(e) => playVideo(video.url)}
                    >
                      <div className="popularVideo-single text-center">
                        <div
                          className="popularVideo-vid video-card-wrapper-1 dashboard-card-bg"
                          style={{ background: "none" }}
                        >
                          <img
                            src={videoThumb}
                            className="mb-3 vidThumb-img  img-fluid"
                            alt=""
                            style={{ objectFit: "contain" }}
                          />
                          {/* <img src={video.thumbnail} /> */}
                        </div>
                        <div className="popularVideo-txt">
                          <span>{video.title}</span>
                        </div>
                      </div>
                    </div>
                  );
                })
              : ""}
            {videosData.length === 0 ? (
              <div className="col-12">
                <h4 className="text-center load-icon-help">
                  <i
                    className="fa fa-spinner fa-spin mr-2"
                    style={{ color: "#c238a4" }}
                  />
                </h4>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </section>
      <section className="Webinar_Training">
        <div className="container">
          <div className="row">
            <div className="text-center">
              <h2 style={{ color: "#fff" }}>  Webinar Training Replay</h2>
            </div>
            <div className="col-md-4">
              <div className="Webinar_image ">
                <a
                  href="https://growthtraining.clickfunnels.com/optin1687158724442"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={webTraning} alt="" />
                </a>
                <div className="web_heading">
                  <span>Part #1 : Stan Martin & Other AIStaffs Cool Fetures</span>
                </div>
              </div>
            </div>
           
            <div className="col-md-4">
              <div className="Webinar_image ">
                <a
                  href="https://growthtraining.clickfunnels.com/optin1687160542481"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={webTraning3} alt="" />
                </a>
                <div className="web_heading">
                  <span>Part #2 : How to make money with this</span>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="Webinar_image ">
                <a
                  href="https://growthtraining.clickfunnels.com/optin1687160766980"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={webTraning2} alt="" />
                </a>
                <div className="web_heading">
                  <span>Part #3 : Life changing part!</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div
        className={`modal VideoModal ${showModal ? "show-modal" : ""}`}
        id="myModal"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div onClick={(e) => closeModal()} className="vidClose">
              <img src={modalCloseIcon} alt="" />
            </div>
            <div className="modal-body">
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  id="frameSrc"
                  className="embed-responsive-item"
                  src={url}
                  allowFullScreen={true}
                  title="help-videos"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HelpVideos;
