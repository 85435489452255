import React, { useEffect, } from 'react'
import { RxCross2 } from "react-icons/rx"

const SelectBoxMultiple = ({ state, setState, curElem, selectAll, folders, type, setSelectAll }) => {
    const isSelected = type === "botType"
        ? state.personality.botShow.find(({ id }) => id === curElem.id)
        : type === "botAnswer" ? state.personality.botAnswer.find(({ id }) => id === curElem.id)
            : state.knowledge.find(({ id }) => id === curElem.id)

    const handleChange = (folderData) => {
        let arr = type === "botType"
            ? [...state.personality.botShow]
            : type === "botAnswer"
                ? [...state.personality.botAnswer]
                : [...state.knowledge]

        let obj = {
            id: folderData.id,
            name: folderData.name
        }
        let flag = arr.find(data => data.id === folderData.id);
        if (flag === undefined) {
            arr = [...arr, obj]
            if (type === "botType") {
                setState({
                    ...state,
                    personality: {
                        ...state.personality,
                        botShow: arr
                    }
                })
            }
            else if (type === "botAnswer") {
                setState({
                    ...state,
                    personality: {
                        ...state.personality,
                        botAnswer: arr
                    }
                })
            }
            else {
                setState({
                    ...state,
                    knowledge: arr
                })
            }

            if (setSelectAll) {
                let flag2=folders.every(obj=>arr.some(obj2=>obj.id === obj2.id && obj.name === obj2.name));
                if(flag2){
                    setSelectAll(true)
                }
            }
        }

    }
    const onRemoveFolder = (e, fId) => {
        e.stopPropagation()
        const folderIndex =
            type === "botType" ?
                state.personality.botShow.findIndex(({ id }) => id === fId) :
                type === "botAnswer" ?
                    state.personality.botAnswer.findIndex(({ id }) => id === fId) :
                    state.knowledge.findIndex(({ id }) => id === fId)
        if (folderIndex !== -1) {
            let arr = type === "botType" ? [...state.personality.botShow] : type === "botAnswer" ? [...state.personality.botAnswer] : [...state.knowledge]
            arr.splice(folderIndex, 1)
            if (type === "botType") {
                setState({
                    ...state,
                    personality: {
                        ...state.personality,
                        botShow: arr
                    }
                })
            }
            else if (type === "botAnswer") {
                setState({
                    ...state,
                    personality: {
                        ...state.personality,
                        botAnswer: arr
                    }
                })
            }
            else {
                setState({
                    ...state,
                    knowledge: arr
                })
            }
            if (setSelectAll) {
                if (arr.length < folders.length) {
                    setSelectAll("")
                }
            }
        }
    }

    useEffect(() => {
        if (selectAll) {
            let arr = type === "botType" ? [...state.personality.botShow] : [...state.knowledge]
            folders.forEach(element => {
                let isInserted = arr.findIndex(({ id }) => id === element.id)
                if (isInserted === -1) {
                    let obj = {
                        id: element.id,
                        name: element.name
                    }
                    arr = [...arr, obj]
                }
            })

            setState({
                ...state,
                knowledge: arr
            })
        }
        if (selectAll === false && !type) {
            setState({
                ...state,
                knowledge: []
            })
        }
    }, [selectAll])

    return (
        <div style={{ marginBottom: '45px' }}>
            <span
                style={{ cursor: "pointer", background: isSelected ? "linear-gradient(to right, #7900b3 0%, #b83333 100%)" : "", position: "relative", }}
                onClick={() => handleChange(curElem)}
            >
                {curElem.name}
                {isSelected ?
                    <RxCross2
                        style={{ position: 'absolute', top: -9, right: -6, cursor: 'pointer' }}
                        onClick={(e) => onRemoveFolder(e, curElem.id)}
                    /> : ""}
            </span>

        </div>
    )
}

export default SelectBoxMultiple