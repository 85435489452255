import React, { useEffect, useState } from 'react'
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';

import { Modal } from "react-bootstrap";
import modalCloseIcon from "../../images/modal-close.png";
import GeneralTab from './GeneralTab';
import KnowledgeTab from './KnowledgeTab';
import PersonalityTab from './PersonalityTab';

const CreateBotModal = ({ staffId, show, handleClose, fetchBots, curElem}) => {
    const [next, setNext] = useState(false)
    const [state, setState] = useState({
        bot_name: "",
        description: "",
        image: "https://reeelapps-app.s3.us-west-2.amazonaws.com/aistaff/embed_logo/default_staff.png",
        knowledge: [],
        role: "",
        gender: "male",
        tone: "Authoritative",
        writingStyle: "Academic",
        isfired: curElem ? curElem.isfired : ""
        // personality: {
        //     botRole: "",
        //     botStrictness: "",
        //     botShow: [],
        //     botAnswer: [],
        //     botCantAnswer: ""
        // },
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setState({
            ...state,
            [name]: value
        })
    }


    useEffect(() => {
        if (curElem) {
            setState(curElem)
        }

        return () => {
            setNext(false)
        }
    }, [curElem])


    return (
        <Modal className="VideoModal dark" show={show} centered>
            <Modal.Body>
                <div onClick={handleClose} className="vidClose">
                    <img alt="" src={modalCloseIcon} />
                </div>
                <div className="modal-txt">
                    <div className="modal-txt-top">
                        <h4>Customize Your youAi</h4>
                        <p>Create your youAi purpose</p>
                    </div>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <Nav className="bots-nav mb-3">
                            <Nav.Item onClick={() => setNext(false)}>
                                <Nav.Link className={next ? "" : 'active'}>General</Nav.Link>
                            </Nav.Item>
                            <Nav.Item onClick={() => setNext(true)}>
                                <Nav.Link className={next ? "active" : ''}>Knowledge</Nav.Link>
                            </Nav.Item>
                            {/* <Nav.Item>
                                <Nav.Link eventKey="third">Personality</Nav.Link>
                            </Nav.Item> */}
                        </Nav>
                        <Tab.Content className="bot_content">
                            <Tab.Pane className={next ? '' : "show active"}>
                                <GeneralTab
                                    state={state}
                                    setState={setState}
                                    handleChange={handleChange}
                                    setNext={setNext}
                                />
                            </Tab.Pane>
                            <Tab.Pane className={next ? 'show active' : ""}>
                                <KnowledgeTab
                                    state={state}
                                    setState={setState}
                                    fetchBots={fetchBots}
                                    curElem={curElem}
                                    handleClose={handleClose}
                                    staffId={staffId}
                                />
                            </Tab.Pane>
                            {/* <Tab.Pane eventKey="third">
                                <PersonalityTab
                                    state={state}
                                    setState={setState}
                                    handleClose={handleClose}
                                    fetchBots={fetchBots}
                                    botId={botId}
                                />
                            </Tab.Pane> */}
                        </Tab.Content>
                    </Tab.Container>
                </div>
            </Modal.Body>
        </Modal >
    )
}

export default CreateBotModal