import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import coverImage from "../../images/conv_card.png"
import { BsTrashFill } from "react-icons/bs"
import { useDispatch } from 'react-redux'
import { onDeleteChat, onViewMessageForPdf, onEditChatName } from '../../Actions/StaffAction'
import SweetAlertTwo from '../SweetAlertTwo'
import Dropdown from 'react-bootstrap/Dropdown';
import { BiDotsHorizontalRounded } from "react-icons/bi"
import { HiOutlineCloudDownload } from 'react-icons/hi'
import { usePDF } from '@react-pdf/renderer';
import { useSelector } from 'react-redux'
import html2pdf from 'html2pdf.js/dist/html2pdf.min';
import { BiEdit } from "react-icons/bi";

const ConversationCard = ({ curElem, conversationData, setConversation, type, setConversationsEmbed }) => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const [pdfDoc, setPdfDoc] = useState(false)
    const [letDownload, setLetDownload] = useState(false)
    const [instance, updateInstance] = usePDF({ document: pdfDoc })
    const [info, setInfo] = useState({
        id: -1,
        enable: false,
    })
    const [isReadOnly, setIsReadOnly] = useState(true)
    const [conversationName, setConversationName] = useState(curElem.name)
    const [htmlTag, setHtmlTag] = useState([])
    const [loader, setLoader] = useState(false);
    const convRef = useRef()
    const handleCancel = () => {
        setInfo({
            ...info,
            id: -1,
            enable: false,
        })
    }
    const handleDelete = (id) => {
        setInfo({
            ...info,
            id: id,
            enable: true
        })
    }

    const deleteConversation = (swal) => {
        let data = {
            cid: info.id,
            type: type
        }

        dispatch(onDeleteChat(data, conversationData, false, false, swal, setConversation, setConversationsEmbed))
        setInfo({
            ...info,
            id: -1,
            enable: false,
        })
    }

    const handleDownloadPdf = (val) => {
        setLoader(true);
        let data = {
            cid: val.id,
            hireId: val.hireId,
            staffId: val.staffId,
            type: type,
            name: conversationName
        }
        //dispatch(onViewMessageForPdf(data, setPdfDoc, updateInstance,html2pdf))

        // rehan code starts here
        dispatch(onViewMessageForPdf(data, html2pdf, setLoader))
    }
    const handleEdit = (e) => {
        if (+auth.user.isClient === 1) { } else {
            e.stopPropagation()
            convRef.current.focus()
            setIsReadOnly(false)
        }
    }
    const handleUpdate = (swal) => {
        
        if (!isReadOnly) {
            if (conversationName !== "") {
                if(type==="embed"){
                    let data = {
                        name: conversationName,
                        cId: curElem.id,
                        userId: curElem.userId,
                        type: "embed"
                    }
                    dispatch(onEditChatName(data, setIsReadOnly))
                }else{
                    let data = {
                        name: conversationName,
                        cId: curElem.id,
                        userId: curElem.userId,
                        type: ""
                    }
                    dispatch(onEditChatName(data, setIsReadOnly))
                }
                // let data = {
                //     name: conversationName,
                //     cId: curElem.id,
                //     userId: curElem.userId,
                //     type: ""
                // }
                // dispatch(onEditChatName(data, setIsReadOnly))
            }
        }
    }
    useEffect(() => {
        if (pdfDoc) {
            updateInstance({
                document: pdfDoc
            })
            setLetDownload(true)
        }
    }, [pdfDoc])
    useEffect(() => {
        setConversationName(curElem.name)
    }, [curElem])

    useEffect(() => {
        if (instance.url && letDownload) {
            let a = document.createElement('a')
            a.href = instance.url
            a.download = `${curElem.name}.pdf`
            a.click()
            setLetDownload(false)
        }
    }, [instance])
    return (
        <div className="col-sm-6 col-lg-4">
            <div className="convSec-single">
                <div className="convSec-img">
                    <img src={coverImage} alt="" />
                    <div className='convSec-del' >
                        <Dropdown>
                            <Dropdown.Toggle variant="t" id="dropdown-basic">
                                <BiDotsHorizontalRounded style={{ fontSize: '32px', color: "#fff" }} />
                            </Dropdown.Toggle>

                            <Dropdown.Menu style={{ background: "#000" }}>
                                {+auth.user.isClient === 1 ? "" :
                                    <Link onClick={() => handleDelete(curElem.id)}><BsTrashFill style={{ marginLeft: "8px", marginRight: "8px", fontSize: "18px" }} />Delete</Link>}
                                <Link onClick={() => handleDownloadPdf(curElem)}>

                                    <div style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center" }}>
                                        {
                                            loader ? <>
                                                <i className="fa fa-spinner fa-spin" style={{ fontSize: "18px", color: "#fff", marginRight: "8px", marginLeft: "8px" }} />
                                                Downloading
                                            </> :
                                                <>
                                                    <HiOutlineCloudDownload style={{
                                                        marginLeft: "8px",
                                                        marginRight: "8px", color: "#fff", fontSize: "18px"
                                                    }} />
                                                    Download
                                                </>
                                        }

                                    </div>
                                </Link>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>

                <div className="convSec-txt">
                    <div onDoubleClick={handleEdit}>
                        <div className='chat-edit-btn' style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <input
                                ref={convRef}
                                value={conversationName}
                                onChange={(e) => { setConversationName(e.target.value) }}
                                className='conver-input-field px-0'
                                readOnly={isReadOnly}
                                onBlur={handleUpdate}
                                title={conversationName}
                                maxLength={100}
                                style={{ cursor: isReadOnly ? "pointer" : "", textAlign: "center", width: "100%", fontWeight: "bold", letterSpacing: "1.4px" }}
                                type="text" />
                            {
                                +auth.user.isClient === 1 ? "" : <div>
                                    <button
                                        onClick={(e) => { handleEdit(e) }}
                                        style={{}}>
                                        <BiEdit title='Edit' /></button>
                                </div>
                            }

                        </div>
                        <p style={{ color: "#FFF" }}>{curElem.created}</p>
                    </div>
                    {/* <h6 style={{ letterSpacing: "1.5px" }} title={curElem.name}>{curElem.name?.length > 35 ? curElem.name.slice(0, 35) + "..." : curElem.name}</h6> */}
                    {type === "embed" ?
                        ""
                        :
                        <div className="staff-btn">
                            <Link to={`/conversations/chat?sId=${curElem.staffId}&hId=${curElem.hireId}&cId=${curElem.id}`} className="demoLink mt-2" href="">View</Link>
                        </div>
                    }
                </div>
            </div>
            <SweetAlertTwo
                show={info.enable}
                title="Are You Sure?"
                text="You won't be able to revert this!"
                icon="warning"
                showCancelButton={true}
                confirmButtonColor='#b32f3d'
                cancelButtonColor='#2a2c3a'
                confirmButtonText='Yes, delete it!'
                confirmTitle='Deleting...'
                confirmText="This won't be revert!"
                confirmIcon='success'
                handlePerform={deleteConversation}
                onCancel={handleCancel}
            />
        </div>

    )
}

export default ConversationCard