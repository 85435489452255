import produce from "immer"
const initialState = {
    data: {
        isEnter: true,
        autoSubmit: false,
        disableStaffNonWorking: false,
        timeZone: "Etc/GMT",
        langCode: "",
        isDataFetch: false,
        openingHours: [
            {
                day: "Sunday",
                enable: false,
                fromTime: "9:00",
                fromMeridiem: "AM",
                toTime: "9:00",
                toMeridiem: "PM"
            },
            {
                day: "Monday",
                enable: true,
                fromTime: "9:00",
                fromMeridiem: "AM",
                toTime: "9:00",
                toMeridiem: "PM"
            },
            {
                day: "Tuesday",
                enable: true,
                fromTime: "9:00",
                fromMeridiem: "AM",
                toTime: "9:00",
                toMeridiem: "PM"
            },
            {
                day: "Wednesday",
                enable: true,
                fromTime: "9:00",
                fromMeridiem: "AM",
                toTime: "9:00",
                toMeridiem: "PM"
            },
            {
                day: "Thursday",
                enable: true,
                fromTime: "9:00",
                fromMeridiem: "AM",
                toTime: "9:00",
                toMeridiem: "PM"
            },
            {
                day: "Friday",
                enable: true,
                fromTime: "9:00",
                fromMeridiem: "AM",
                toTime: "9:00",
                toMeridiem: "PM"
            },
            {
                day: "Saturday",
                enable: false,
                fromTime: "9:00",
                fromMeridiem: "AM",
                toTime: "9:00",
                toMeridiem: "PM"
            }
        ],
    }
}

export const UserSettingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_SETTINGS":
            return produce(state, (draft) => {
                draft.data.isEnter = +action.payload.is_enter === 1 ? true : false
                draft.data.autoSubmit = +action.payload.autoSubmit === 1 ? true : false
                draft.data.disableStaffNonWorking = +action.payload.disableStaffNonWorking === 1 ? true : false
                draft.data.openingHours = action.payload.openingHours ? action.payload.openingHours : initialState.data.openingHours
                draft.data.timeZone = action.payload.timeZone === "" ? initialState.data.timeZone : action.payload.timeZone
                draft.data.langCode = action.payload.langCode
            })

        case "ON_MAKE_DEFAULT_ACTIVE":
            return produce(state, (draft) => {
                draft.data.isDataFetch = true
            })

        case "CHANGE_SETTING":
            return produce(state, (draft) => {
                draft.data = {
                    ...draft.data,
                    [action.payload.name]: action.payload.checked
                }
            })

        case "ON_ENABLE_DAY":
            return produce(state, (draft) => {
                draft.data.openingHours[action.payload.index].enable = action.payload.checked
            })

        case "ADD_FROM_MERIDIEM":
            return produce(state, (draft) => {
                draft.data.openingHours[action.payload.index].fromMeridiem = action.payload.value
            })
        case "ADD_TO_MERIDIEM":
            return produce(state, (draft) => {
                draft.data.openingHours[action.payload.index].toMeridiem = action.payload.value
            })

        case "ADD_FROM_TIME":
            return produce(state, (draft) => {
                draft.data.openingHours[action.payload.index].fromTime = action.payload.value
            })

        case "ADD_TO_TIME":
            return produce(state, (draft) => {
                draft.data.openingHours[action.payload.index].toTime = action.payload.value
            })

        case "ADD_TIMEZONE":
            return produce(state, (draft) => {
                draft.data.timeZone = action.payload
            })

        default:
            return state
    }
}