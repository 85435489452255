import React from "react";
import { useSelector } from "react-redux";
import queryString from "query-string";
import { Link } from "react-router-dom";

const TrainingTitles = ({ location, id }) => {

    const supportData = useSelector(state => state.support);
    const articleId = queryString.parse(location.search).id;

    // const [currentArt, setCurrentArt] = useState([])

    // useEffect(() => {
    //     if (supportData.articles.length > 0) {
    //         setCurrentArt(supportData.articles.filter((article) => article.id === id)[0])
    //     }
    // }, [supportData])


    return (
        <>
            {/* <div className="container-fluid mt-3 ml-2">
                <div className="row">
                    <div className="col-12">
                        <div className="mb-2 support-data text-white">
                            <Link to="/help-and-support" className="support-link text-white">Support </Link>
                            /
                            <Link className={`${currentArt.name ? '' : 'active-help'} support-link`} to={`training?id=${currentArt.id}`}> {currentArt.name} </Link>
                            {
                                currentArt.name ? <> / <span className="active-help">{currentArt.name.title}</span></> : ''
                            }

                        </div>
                    </div>
                </div>
            </div> */}
            <div className="col-lg-4 tabLeft">
                <div className="popular-topics-box">
                    <h5 className="text-white pb-4">POPULAR TOPICS</h5>

                    <div className="tabCont-left">

                        <div className="nav flex-column" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                            {
                                supportData.articles.length > 0 ?
                                    supportData.articles.map((item, index) => {
                                        return (
                                            <Link key={index} className={`nav-link ${articleId === item.id ? 'active' : ''}`} to={`/training?id=${item.id}`} >
                                                {item.name}
                                            </Link>
                                        )
                                    })
                                    : ''
                            }

                        </div>


                    </div>
                    {supportData.articles.length === 0 ?
                        <div className="text-center" >
                            <i className="fa fa-spinner fa-spin mr-2" style={{ color: "#9c1d6b", fontSize: "30px" }} />
                        </div> : ""}

                </div>
            </div>
        </>
    )
}







export default TrainingTitles;