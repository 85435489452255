import { commonAxios } from "../Global/CommonAxios"
import { setAlert } from "./AlertAction"

export const onfetchBots = (data, setState, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-bots", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState(res.data)
            } else {
                setState([])
            }
            setLoader(false)
        }).catch((err) => {
            setLoader(false)
            console.log(err)
        })
}

export const onDeleteBot = (data, fetchBots, Swal) => (dispatch, getState) => {
    commonAxios("delete-bot", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchBots()
                dispatch(setAlert(res.msg, "success"))
            }
            else {
                dispatch(setAlert(res.msg, "danger"))
            }
            Swal.close()
        }).catch((err) => {
            Swal.close()
            dispatch(setAlert(err.msg, "danger"))
            console.log(err)
        })
}

export const onSubmitBot = (ep, data, handleClose, fetchBots, setLoader, loader) => (dispatch, getState) => {
    commonAxios(ep, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                if (fetchBots) {
                    fetchBots()
                }
            }
            else {
                dispatch(setAlert(res.msg, "danger"))
            }
            if (loader) {
                setLoader({
                    ...loader,
                    hireLoader: false
                })
            } else {
                setLoader(false)
            }
            handleClose()
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            if (loader) {
                setLoader({
                    ...loader,
                    hireLoader: false
                })
            } else {
                setLoader(false)
            }
            handleClose()
            console.log(err)
        })
}

export const onGetUserSetting = () => (dispatch, getState) => {
    commonAxios("fetch_default_setting", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch({ type: "GET_SETTINGS", payload: res.data[0] })
            }
            dispatch({ type: "ON_MAKE_DEFAULT_ACTIVE" })
        }).catch((err) => {
            console.log(err)
        })
}

export const onUpdateUserSetting = (data, name, checked) => (dispatch, getState) => {
    let myData = { name, checked }
    dispatch({ type: "CHANGE_SETTING", payload: myData })
    commonAxios("update_default_setting", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
            }
        }).catch((err) => {
            console.log(err)
        })
}

export const onGetApiKey = (apiKey, setApiKey) => (dispatch, getState) => {
    commonAxios("fetch-user-key", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setApiKey({
                    ...apiKey,
                    id: res.data[0].id,
                    key: res.data[0].key
                })
            } else {
                setApiKey({
                    ...apiKey,
                    id: "",
                    key: ""
                })
            }
        }).catch((err) => {
            console.log(err)
            setApiKey({
                ...apiKey,
                id: "",
                key: ""
            })
        })
}

export const onUpdateUserKey = (data, setLoader, fetchApiKey) => (dispatch, getState) => {
    commonAxios("create-user-key", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                fetchApiKey()
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            setLoader(false)
            dispatch(setAlert(err.msg, "danger"))
        })
}

export const onDeleteApiKey = (apiKey, fetchApiKey, swal) => (dispatch, getState) => {
    commonAxios("delete-user-key", apiKey, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchApiKey();
            }
            swal.close()
        }).catch((err) => {
            console.log(err)
            swal.close()
        })
}

export const onEnableDay = (obj, index, checked) => (dispatch, getState) => {

    let data = { index, checked };
    dispatch({ type: "ON_ENABLE_DAY", payload: data });
    commonAxios("update_default_setting", obj, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {

            }
        }).catch((err) => {
            console.log(err)
        })
};

export const onAddMeridiemFrom = (obj, index, value) => (dispatch, getState) => {

    let data = { index, value };
    dispatch({ type: "ADD_FROM_MERIDIEM", payload: data });
    commonAxios("update_default_setting", obj, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {

            }
        }).catch((err) => {
            console.log(err)
        })
};

export const onAddMeridiemTo = (obj, index, value) => (dispatch, getState) => {

    let data = { index, value };
    dispatch({ type: "ADD_TO_MERIDIEM", payload: data });
    commonAxios("update_default_setting", obj, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {

            }
        }).catch((err) => {
            console.log(err)
        })
};

export const onAddTimeFrom = (obj, index, value) => (dispatch, getState) => {

    let data = { index, value };
    dispatch({ type: "ADD_FROM_TIME", payload: data });
    commonAxios("update_default_setting", obj, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {

            }
        }).catch((err) => {
            console.log(err)
        })
};

export const onAddTimeTo = (obj, index, value) => (dispatch, getState) => {

    let data = { index, value };
    dispatch({ type: "ADD_TO_TIME", payload: data });
    commonAxios("update_default_setting", obj, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {

            }
        }).catch((err) => {
            console.log(err)
        })
};


export const onSelectTimezone = (data) => (dispatch, getState) => {
    dispatch({ type: "ADD_TIMEZONE", payload: data });
    let obj = getState().setting.data
    obj = {
        ...obj,
        is_enter: obj.isEnter
    }
    delete obj.isEnter
    commonAxios("update_default_setting", obj, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {

            }
        }).catch((err) => {
            console.log(err)
        })
};


export const onGetOfflineMessages = (data, setState, setLoader) => (dispatch) => {
    commonAxios("fetch-offline-messages", data, dispatch)
        .then((res) => {
            if (res.status) {
                setState(res.data)
            }else{
                setState([])
            }
            setLoader(false)
        }).catch((err) => {

            console.log(err)
            setLoader(false)
        })
}


export const onFetchOutreach = (setData, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-smtp", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setData(res.data.reverse())
            }
            else {
                setData([])
            }
            setLoader(false)
        })
        .catch((err) => {
            console.log(err)
            setLoader(false)

        })

}

export const onTestOutreach = (data, setTested, loader, setLoader) => (dispatch, getState) => {
    commonAxios("check-smtp", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setTested(true)
            }
            else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                testLoader: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                testLoader: false
            })
        })
}

export const onAddOutreach = (ep, data, loader, setLoader, handleClose, fetchOutreach) => (dispatch, getState) => {
    commonAxios(ep, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                fetchOutreach()
            }
            else {
                dispatch(setAlert(res.msg, "danger"))
            }
            handleClose()
            setLoader({
                ...loader,
                addLoader: false
            })
        }).catch((err) => {
            console.log(err)
            handleClose()
            setLoader({
                ...loader,
                addLoader: false
            })
        })
}

export const onDeleteOutreach = (state, setState, fetchOutreach) => (dispatch, getState) => {
    let data = {
        id: state.id
    }
    commonAxios("delete-smtp", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchOutreach()
                dispatch(setAlert(res.msg, "success"))
            }
            else {
                dispatch(setAlert(res.msg, "danger"))
            }
        }).catch((err) => {
            console.log(err)
            dispatch(setAlert(err.msg, "danger"))
        })
}

export const onSendMail = (data, setLoader) => (dispatch, getState) => {
    commonAxios("send-email", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
            }
            else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err)
            dispatch(setAlert(err.msg, "danger"))
            setLoader(false)
        })
}

export const onDeleteMessages = (data, fetchData, Swal) => (dispatch, getState) => {
    commonAxios("delete-offline-message", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchData()
            }
            else {
                dispatch(setAlert(res.msg, "danger"))
            }
            Swal.close()
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            Swal.close()
            console.log(err)
        })
}

