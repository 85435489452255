import React from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';

const SummerNote = ({ state, setState, type }) => {
    const handleChange = (val) => {
        if (type === "edit") {
            setState({
                ...state,
                orignalContent: val
            })
        } else {
            setState({
                ...state,
                content: val
            })
        }
    }

    const editorConfiguration = {
        toolbar: ['heading', '|', 'bold', 'italic', '|', 'fontColor', 'fontBackgroundColor', '|', 'undo', 'redo']

    };

    return (
        <CKEditor
            onReady={editor => {
                editor.ui.getEditableElement().parentElement.insertBefore(
                    editor.ui.view.toolbar.element,
                    editor.ui.getEditableElement()
                );
            }}
            onError={(error, { willEditorRestart }) => {
                if (willEditorRestart) {
                    this.editor.ui.view.toolbar.element.remove();
                }
            }}
            onChange={(event, editor) => {
                const data = editor.getData();
                handleChange(data)
            }}

            editor={DecoupledEditor}
            data={state.orignalContent}
            config={editorConfiguration}
        />
    )
}

export default SummerNote