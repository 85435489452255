import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import TitleBar from "../TitleBar";
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import DefaultSetting from "./DefaultSetting";
import WorkingHours from "./WorkingHours";
import OfflineMessages from "./OfflineMessages";
import Integration from "./Integration/Integration";
import ApiKey from "./ApiKey";
import Outreach from "./Outreach/Outreach";
import { useSelector } from "react-redux";

const SettingPage = () => {

    const auth = useSelector(state => state.auth);
    const [memberShip, setMemberShip] = useState([])

    useEffect(() => {
        if (auth.user) {
            setMemberShip(auth.user.membership.split("__"))
        }
    }, [auth.user])

    return (
        <>
            <TitleBar title="Settings" />
            <Navbar />
            <section className="siteWrap">
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">

                    <div className="tabCont">
                        <div className="row">
                            <div className="col-lg-3 tabLeft">
                                <div className="tabCont-left">
                                    <Nav className="flex-column">
                                        <Nav.Item>
                                            <Nav.Link eventKey="first">General Settings</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="second">Working Hours</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="third">Offline Messages</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="forth">Integration</Nav.Link>
                                        </Nav.Item>
                                        {/* <Nav.Item>
                                            <Nav.Link eventKey="fifth">OpenAI API Key</Nav.Link>
                                        </Nav.Item> */}
                                        <Nav.Item>
                                            {memberShip.includes("enterprise") || memberShip.includes("bundle") || memberShip.includes("fastpass") ?
                                                <Nav.Link eventKey="sixth">Outreach</Nav.Link>
                                                : ""
                                            }
                                        </Nav.Item>
                                    </Nav>
                                </div>
                            </div>
                            <div className="col-lg-9 tabRight">
                                <div className="tabCont-right">
                                    <Tab.Content>
                                        <Tab.Pane eventKey="first">
                                            <DefaultSetting />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="second">
                                            <WorkingHours />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="third">
                                            <OfflineMessages />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="forth">
                                            <Integration />
                                        </Tab.Pane>
                                        {/* <Tab.Pane eventKey="fifth">
                                            <ApiKey />
                                        </Tab.Pane> */}
                                        <Tab.Pane eventKey="sixth">
                                            <Outreach />
                                        </Tab.Pane>
                                    </Tab.Content>
                                </div>
                            </div>
                        </div>
                    </div>


                </Tab.Container>
            </section >

            <Footer />
        </>
    )
}

export default SettingPage;