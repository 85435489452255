import React, { useState } from 'react'
import OpeningHours from './OpeningHours';
import { useSelector } from 'react-redux';
import TimezoneSelect from 'react-timezone-select'
import { onSelectTimezone } from '../../Actions/SettingAction';
import { useDispatch } from 'react-redux';

const WorkingHours = () => {
    const dispatch = useDispatch()
    const state = useSelector((state) => state.setting.data);
    // const [selectedTimezone, setSelectedTimezone] = useState(
    //     Intl.DateTimeFormat().resolvedOptions().timeZone
    // )

    const selectTimeZone = (e) => {
        dispatch(onSelectTimezone(e.value))
    }
    return (
        <div className="tabepaneIn">
            <h2 className="tabTitle">Opening hours</h2>
            <div className="tabepaneIn-main">
                <div className="openHr">
                    <div className="tabepaneIn-cont">
                        <h3 >Opening hours:</h3>

                        <div className="openHr-right mb-2" style={{ overflow: "unset" }}>
                            <div className="time_zone">
                                <div className="time_zone_head">
                                    <h2>Time zone</h2>
                                </div>
                                <div className="select_box">
                                    <TimezoneSelect
                                        value={state.timeZone}
                                        onChange={selectTimeZone}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="openHr-right">

                            {
                                state.openingHours && state.openingHours.length > 0 ?
                                    state.openingHours.map((curElem, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <OpeningHours
                                                    curElem={curElem}
                                                    index={index}
                                                    format={"12"}
                                                    state={state}
                                                />
                                            </React.Fragment>
                                        );
                                    })
                                    : ""
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WorkingHours