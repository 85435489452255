import React, { useEffect, useState } from 'react'
import { BiBrain } from 'react-icons/bi'
import { IoMdTrophy } from 'react-icons/io'
import { useSelector } from 'react-redux'
import { ImPushpin } from 'react-icons/im'
import { FiCheckCircle } from 'react-icons/fi'
import jsPDF from 'jspdf';
import { MdOutlineDownloadForOffline } from "react-icons/md"
import ContentEditable from 'react-contenteditable';
import { Modal } from 'react-bootstrap'
import modalCloseIcon from "../../images/modal-close.png";
import { onCreateGodMode, onExecuteGodMode, onResetTaskLimit } from '../../Actions/GodModeAction'
import { useDispatch } from 'react-redux'
import { setAlert } from '../../Actions/AlertAction'
import { usePDF } from '@react-pdf/renderer'
import { Document, Page, View, Text, StyleSheet , Font} from '@react-pdf/renderer';
import Mukta from "../../fonts/Mukta-Regular.ttf"
//////////////////Rehan PDF
//import html2pdf from 'html2pdf.js/dist/html2pdf.min';
//////////////////////////////
const AgentGpt = ({ conversationData, selectedcId, staff, godMode, messages, setMessages, index }) => {
    const dispatch = useDispatch()
    const godModeData = useSelector(state => state.godMode.data)
    const [show, setShow] = useState(false)
    const [taskLimit, setTaskLimit] = useState(godModeData.maxTask)
    const [pdf, setPdf] = useState(false)
    const [selectedMsg, setSelectedMsg] = useState(false)
    const [instance, updateInstance] = usePDF({
        document: pdf
    })

    const handleAction = (type) => {
        let chatName = conversationData.find((ele) => +ele.id === +selectedcId)
        let documentName = staff.hire_name + chatName.name + 7
        let data = "", newIndex = 1

        if (type === "doc") {
            if (godMode.results.length > 0) {
                godMode.results.forEach((curElem, index) => {
                    if (index === 0) {
                        data += `Goal : ${curElem.message}\n\n`
                    }
                    if (curElem.type === "response") {
                        data += `${newIndex}: ${curElem.task}\n\nResponse:\n${curElem.message}\n\n\n`
                        newIndex++
                    }
                })
            }
            let fileDownload = document.createElement("a");
            fileDownload.href = `data:application/vnd.ms-word;charset=utf-8,` + encodeURIComponent(data)
            fileDownload.download = `${documentName}.doc`;
            fileDownload.click();
        } else {
            handleGeneratePDF(godMode.results)
            setSelectedMsg(index)
        }
    }
        //-------Rehan PDF CODE--------
    
    const formatText = (text) => {
         const regex = /(.{100})/g;
         return text.replace(regex, '$1<br>');
    };
      const printHandle = () => {
    //     let copyText = document.getElementById(`floatingTextarea`)
    //     const element = document.createElement('div');
    //     element.innerHTML = `<pre>${formatText(copyText.value)}</pre>`;
    //     element.style.fontFamily = 'Arial';
    //     element.style.fontSize = '14px';
    //     element.style.color = 'black';
    //     element.style.padding = '10px';
    //     element.style.whiteSpace = 'pre-wrap';
    //     const options = {
    //         margin: 10,
    //         filename: 'DoitAll.pdf',
    //         image: { type: 'jpeg', quality: 0.98 },
    //         html2canvas: { scale: 2 },
    //         jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    //     };
    //     html2pdf().set(options).from(element).save();
     }
     /////////////////////////////////////////////////////////////////

    const handleOpen = () => {
        setShow(true)
    }

    const handleClose = () => {
        setShow(false)
    }

    const handleChange = (e) => {
        setTaskLimit(e.target.value)
    }

    const handleSubmit = () => {
        let doneTasks = 0
        godMode.tasks.forEach((curElem) => {
            if (curElem.status === 2) {
                doneTasks++
            }
        })
        if (+taskLimit > +doneTasks) {
            dispatch(onResetTaskLimit(taskLimit))
            handleClose(false)
            if (godMode.results[godMode.results.length - 1].message === "Task limit reached") {
                let data = { ...godModeData }
                let taskData = godMode.tasks.find(({ status }) => +status === 0)
                if (taskData) {
                    data.task = taskData.task
                    dispatch(onExecuteGodMode(data, messages, setMessages))
                } else {
                    dispatch(onCreateGodMode(messages, setMessages))
                }

            }
        } else {
            dispatch(setAlert(`Your ${doneTasks} task${doneTasks === 1 ? "" : "s"} ${doneTasks === 1 ? "is" : "are"} done. Please provide limit more than ${doneTasks}!`, "danger"))
        }
    }


    const handleGeneratePDF = (resultArr) => {
        Font.register({ family: 'Mukta', src: Mukta });
        const styles = StyleSheet.create({
            section: {
                padding: 5,
                flexGrow: 1,
                fontSize: 10,
                border: "1px solid black"
            },
            footer: {
                fontSize: 11,
                position: "absolute",
                bottom: 11,
                right: 12,
            }
        })
        let newIndex = 0
        setPdf(
            <Document >
                <Page style={{ padding: 10 , fontFamily : "Mukta"}}>
                    <View style={styles.section}>
                        {resultArr.length > 0 ?
                            resultArr.map((curElem, index) => {
                                if (curElem.type === "response") {
                                    newIndex++
                                }
                                return (
                                    <React.Fragment key={index}>
                                        {index === 0 ?
                                            <>
                                                <View style={{ fontWeight: 600, marginBottom: 4 , fontSize : 13}}>
                                                    <Text>Goal: {curElem.message}</Text>
                                                </View>
                                            </>
                                            : null}
                                        {
                                            curElem.type === "response" ?
                                                <>
                                                    <View style={{ paddingTop: 8, paddingBottom: 4, fontSize: 12, fontWeight: "bold" }}>
                                                        <Text>{newIndex}{": "} {curElem.task}</Text>
                                                    </View>
                                                    <View style={{ marginTop: 4, fontWeight: "bold", fontSize: 11 }}>
                                                        <Text>Response: </Text>
                                                    </View>
                                                    <View style={{ paddingLeft: 3 , marginBottom : 6}}>
                                                        <Text>{curElem.message}</Text>
                                                    </View>
                                                </> : null
                                        }

                                    </React.Fragment>
                                )
                            })

                            : ""}

                        <View style={styles.footer}>
                            <Text>This PDF is generated by AiStaffs.</Text>
                        </View>
                    </View>
                </Page>
            </Document >)
    }

    useEffect(() => {
        if (pdf && (index === selectedMsg)) {
            updateInstance({
                document: pdf
            })
        }
    }, [pdf])

    useEffect(() => {
        if (instance.url && (index === selectedMsg)) {
            let a = document.createElement('a')
            a.href = instance.url
            a.download = staff.hire_name + 7 + ".pdf"
            a.click()
            setSelectedMsg(false)
            setPdf(false)
        }
    }, [instance])

    useEffect(() => {
        let scrollDiv = document.getElementById("godChatBox")
        if (scrollDiv.clientHeight < scrollDiv.scrollHeight) {
            scrollDiv.scrollBy(0, scrollDiv.scrollHeight)
        }
    }, [godMode.results])

    useEffect(() => {
        let scrollDiv = document.getElementById("godModeTask")
        if (scrollDiv.clientHeight < scrollDiv.scrollHeight) {
            scrollDiv.scrollBy(0, scrollDiv.scrollHeight)
        }
    }, [godMode.tasks])

    return (
        <div className="GodMode_wrap_main" >
            <div className="row"  >
                <div className="col-md-8  mb-sm-2 p-0">
                    <div className="AgentGpt_wrapper">
                        <div className="AgentGpt_head">
                            <div className="agent_heading">
                                <h2>
                                    God Mode
                                </h2>
                            </div>
                            <div className="dropdown">
                                <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                    Export
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li
                                        className="dropdown-item cursor-pointer"
                                        onClick={() => handleAction("doc")}
                                        style={{ fontSize: "15px", color: "#000", fontFamily: "arial", fontWeight: "500" }}
                                    >
                                        <MdOutlineDownloadForOffline className='mr-1' size={20} />   DOC
                                    </li>
                                    <li
                                        className="dropdown-item cursor-pointer"
                                        onClick={() => handleAction("pdf")}
                                        style={{ fontSize: "15px", color: "#000", fontFamily: "arial", fontWeight: "500" }}
                                    >
                                        <MdOutlineDownloadForOffline className='mr-1' size={20} />   PDF
                                    </li>
                                </ul>
                            </div>

                        </div>
                        <div className="breakLine">
                            <hr style={{ opacity: "1", color: "#ffffff33", height: "2px" }} />

                        </div>
                        <div className="AgentGpt_Response_warp" id='godChatBox'>

                            {godMode.results.length > 0 ?
                                godMode.results.map((curElem, index) => {
                                    return (
                                        <div className="agentGpt_main" key={index} style={{ borderColor: curElem.type === "response" ? '#3ce73c' : "" }}>
                                            {index === 0 ?
                                                <>
                                                    <IoMdTrophy style={{ color: "white", fontSize: 20, marginRight: 1 }} />
                                                    <span style={{ fontWeight: "bold" }}> My Goal :</span>
                                                </>
                                                : ""}
                                            {curElem.type === "thinking" ?
                                                <>
                                                    <BiBrain style={{ color: "white", fontSize: 20, marginRight: 1 }} />
                                                    <span style={{ fontWeight: "bold" }}> Thinking
                                                        {index === godMode.results.length - 1 ?
                                                            <span className="typing" style={{ display: "inline-block" }}>
                                                                <span></span>
                                                                <span></span>
                                                                <span></span>
                                                            </span> : "..."}
                                                    </span>

                                                </>

                                                : ""}

                                            {curElem.type === "addedTask" ?
                                                <>
                                                    <ImPushpin style={{ color: "white", fontSize: 18, marginRight: 1, transform: "scaleX(-1)" }} />
                                                    <span style={{ fontWeight: "bold" }}> Added task :</span>
                                                </>
                                                : ""}
                                            {
                                                curElem.type === "response" ?
                                                    <>
                                                        <span style={{ fontWeight: "bold" }}><FiCheckCircle color='#3ce73c' /> Response : </span>
                                                        <span className='mx-1'>{curElem.task}</span>
                                                        <hr style={{ color: "#848080", opacity: 1 }} />
                                                    </>
                                                    : ""
                                            }
                                            {/* <span className={`mx-1 ${curElem.type === "thinking" ? "d-none" : ""}`} >{curElem.message}</span> */}
                                            <ContentEditable
                                                className={`mx-1 ${curElem.type === "thinking" ? "d-none" : ""}`}
                                                html={curElem.message.replaceAll('\n', '<br />')}
                                                disabled={true}
                                                tagName='span'
                                            />
                                        </div>
                                    )
                                })
                                : ""}
                        </div>
                    </div>

                </div>
                <div className="col-md-4">
                    <div className="agent_tasks_wrapper">
                        <div className="agent_tasks_heading text-center" >
                            <h2>
                                Current tasks
                            </h2>
                            <div className="breakLine">
                                <hr style={{ opacity: "1", color: "#ffffff33", height: "2px" }} />

                            </div>
                        </div>
                        <div className="agentTasks_response_wrap text-center" id='godModeTask'>
                            {godMode.tasks.length > 0 ?
                                godMode.tasks.map((curElem, index) => {
                                    return (
                                        <div className="agent_tasks_main text-left" key={index} style={{ borderColor: curElem.status === 2 ? "#3ce73c" : "" }}>

                                            {curElem.status === 0 ?
                                                <ImPushpin className='mr-1' style={{ color: "white", fontSize: 13, transform: "scaleX(-1)" }} />
                                                : ""}
                                            {curElem.status === 1 ?
                                                <i className="fa fa-spinner fa-spin mr-1" style={{ fontSize: 13 }} />
                                                : ""}
                                            {curElem.status === 2 ?
                                                <FiCheckCircle className='mr-1' color='#3ce73c' size={13} />
                                                : ""}
                                            <span> {curElem.task}</span>
                                        </div>
                                    )
                                })
                                : ""}
                            {index === messages.length - 1 ?
                                <button onClick={handleOpen} className='demoLink'>Add More Tasks</button> : ""}
                        </div>

                    </div>

                </div>
            </div>
            <Modal className="VideoModal dark" show={show} onHide={handleClose} centered>
                <Modal.Body>
                    <div onClick={handleClose} className="vidClose">
                        <img alt="" src={modalCloseIcon} />
                    </div>
                    <div className="modal-txt">
                        <div>
                            <div className="form-group form-group-alt">
                                <label className="form-text text-muted" style={{ zIndex: 1 }}>Enter Max Tasks</label>
                                <input
                                    className="form-control"
                                    name="maxTask"
                                    placeholder='Enter max task here'
                                    value={taskLimit}
                                    onChange={(e) => handleChange(e)}
                                    type="number"
                                    required
                                />
                            </div>
                            <div className="form-group button-group d-flex justify-content-end">
                                <button className="demoLink" type='submit' onClick={handleSubmit}>Add</button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal >
        </div>
    )
}

export default AgentGpt