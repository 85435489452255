import React, { useEffect, useRef, useState } from 'react'
import { BsFolder, BsFolderPlus } from "react-icons/bs"
import Nav from 'react-bootstrap/Nav';
import { Modal } from "react-bootstrap";
import CreateBotModal from "./CreateBotModal";
import modalCloseIcon from "../../images/modal-close.png";
import { useDispatch } from 'react-redux';
import { onCreateFolder, onDeleteFolder } from '../../Actions/TrainingAction';
import { BiTrash } from 'react-icons/bi';
import SweetAlertTwo from "../SweetAlertTwo"
import { FaRobot } from 'react-icons/fa';
const FolderStuff = ({ folder, loader, fetchFolders }) => {
    const dispatch = useDispatch()
    const [searchKey, setSearchKey] = useState("")
    const [name, setName] = useState("")
    const [createLoader, setCreateLoader] = useState(false)

    const [show, setShow] = useState(false)
    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)

    const [createModal, setCreateModal] = useState(false);
    const createModalClose = () => setCreateModal(false);
    const createModalShow = () => setCreateModal(true);
    const nameInputRef = useRef(null);
    const handleInvalid = () => {
        const input = nameInputRef.current;
        if (input && input.validity.valueMissing) {
            input.setCustomValidity("Please Enter Directory Name.");
        }
    };

    const [info, setInfo] = useState({
        id: -1,
        enable: false,
    })

    const handleSubmit = (e) => {
        e.preventDefault()
        setCreateLoader(true)
        let data = {
            name: name
        }
        dispatch(onCreateFolder(data, handleClose, fetchFolders, setCreateLoader))
    }

    const handleDelete = (e, id) => {
        e.stopPropagation()
        setInfo({
            ...info,
            id: id,
            enable: true
        })
    }

    const handleCancel = () => {
        setInfo({
            ...info,
            id: -1,
            enable: false
        })
    }

    const deleteFolder = (Swal) => {
        let data = {
            tid: info.id
        }
        dispatch(onDeleteFolder(data, fetchFolders, Swal))
        setInfo({
            ...info,
            id: -1,
            enable: false
        })
    }

    useEffect(() => {
        if (!show) {
            setName("")
        }
    }, [show])

    return (
        <div className="training-sec-left">
            <button className="demoLink full mt-0 mb-2" onClick={createModalShow}>
                <FaRobot />
                Create youAi Agent
            </button>
            <div className="search-folder">


                <div className="search-folder-left">
                    <input
                        type="text"
                        placeholder="Search training directory"
                        onChange={(e) => setSearchKey(e.target.value)}
                    />
                </div>
                <div className="search-folder-right">
                    <button onClick={handleShow}><BsFolderPlus /></button>
                </div>
            </div>

            <Nav variant="hh" className="flex-column tab-side-nav tranning_sideBar">

                {folder.length > 0 ?
                    folder.filter((fold) => {
                        return searchKey !== "" ? fold.name.toLowerCase().includes(searchKey.toLowerCase()) : fold
                    }).map((curElem, index) => {
                        return (
                            <Nav.Item key={index} >
                                <Nav.Link eventKey={`folder_${curElem.id}`} style={{ minHeight: "56px", maxHeight: "100%", height: "100%" }}>
                                    <div className="FolderStuff_warp ">
                                        <div>
                                            <BsFolder />
                                        </div>
                                        <span className='text-capitalize'>{curElem.name}</span>
                                        <span className='mx-3' title='Number of character'>({curElem.totalChar})</span>
                                    </div>

                                    <span style={{ fontSize: "16px", display: "flex" }}>
                                        {curElem.totalDoc.length}
                                        <BiTrash
                                            style={{ marginBottom: "3px", marginLeft: 8 }}
                                            onClick={(e) => handleDelete(e, curElem.id)}
                                            className='button-hover-effect'
                                        />
                                    </span>
                                </Nav.Link>
                            </Nav.Item>
                        )
                    })
                    :
                    <div className='text-center text-light'>
                        {loader ? <i className="fa fa-spinner fa-spin" style={{ fontSize: "30px", color: "#c238a4", marginTop: "50px" }} /> : "No Folder Created Yet"}
                    </div>
                }
            </Nav>


            <CreateBotModal
                show={createModal}
                handleClose={createModalClose}
            />

            <Modal className="VideoModal dark" show={show} onHide={handleClose} centered>
                <Modal.Body>
                    <div onClick={handleClose} className="vidClose">
                        <img alt="" src={modalCloseIcon} />
                    </div>
                    <form className="modal-txt mb-3" onSubmit={handleSubmit}>
                        <div className=" text-left" style={{
                            position: 'relative',
                            top: '6px',
                            margin: '12px 0',
                        }}>
                            <h4>Create Training Directory</h4>
                        </div>
                        <hr />
                        <div className="form-group form-group-alt">
                            <label className="form-text text-muted" htmlFor="name">Directory Name</label>
                            <input
                                className="form-control"
                                type="text"
                                id='name'
                                placeholder="Enter Directory Name Here"
                                value={name}
                                ref={nameInputRef}
                                onChange={(e) => setName(e.target.value)}
                                required
                                onInvalid={handleInvalid}
                                title='Please Enter Directory Name'
                            />
                        </div>
                        <div className=" bot_btn text-center">
                            <button type='submit' className="btn text-light ">{createLoader ? <> Creating <i className="fa fa-spinner fa-spin mx-1" /></> : "Create"}</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal >


            <SweetAlertTwo
                show={info.enable}
                title="Are You Sure?"
                text="You won't be able to revert this!"
                icon="warning"
                showCancelButton={true}
                confirmButtonColor='#b32f3d'
                cancelButtonColor='#2a2c3a'
                confirmButtonText='Yes, delete it!'
                confirmTitle='Deleting...'
                confirmText="This won't be revert!"
                confirmIcon='success'
                handlePerform={deleteFolder}
                onCancel={handleCancel}
            />
        </div>
    )
}

export default FolderStuff