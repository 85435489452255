import React, { useEffect, useState } from "react";
import Footer from "../Footer";
import DashboardHead from "./DashboardHead";
import DashboardContent from "./DashboardContent";
import DashboardAbout from "./DashboardAbout";
import Webinar from "./Webinar";
import TitleBar from "../TitleBar";
import Navbar from "../Navbar";

const Dashboard = () => {

    const [widget, setWidget] = useState(false)

    useEffect(() => {
        if (widget[0]) {
            widget[0].hidden = false
            setTimeout(() => {
                widget[0].hidden = false
            }, 200);
        } else {
            setWidget(false)
            setTimeout(() => {
                setWidget(document.getElementsByClassName("ewebinar__Widget"))
            }, 100);
        }
        return () => {
            if (widget[0]) {
                widget[0].hidden = true
            }
        }
    }, [widget])

    return (
        <>
            <TitleBar title="Dashboard" />
            <Navbar />
            <section className="siteWrap">
                <DashboardHead />
                <DashboardContent />
                <DashboardAbout />
            </section>
            <Webinar />
            <Footer />
        </>
    )
}

export default Dashboard;