import React, { useEffect, useState } from "react";
import Footer from "../Footer";
import { onFetchConversationData, onFetchStaffData } from "../../Actions/StaffAction";
import { useDispatch } from "react-redux";
import ConversationCard from "./ConversationCard";
import { BsSearch } from "react-icons/bs";
import Select from 'react-select'
import InfiniteScroll from "react-infinite-scroll-component";


const Conversations = () => {

    const dispatch = useDispatch();
    const [conversation, setConversation] = useState([])
    const [conversationsEmbed, setConversationsEmbed] = useState([])
    const [staff, setStaff] = useState([])
    const [loader, setLoader] = useState(false)
    const [convLimit, setConvLimit] = useState(0)
    const [filterData, setFilterData] = useState({
        name: "",
        staff: ""
    })
    const [filterData2, setFilterData2] = useState({
        name: "",
        staff: ""
    })
    const [htmlTag, setHtmlTag] = useState([]);
    const [pageLoader, setPageLoader] = useState(false)

    const onfetchStaffName = () => {
        let data = {}
        dispatch(onFetchStaffData(data, setStaff, "Add"))
    }
    const handleSelectStaff = (e, type) => {
        if (type) {
            setFilterData({
                ...filterData,
                [type]: e.value
            })
        } else {
            const { name, value } = e.target;
            setFilterData({
                ...filterData,
                [name]: value
            })
        }

    }
    const handleSelectStaff2 = (e, type) => {
        if (type) {
            setFilterData2({
                ...filterData,
                [type]: e.value
            })
        } else {
            const { name, value } = e.target;
            setFilterData2({
                ...filterData,
                [name]: value
            })
        }

    }

    const conversationData = (Swal, page = 0, fetchType) => {
        setLoader(true)
        let data = {
            offset: page
        }
        dispatch(onFetchConversationData(data, conversation, setConversation, setLoader, false, Swal, conversationsEmbed, setConversationsEmbed, setConvLimit, fetchType))
    }
    useEffect(() => {
        conversationData(false)
        onfetchStaffName()
    }, [])
    useEffect(() => {
        let data = staff;
        const selectAllIndex = data.findIndex((item) => item.label === "Select All");

        if (selectAllIndex !== -1) {
            const selectAllObject = data.splice(selectAllIndex, 1);
            data.unshift(...selectAllObject);
        }
        setStaff(data)

    }, [staff])
    return (
        <>

            <section className="siteWrap">
                <section className="convSec convSec-page">
                    <div className="container">
                        <div className="convSec-wrap">
                            <h2 className="infoTitle p-0">Your Conversation</h2>
                            <hr style={{ color: "#c238a4", opacity: 0.5 }} />

                            <div className="convSec-search">
                                <div className="convSec-search-single staffS_selecters">
                                    <label htmlFor="">Staffs</label>
                                    <Select
                                        className="ml-3"
                                        options={staff}
                                        onChange={(e) => handleSelectStaff(e, "staff")}
                                        isSearchable={false}
                                    />
                                </div>
                                <div className="convSec-search-single ml-3">
                                    <div className="search-bar">
                                        <span><BsSearch /></span>
                                        <input
                                            type="text"
                                            name="name"
                                            placeholder="Search here"
                                            className="ml-3"
                                            value={filterData.name}
                                            onChange={handleSelectStaff}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="convRow chat-area-main" id="scrollableDiv" style={{ marginTop: "50px", height: 500, overflowY: 'scroll' }} >
                                <InfiniteScroll
                                    dataLength={conversation.length}
                                    next={() => conversationData(false, conversation.length + 1)}
                                    hasMore={conversation.length > 0 ? conversation.length === convLimit ? false : true : false}
                                    // height={500}
                                    scrollableTarget="scrollableDiv"
                                >
                                    <div className="row">
                                        {
                                            conversation.length > 0 ?
                                                conversation.filter((item) => {
                                                    if (filterData.staff !== "") {
                                                        return +item.staffId === +filterData.staff
                                                    }
                                                    else {
                                                        return item
                                                    }
                                                }).filter((conve) => {
                                                    if (filterData.name !== "") {
                                                        return conve.name.toLowerCase().includes(filterData.name.toLowerCase())
                                                    } else {
                                                        return conve
                                                    }
                                                }).length > 0 ?
                                                    conversation.filter((item) => {
                                                        if (filterData.staff !== "") {
                                                            return +item.staffId === +filterData.staff
                                                        }
                                                        else {
                                                            return item
                                                        }
                                                    }).filter((conve) => {
                                                        if (filterData.name !== "") {
                                                            return conve.name.toLowerCase().includes(filterData.name.toLowerCase())
                                                        } else {
                                                            return conve
                                                        }
                                                    }).map((curElem, index) => {
                                                        return (
                                                            <React.Fragment key={index}>
                                                                <ConversationCard
                                                                    curElem={curElem}
                                                                    conversationData={conversationData}
                                                                    setConversation={setConversation}
                                                                />
                                                            </React.Fragment>
                                                        )
                                                    })
                                                    :

                                                    <p className="text-center mb-0 text-light">No conversation found!</p>
                                                : ""
                                        }
                                    </div>
                                    <div className="text-center mt-1 text-light"  >
                                        {
                                            loader ?
                                                <i className="fa fa-spinner fa-spin" style={{ fontSize: "30px", color: "#c238a4", marginTop: 20 }} />
                                                :
                                                conversation.length === 0 ? `"No conversation available!"` : ""}
                                    </div>
                                </InfiniteScroll>


                            </div>
                        </div>


                        <div className="convSec-wrap my-5">
                            <h2 className="infoTitle p-0">Your Embed Conversation</h2>
                            <hr style={{ color: "#c238a4", opacity: 0.5 }} />
                            <div className="convSec-search">
                                <div className="convSec-search-single staffS_selecters">
                                    <label htmlFor="">Embed</label>
                                    <Select
                                        className="ml-3"
                                        options={staff}
                                        onChange={(e) => handleSelectStaff2(e, "staff")}
                                        isSearchable={false}
                                    />
                                </div>
                            </div>
                            <div className="convRow chat-area-main"
                                id="infiniteScrollDiv" style={{ marginTop: "50px", height: 500, overflowY: 'scroll' }}>


                                <div className="row convRow" style={{ marginTop: "50px" }}>

                                    {
                                        conversationsEmbed.length > 0 ?
                                            conversationsEmbed.filter((item) => {
                                                if (filterData2.staff !== "") {
                                                    return +item.staffId === +filterData2.staff
                                                }
                                                else {
                                                    return item
                                                }
                                            }).filter((conve) => {
                                                if (filterData2.name !== "") {
                                                    return conve.name.toLowerCase().includes(filterData2.name.toLowerCase())
                                                } else {
                                                    return conve
                                                }
                                            }).length > 0 ?
                                                conversationsEmbed.filter((item) => {
                                                    if (filterData2.staff !== "") {
                                                        return +item.staffId === +filterData2.staff
                                                    }
                                                    else {
                                                        return item
                                                    }
                                                }).filter((conve) => {
                                                    if (filterData2.name !== "") {
                                                        return conve.name.toLowerCase().includes(filterData.name.toLowerCase())
                                                    } else {
                                                        return conve
                                                    }
                                                }).map((curElem, index) => {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <ConversationCard
                                                                curElem={curElem}
                                                                conversationData={conversationData}
                                                                type={"embed"}
                                                                setConversationsEmbed={setConversationsEmbed}
                                                            />
                                                        </React.Fragment>
                                                    )
                                                })
                                                :

                                                <p className="text-center mb-0 text-light">No embed conversations found!</p>
                                            : ""
                                    }
                                    <div className="text-center mt-1 text-light"  >
                                        {conversationsEmbed.length === 0 ?
                                            loader ?
                                                <i className="fa fa-spinner fa-spin" style={{ fontSize: "30px", color: "#c238a4", marginTop: "50px" }} />
                                                :
                                                `"No conversation available!"` :
                                            ""}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
            </section>




            <Footer />
        </>
    )
}

export default Conversations;