import produce from "immer"
const initialState = {
    data: {
        hire_image: "",
        hire_name: "",
        hire_category: "",
        hire_id: "",
        staffId: "",
        hire_description: "",
        hireDate: ""
    }
}

export const StaffReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ADD_STAFF_DATA":
            return produce(state, (draft) => {
                draft.data = action.payload
            })

        case "UNMOUNT_CHAT_STAFF":
            return initialState
        default:
            return state
    }
}