import { commonAxios } from "../Global/CommonAxios"
import { setAlert } from "./AlertAction"
import { Document, Page, View, Text, StyleSheet, Font } from '@react-pdf/renderer';
import React from "react"
import Mukta from "../fonts/Mukta-Regular.ttf"

export const onFetchStaffData = (data, setHiredSatff, setStaff, onfetchExpertise, setLoader, limit) => (dispatch, getState) => {
    commonAxios("view-hire-staff", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let hire = [], fire = []
                res.data.forEach((curElem) => {
                    if (+curElem.isfired === 1) {
                        fire = [...fire, curElem]
                    } else {
                        hire = [...hire, curElem]
                        if (limit) {
                            hire = hire.slice(0, limit)
                        }
                    }
                })
                if (setStaff && setStaff !== "Add") {
                    setStaff(fire);
                }

                if (setStaff === "Add") {
                    hire = res.data.length > 0 ?
                        res.data.map((item) => {
                            return {
                                ...item,
                                label: +item.isfired === 1 ? item.name : item.hire_name,
                                value: item.id
                            }
                        }) : []
                    hire.sort((a, b) => {
                        if (a.name > b.name) {
                            return 1
                        }
                        else {
                            return -1
                        }
                    })
                    hire.unshift({ label: "Select All", value: "" })
                    //console.log(hire);
                }

                setHiredSatff(hire)
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            if (setLoader) {
                setLoader(false)
            }
            onfetchExpertise()
        })
        .catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            if (setLoader) {
                setLoader(false)
            }
        })
}


export const onFetchStaffDataForDashboard = (data, state, setState) => (dispatch, getState) => {
    commonAxios("view-hire-staff", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState({
                    ...state,
                    hiredStaff: res.data.filter((curElem) => {
                        return +curElem.isfired !== 1
                    }).length,
                    totalStaff: res.data.length
                })

            }
        })
        .catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
        })
}

export const onFetchProgress = (setState) => (dispatch, getState) => {
    commonAxios("fetch-current-limit", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState(res.data)
            }
        }).catch((err) => {
            console.log(err)
        })
}

export const onChangeStatus = (data, ep, swal, fetchStaffData,) => (dispatch, getState) => {
    commonAxios(ep, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                fetchStaffData()
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            swal.close()
        })
        .catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            swal.close()
        })
}
export const onHireStaff = (data, fetchStaffData, handleClose, loader, setLoader) => (dispatch, getState) => {
    commonAxios("hire-staff", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                fetchStaffData()
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                hireLoader: false
            })
            handleClose()
        })
        .catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            setLoader({
                ...loader,
                hireLoader: false
            })
        })
}

export const uploadBotImage = (formData, state, setState, loader, setLoader) => (dispatch, getState) => {
    commonAxios("upload-file", formData, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState({
                    ...state,
                    image: res.data.path,
                })
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                uploadLoader: false
            })
        })
        .catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            setLoader({
                ...loader,
                uploadLoader: false
            })
        })
}

export const uploadEmbedIcon = (formData, state, setState, loader, setLoader) => (dispatch, getState) => {
    commonAxios("upload-user-file", formData, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState({
                    ...state,
                    image: res.data,
                })
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                uploadLoader: false
            })
        })
        .catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            setLoader({
                ...loader,
                uploadLoader: false
            })
        })
}

export const fetchUserUpload = (data, setState) => (dispatch, getState) => {
    commonAxios("fetch-user-upload-file", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState(res.data)
            }
        }).catch((err) => {
            console.log(err)
        })
}

export const onCreateConversation = (data, fetchConversation, setSelectedcId, setActiveBlink, loader, setLoader) => (dispatch, getState) => {
    commonAxios("create-conversation", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setSelectedcId(res.data.cid)
                fetchConversation(res.data.cid)
                setActiveBlink(true)
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({ ...loader, btnLoader: false })
        })
        .catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            setLoader({ ...loader, btnLoader: false })
        })

}
export const onFetchOptions = (ep, data, setState, type) => (dispatch, getState) => {
    commonAxios(ep, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let arr = res.data
                arr.sort((a, b) => {
                    if (a.name > b.name) { return 1 }
                    else {
                        return -1
                    }
                })
                if (type === "select") {
                    setState(arr.map((curElem) => {
                        return {
                            label: curElem.name,
                            value: curElem.name
                        }
                    }))
                } else {
                    setState(arr)
                }
            }
        })
        .catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
        })

}

export const onFetchHireData = (data, setState) => (dispatch, getState) => {
    commonAxios("hire-data", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState(res.data[0].hire_image)
            }
        })
        .catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
        })
}

export const onSaveDataEmbed = (data, curElem, setEmbedId, setLoader, loader) => (dispatch, getState) => {
    let obj = {
        ar: data.autoresponderId,
        staffId: data.staffId,
        arList: data.listOption,
        image: data.image,
        url: data.url,
        name: data.name,
        type: curElem.type,
        staffName: +curElem.isfired ? curElem.name : curElem.hire_name
    }
    commonAxios("create-embed-code", obj, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setEmbedId(res.data)
                setLoader({
                    ...loader,
                    tab: false,
                    generateLoader: false
                })

            } else {
                setLoader({
                    ...loader,
                    generateLoader: false
                })
                dispatch(setAlert(res.msg, "danger"))
            }

        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            setLoader({
                ...loader,
                generateLoader: false
            })
        })
}

export const onFetchAllEmbed = (setData, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-all-embed-code", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setData(res.data)
            } else {
                setData([])
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err)
            setLoader(false)
        })
}
export const onUpdateEmbed = (data, fetchData, Swal) => (dispatch, getState) => {
    commonAxios("update-embed-code", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchData()
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            Swal.close()
        }).catch((err) => {
            console.log(err)
            Swal.close()
        })
}



// conversation Axios.......................................................................

export const onFetchConversationData = (data, conversation, setConversation, setLoader, limit, swal, conversationsEmbed, setConversationsEmbed, setConvLimit, fetchType) => (dispatch, getState) => {
    commonAxios("view-conversation", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                if (swal) {
                    swal.close()
                }
                if (fetchType === "delete") {

                    setConversation(res.data.conversations)
                } else if (fetchType === "embedDelete") {
                    setConversationsEmbed(res.data.conversations_embed)
                }
                else {
                    setConversation(
                        limit ? res.data.conversations.slice(0, limit)
                            :
                            conversation.concat(res.data.conversations)

                    )
                    setConversationsEmbed(res.data.conversations_embed?.length > 0 ? conversationsEmbed.concat(res.data.conversations_embed) : conversationsEmbed.length > 0 ? conversationsEmbed.concat(res.data.conversations_embed) : [])
                }


                //setConversationsEmbed(res.data.conversations_embed.length > 0 ? res.data.conversations_embed : [])
                if (setConvLimit) {
                    setConvLimit(res.data.total - 1)
                }
            } else {
                setConversation([])
                setConversationsEmbed([])
            }
            setLoader(false)
        })
        .catch((err) => {
            setLoader(false)
            dispatch(setAlert(err.msg, "danger"))
        })
}

export const fetchExpertise = (setExpertise) => (dispatch, getState) => {
    commonAxios("fetch-expertise", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let data = res.data.map((item) => {
                    return {
                        value: item, label: item
                    }
                })
                data.unshift({
                    label: "Select All",
                    value: ""
                })
                setExpertise(data)
            }
        }).catch((err) => {
            console.log(err)
        })
}
export const fetchHiredExpertise = (setHiredExpertise) => (dispatch, getState) => {
    commonAxios("fetch-hire-expertise", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let data = res.data.map((item) => {
                    return {
                        value: item.hire_category, label: item.hire_category
                    }
                })
                data.unshift({
                    label: "Select All",
                    value: ""
                })
                setHiredExpertise(data)

            }
        }).catch((err) => {
            console.log(err)
        })
}


//Chat Axios..............................................................................


export const onFetchConStaffData = (data, setConversationData, handleStartChat, setLoader, loader) => (dispatch, getState) => {
    commonAxios("view-staff-conversation", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setConversationData(res.data)
            } else {
                handleStartChat(false)
                setConversationData([])
            }
            setLoader({ ...loader, conLoader: false })
        })
        .catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            setLoader({ ...loader, conLoader: false })
        })
}
export const onFetchMessage = (data, setMessages, setLoader) => (dispatch, getState) => {
    commonAxios("view-messages", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                for (const obj of res.data) {
                    if (obj.type === "assistant") {
                        obj.messages = obj.messages.replaceAll(/</g, "&lt;").replaceAll(/>/g, "&gt").
                            replaceAll("```html", `<pre style="background-color:black;color:white;width:100%,overflow-x:scroll;border-radius:10px;padding:10px">`).
                            replaceAll("```", "</pre>").
                            replaceAll("\n", "<br />").
                            replaceAll("```vba", `<pre style="background-color:black;color:white;width:100%,overflow-x:scroll;border-radius:10px;padding:10px">`).
                            replaceAll("```\n", "</pre>")
                        //obj.messages=obj.messages.replaceAll(/</g,"&lt;").replaceAll(/>/g,"&gt").replaceAll("```html",`<pre>`).replaceAll("```","</pre>")
                        //console.log(obj.messages);
                    }
                }
                for (const obj of res.data) {
                    if (obj.type === "assistant") {
                        // obj.messages = obj.messages.replaceAll(/</g, "&lt;").replaceAll(/>/g, "&gt").replaceAll("```html", `<pre style="background-color:black;color:white;width:100%,overflow-x:scroll;border-radius:10px;padding:10px">`).replaceAll("```", "</pre>")
                        // //obj.messages=obj.messages.replaceAll(/</g,"&lt;").replaceAll(/>/g,"&gt").replaceAll("```html",`<pre>`).replaceAll("```","</pre>")
                        // console.log(obj.messages);
                        // console.log(obj.orignalMessage);
                    }

                }
                //onsole.log(res.data);
                setMessages(res.data.reverse())
            } else {
                setMessages([])
            }
            setLoader(false)
        })
        .catch((err) => {
            setLoader(false)
            console.log(err)
        })
}

export const onClearConversation = (data, fetchMessages, Swal) => (dispatch, getState) => {
    commonAxios("delete-staff-conversation", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchMessages()
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            Swal.close()
        })
        .catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            Swal.close()
        })
}

export const onChatEdit = (data, setIsReadOnly, fetchConversation) => (dispatch, getState) => {
    commonAxios("edit-conversation-name", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            fetchConversation(data.cid)
            setIsReadOnly(true)
        })
        .catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            setIsReadOnly(true)
        })
}

export const onDeleteChat = (data, fetchConversation, setSelectedcId, fetchMessage, swal, setConversation, setConversationsEmbed, setConversationData) => (dispatch, getState) => {
    return commonAxios("delete-conversation", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                if (setSelectedcId) {
                    setSelectedcId(false)
                }
                if (fetchMessage) {
                    fetchMessage()
                }
                if (data.type === "embed") {
                    setConversationsEmbed([])
                } else if (setConversationData) {
                    setConversationData([])
                }
                else {
                    setConversation([])

                }

                fetchConversation(swal, 0, data.type === "embed" ? "embedDelete" : "delete")
            } else {
                dispatch(setAlert(res.msg, "danger"))
                swal.close()
            }
        })
        .catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            console.log(err, "Error msg");
            swal.close()
        })

}

export const onCreateMessage = (data, messages, setMessages, setTypingActive, conversationData, setConversationData, setSelectedcId, setActiveBlink, handlePlay) => (dispatch, getState) => {
    commonAxios("create-message", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let arr = [...messages]
                if (res.data.messages) {
                    res.data.messages = res.data.messages.replaceAll(/</g, "&lt;")
                        .replaceAll(/>/g, "&gt")
                        .replaceAll("```html", `<pre style="background-color:black;color:white;width:100%,overflow-x:scroll;border-radius:10px;padding:10px">`)
                        .replaceAll("```vba", `<pre style="background-color:black;color:white;width:100%,overflow-x:scroll;border-radius:10px;padding:10px">`)
                        .replaceAll("```\n\n", `</pre>`)
                        .replaceAll("```css", `<pre style="background-color:black;color:white;width:100%,overflow-x:scroll;border-radius:10px;padding:10px">`)
                        .replaceAll("```", "</pre>").replaceAll("\n", "<br />")

                }  /**
                 for(const obj of res.data){
                    if(obj.type=="assistant"){
                        obj.messages=obj.messages.replaceAll(/</g,"&lt;").replaceAll(/>/g,"&gt").replaceAll("```html",`<pre style="background-color:black;color:white;width:100%,overflow-x:scroll;border-radius:10px;padding:10px">`).replaceAll("```","</pre>")
                    }
                }
                 */


                //.replaceAll(/</g,"&lt;").replaceAll(/>/g,"&gt").replaceAll("```html",`<pre style="background-color:black;color:white;width:100%,overflow-x:scroll;border-radius:10px;padding:10px">`).replaceAll("```","</pre>")
                // res.data.messages= res.data.messages.replaceAll(/</g, '&lt;').replaceAll(/>/g, '&gt;');

                //console.log(res.data.messages);

                let obj = {
                    messages: res.data.messages,
                    sources: {
                        link: ""
                    },
                    type: "assistant"
                }
                if (+data.staffType === 2 && typeof (messages) !== "string") {
                    obj = {
                        ...obj,
                        graphics: res.data.list.messages,
                        image: "",
                        messages: res.data.headingMessage
                    }
                }
                arr.push(obj)
                setMessages(arr)
                handlePlay(res.data.audioUrl)
                setTypingActive(false)
                setActiveBlink(true)
                setSelectedcId(data.cid)
            } else {
                dispatch(setAlert(res.msg, "danger"))
                setTimeout(() => {
                    setTypingActive(false)
                }, 3500)
                setActiveBlink(false)
            }
        })
        .catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            setActiveBlink(false)
            setTimeout(() => {
                setTypingActive(false)
            }, 4000)
        })
}

export const onGenerateImage = (data, messages, setMessages, index, graphicIndex, loader, setLoader) => (dispatch, getState) => {
    commonAxios("generate-image", data, dispatch, getState().auth.token)
        .then((res) => {
            //console.log(res.data);
            if (res.status) {
                let arrData = [...messages]
                arrData[index].graphics[graphicIndex].image = res.data
                setMessages(arrData)
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                image: false,
                selectedIndex: -1
            })
        })
        .catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            setLoader({
                ...loader,
                image: false,
                selectedIndex: -1
            })
        })
}

export const onSubmitClickPrompts = (data) => (dispatch, getState) => {
    commonAxios("create-message", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
            }
        })
        .catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
        })
}

export const onFetchHiredStaff = (data, setPageLoader) => (dispatch, getState) => {
    commonAxios("hire-data", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch({ type: "ADD_STAFF_DATA", payload: res.data[0] })
            }
            setPageLoader(true)
        }).catch((err) => {
            console.log(err)
            setPageLoader(true)
        })
}

export const onInternetAccess = (data, setTypingActive, setShowIntTyping, setActiveBlink, messages) => (dispatch, getState) => {
    commonAxios("access-internet", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let obj = {
                    messages: res.data.message.replaceAll(/</g, "&lt;").
                        replaceAll(/>/g, "&gt")
                        .replaceAll("```vba", `<pre style="background-color:black;color:white;width:100%,overflow-x:scroll;border-radius:10px;padding:10px">`).
                        replaceAll("```html", `<pre style="background-color:black;color:white;width:100%,overflow-x:scroll;border-radius:10px;padding:10px">`).
                        replaceAll("```", "</pre>").
                        replaceAll("\n", "<br />")
                        .replaceAll("```html", `</pre>`)
                    ,
                    sources: res.data.source,
                    type: "assistant"
                }
                const pattern = /\((.*?)\)/g;
                let matches = obj.sources.link.match(pattern);
                if (matches) {
                    obj.sources.link = matches[0].replace("(", "").replace(")", "")
                }
                setActiveBlink(true)
                messages.push(obj)
            }
            setTypingActive(false)
            setShowIntTyping(false)
        }).catch((err) => {
            setTypingActive(false)
            setShowIntTyping(false)
            console.log(err)
        })
}

export const onGetAutoResponder = (setAutoResponder) => (dispatch, getState) => {
    commonAxios("autoresponder-integration-list", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setAutoResponder(res.data)
            }
        }).catch((err) => {
            console.log(err)
        })
}

export const onFetchSelectAutoResponderData = (ep, data, setResponderList) => (dispatch, getState) => {
    commonAxios(ep, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setResponderList(res.data)
            }
        }).catch((err) => {
            console.log(err)
        })
}
// export const onSubmitAutoResponderData = (state, setState) => (dispatch, getState) => {
//     commonAxios("save-autoresponder-data", state, dispatch, getState().auth.token)
//         .then((res) => {
//             if (res.status) {
//                 dispatch(setAlert(res.msg, "success"))
//             } else {
//                 dispatch(setAlert(res.msg, "success"))
//             }
//         }).catch((err) => {
//             console.log(err)
//         })
// }

export const onViewMessageForPdf = (data, html2pdf, setLoader) => (dispatch, getState) => {
    // updateInstance({
    //     document: false
    // })

    //Font.register({ family: 'Mukta', src: Mukta });
    commonAxios("hire-data", { hireId: data.hireId }, dispatch, getState().auth.token)
        .then((response) => {
            let staffName = response.data[0].hire_name;
            if (response.status) {
                commonAxios("view-messages", data, dispatch, getState().auth.token)
                    .then((res) => {
                        if (res.status) {
                            let arrData = res.data.reverse()
                            //Sanjay Sir Code 

                            // console.log(arrData);
                            // const styles = StyleSheet.create({
                            //     section: {
                            //         padding: 5,
                            //         flexGrow: 1,
                            //         fontSize: 10,
                            //         border: "1px solid black"
                            //     },
                            //     footer: {
                            //         fontSize: 11,
                            //         position: "absolute",
                            //         bottom: 11,
                            //         right: 12,
                            //     }
                            // })
                            // setPdfDoc(
                            //     <Document>
                            //         <Page style={{ padding: 10, fontFamily: "Mukta" }} >
                            //             <View style={styles.section}>
                            //                 {arrData.map((curElem, index) => {
                            //                     return (
                            //                         <React.Fragment key={index}>
                            //                             {
                            //                                 curElem.type === "assistant" ?
                            //                                     <View style={{ fontWeight: "bold", fontSize: 12, marginBottom: 1 }}>
                            //                                         <Text>{staffName} :-</Text>
                            //                                     </View>
                            //                                     :
                            //                                     <View style={{ fontWeight: "bold", fontSize: 12, marginBottom: 1 }}>
                            //                                         <Text>{getState().auth.user.name} :-</Text>
                            //                                     </View>
                            //                             }
                            //                             <View style={{ marginBottom: "10px", paddingLeft: "5px" }}>
                            //                                 <Text>{curElem.messages}</Text>
                            //                             </View>
                            //                         </React.Fragment>)
                            //                 })}

                            //                 <View style={styles.footer}>
                            //                     <Text>This PDF is generated by AiStaffs.</Text>
                            //                 </View>
                            //             </View>
                            //         </Page>
                            //     </Document >
                            // )

                            //Rehan Code Starts here

                            //-------Rehan PDF CODE--------

                            const formatText = (text) => {
                                const regex = /(.{70})/g;
                                let data = text.replaceAll(/</g, '&lt;').replaceAll(/>/g, '&gt;');
                                data = data.replaceAll(regex, '$1<br>')
                                data = data.replaceAll("&lt;xmp&gt;", "").replaceAll("&lt;/xmp&gt;", "")

                                return data
                            };

                            const element = document.createElement('div');
                            element.style.fontFamily = 'Arial';
                            element.style.border = "2px solid black"
                            element.style.fontSize = '15px';
                            element.style.color = 'black';
                            element.style.padding = "10px"
                            element.style.minHeight = "169vh"
                            element.style.whiteSpace = 'pre-wrap';
                            let htmlArray = [];
                            for (let i = 0; i < arrData.length; i++) {
                                const obj = arrData[i];
                                let message = obj.messages;
                                if (message.includes("```html")) {
                                    message = message.replaceAll("```html", ``)
                                    message = message.replaceAll("```", "")
                                }
                                let allData = [];

                                //console.log(message);
                                const type = obj.type
                                let htmlString = `<p style="color:black;padding-bottom:5px;text-align:justify;"><b>${type === "user" ? getState().auth.user.name : staffName}:-</b></p><pre style="color:black;line-height: 250%">${formatText(message)}</pre><br />`
                                htmlArray.push(htmlString);
                            }

                            htmlArray.push(`<p style="text-align:right;z-index:1;position:absolute;bottom:10px;right:10px"><b style="color:black;">This Pdf is generated by AiStaff</b></p>`);
                            element.innerHTML = htmlArray.join('');
                            // console.log(element);

                            const options = {
                                margin: 10,
                                filename: data.name,
                                image: { type: 'jpeg', quality: 0.98 },
                                html2canvas: { scale: 2 },
                                jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
                            };
                            html2pdf().set(options).from(element).save().then((e) => {
                                setLoader(false)
                            })
                            /////////////////////////////////////////////////////////////////
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
                // setTimeout(() => {
                //     setLoader(false)
                // }, 7000);


            }
        }).catch((err) => {
            setTimeout(() => {
                setLoader(false)
            }, 7000);
            console.log(err)
        })

}

// NO Axios Here

export const unmountStaff = () => (dispatch) => {
    dispatch({ type: "UNMOUNT_CHAT_STAFF" })
}

export const onEditChatName = (data, setIsReadOnly) => (dispatch, getState) => {
    commonAxios("edit-embed-conversation-name", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setIsReadOnly(true)
        })
        .catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            setIsReadOnly(true)
        })
}