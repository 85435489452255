import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import TitleBar from "../TitleBar";
import { BsSearch } from "react-icons/bs";
import { onFetchStaffData, fetchExpertise, fetchHiredExpertise, onGetAutoResponder, fetchUserUpload } from "../../Actions/StaffAction";
import { useDispatch } from "react-redux";
import StaffCard from "./StaffCard";
import Select from 'react-select'
import { useSelector } from "react-redux";

const Staff = () => {
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth)
    const [staff, setStaff] = useState([])
    const [hiredStaff, setHiredStaff] = useState([])

    const [loader, setLoader] = useState(false)
    const [expertiseHire, setExpertiseHire] = useState([])
    const [expertise, setExpertise] = useState([])
    const [media, setMedia] = useState([])
    const [search, setSearch] = useState({
        filter: "",
        expertise: "",
        type: "",
        staffFilter: "",
        staffExpertise: "",
        staffType: ""
    })
    const [autoResponder, setAutoResponder] = useState([])

    const fetchAutoResponder = () => {
        dispatch(onGetAutoResponder(setAutoResponder));
    }

    const fetchStaffData = () => {
        setLoader(true)
        let data = {}
        dispatch(onFetchStaffData(data, setHiredStaff, setStaff, onfetchExpertise, setLoader))
    }

    const onfetchExpertise = () => {
        dispatch(fetchExpertise(setExpertise))
        dispatch(fetchHiredExpertise(setExpertiseHire))
    }

    const handleSearch = (e, type) => {
        if (type) {
            setSearch({
                ...search,
                [type]: e.value
            })
        } else {
            const { name, value } = e.target
            setSearch({
                ...search,
                [name]: value
            })
        }
    }

    const fetchUserUploadMedia = () => {
        let data = {
            type: "images"
        }
        dispatch(fetchUserUpload(data, setMedia))
    }
    useEffect(() => {
        fetchStaffData()
        fetchAutoResponder();
        fetchUserUploadMedia()
    }, [])
    return (
        <>
            <TitleBar title="Staff" />
            <Navbar />
            <section className="siteWrap">
                <section className="staffSec staffPage">
                    <div className="container">
                        <div className="staffSec-wrap">
                            <div className="row pb-3" style={{ marginTop: "20px" }}>
                                <h2 className="infoTitle">Your Hired Staffs</h2>
                                <hr style={{ color: "#c238a4", opacity: 0.5 }} />
                                {hiredStaff.length > 0 ?
                                    <div className="convSec-search mb-4">
                                        <div className="convSec-search-single staffS_selecters">
                                            <label htmlFor="">Expertise</label>
                                            <Select
                                                className="ml-3"
                                                options={expertiseHire}
                                                onChange={(e) => handleSearch(e, "expertise")}
                                                isSearchable={false}
                                            />
                                        </div>

                                        <div className="convSec-search-single staffS_selecters ml-3">
                                            <label htmlFor="">Staffs Type</label>
                                            <Select
                                                className="ml-3"
                                                options={
                                                    [
                                                        { label: "Select All", value: "" },
                                                        { label: "AIStaffs", value: "aiStaff" },
                                                        { label: "youAi Agents", value: "youAiStaff" }
                                                    ]
                                                }
                                                isSearchable={false}
                                                onChange={(e) => handleSearch(e, "type")}
                                            />
                                        </div>
                                        <div className="convSec-search-single ml-3 staffS_selecters">
                                            <label className="mr-3" htmlFor="">Staffs</label>
                                            <div className="search-bar">
                                                <span><BsSearch /></span>
                                                <input type="text" name="filter" placeholder="Search Staffs" className="ml-3"
                                                    value={search.filter}
                                                    onChange={handleSearch} />
                                            </div>
                                        </div>
                                    </div> : ""}
                                {
                                    hiredStaff.length > 0 ?
                                        hiredStaff.filter((item) => {
                                            if (search.filter !== "") {
                                                return item.name.toLowerCase().includes(search.filter.toLowerCase()) || item.hire_name.toLowerCase().includes(search.filter.toLowerCase())
                                            }
                                            else {
                                                return item;
                                            }
                                        }).filter((obj) => {
                                            if (search.expertise !== "") {
                                                return search.expertise === obj.category
                                            } else {
                                                return obj;
                                            }
                                        }).filter((data) => {
                                            if (search.type !== "") {
                                                return search.type === data.type
                                            } else {
                                                return data;
                                            }
                                        }).length > 0 ?
                                            hiredStaff.filter((item) => {
                                                if (search.filter !== "") {
                                                    return item.name.toLowerCase().includes(search.filter.toLowerCase()) || item.hire_name.toLowerCase().includes(search.filter.toLowerCase())
                                                }
                                                else {
                                                    return item;
                                                }
                                            }).filter((obj) => {
                                                if (search.expertise !== "") {
                                                    return search.expertise === obj.category
                                                } else {
                                                    return obj;
                                                }
                                            }).filter((data) => {
                                                if (search.type !== "") {
                                                    return search.type === data.type
                                                } else {
                                                    return data;
                                                }
                                            }).map((curElem, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <StaffCard
                                                            curElem={curElem}
                                                            fetchStaffData={fetchStaffData}
                                                            autoResponder={autoResponder}
                                                            media={media}
                                                        />
                                                    </React.Fragment>
                                                )
                                            }) : <p className="text-center mb-0 text-light">No hired staffs found!</p>
                                        : ""
                                }

                                <div className="text-center mt-1 text-light"  >
                                    {hiredStaff.length === 0 ?
                                        loader ?
                                            <i className="fa fa-spinner fa-spin" style={{ fontSize: "30px", color: "#c238a4", marginTop: "50px" }} />
                                            :
                                            `No hired staffs available!` :
                                        ""}
                                </div>
                            </div>
                            {+auth.user.isClient === 1 ? "" :
                                <div className="row" style={{ marginTop: "20px" }}>
                                    <h2 className="infoTitle">Your Available Staffs</h2>
                                    <hr style={{ color: "#c238a4", opacity: 0.5 }} />
                                    {staff.length > 0 ?
                                        <div className="convSec-search mb-4">
                                            <div className="convSec-search-single staffS_selecters">
                                                <label htmlFor="">Expertise</label>

                                                <Select
                                                    className="ml-3"
                                                    options={expertise}
                                                    isSearchable={false}
                                                    onChange={(e) => handleSearch(e, "staffExpertise")}
                                                />
                                            </div>
                                            <div className="convSec-search-single staffS_selecters ml-3">
                                                <label htmlFor="">Staffs Type</label>
                                                <Select
                                                    className="ml-3"
                                                    options={
                                                        [
                                                            { label: "Select All", value: "" },
                                                            { label: "AiStaffs", value: "aiStaff" },
                                                            { label: "youAi Agents", value: "youAiStaff" }
                                                        ]
                                                    }
                                                    isSearchable={false}
                                                    onChange={(e) => handleSearch(e, "staffType")}
                                                />
                                            </div>
                                            <div className="convSec-search-single ml-3">
                                                <label className="mr-3" htmlFor="">Staffs</label>
                                                <div className="search-bar">
                                                    <span><BsSearch /></span>
                                                    <input type="text" name="staffFilter" placeholder="Search Staffs" className="ml-3"
                                                        value={search.staffFilter}
                                                        onChange={handleSearch} />
                                                </div>
                                            </div>
                                        </div> : ""}
                                    {
                                        staff.length > 0 ?
                                            staff.filter((item) => {
                                                if (search.staffFilter !== "") {
                                                    return item.name.toLowerCase().includes(search.staffFilter.toLowerCase())
                                                }
                                                else {
                                                    return item;
                                                }
                                            }).filter((teamStaff) => {
                                                if (search.staffExpertise !== "") {
                                                    return teamStaff.category === search.staffExpertise
                                                } else {
                                                    return teamStaff
                                                }
                                            }).filter((data) => {
                                                if (search.staffType !== "") {
                                                    return search.staffType === data.type
                                                } else {
                                                    return data;
                                                }
                                            }).length > 0 ?
                                                staff.filter((item) => {
                                                    if (search.staffFilter !== "") {
                                                        return item.name.toLowerCase().includes(search.staffFilter.toLowerCase())
                                                    }
                                                    else {
                                                        return item;
                                                    }
                                                }).filter((teamStaff) => {
                                                    if (search.staffExpertise !== "") {
                                                        return teamStaff.category === search.staffExpertise
                                                    } else {
                                                        return teamStaff
                                                    }
                                                }).filter((data) => {
                                                    if (search.staffType !== "") {
                                                        return search.staffType === data.type
                                                    } else {
                                                        return data;
                                                    }
                                                }).map((curElem, index) => {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <StaffCard
                                                                curElem={curElem}
                                                                index={index}
                                                                fetchStaffData={fetchStaffData}
                                                                autoResponder={autoResponder}
                                                                media={media}
                                                                fetchUserUploadMedia={fetchUserUploadMedia}
                                                            />
                                                        </React.Fragment>
                                                    )
                                                })
                                                : <p className="text-center mb-0 text-light mt-2">No staffs found!</p>
                                            : ""
                                    }
                                    <div className="text-center mt-1 text-light"  >
                                        {staff.length === 0 ?
                                            loader ?
                                                <i className="fa fa-spinner fa-spin" style={{ fontSize: "30px", color: "#c238a4", marginTop: "50px" }} />
                                                :
                                                `No staffs available to hire!` :
                                            ""}
                                    </div>
                                </div>
                            }

                        </div>
                    </div>
                </section>
            </section>

            <Footer />
        </>
    )
}

export default Staff;