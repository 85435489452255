import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import modalCloseIcon from "../../images/modal-close.png";
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { onEnterGoalInfo, onStartGodMode } from '../../Actions/GodModeAction';
import { Link } from 'react-router-dom';

const GodModal = ({ show, handleClose, startInterval, messages, setMessages, forTasksOnly }) => {
    const dispatch = useDispatch()
    const godMode = useSelector(state => state.godMode.data)
    const [loader, setLoader] = useState(false)
    const [isAPI, setIsAPI] = useState(false)

    const handleSubmit = (e) => {
        startInterval()
        e.preventDefault()
        setLoader(true)
        setIsAPI(false)
        dispatch(onStartGodMode(godMode, handleClose, setIsAPI, messages, setMessages, setLoader))
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        dispatch(onEnterGoalInfo(name, value))
    }


    return (
        <Modal className="VideoModal dark" show={show} onHide={handleClose} centered>
            <Modal.Body>
                <div onClick={handleClose} className="vidClose">
                    <img alt="" src={modalCloseIcon} />
                </div>
                <div className="modal-txt">

                    <form onSubmit={handleSubmit}>
                        <div className="form-group form-group-alt">
                            <label className="form-text text-muted" htmlFor="">Enter Your Goal</label>
                            <input
                                className="form-control"
                                name="goal"
                                placeholder='Enter goal here'
                                value={godMode.goal}
                                onChange={(e) => handleChange(e)}
                                type="text"
                                required
                            />
                        </div>
                        <div className="form-group form-group-alt">
                            <label className="form-text text-muted" style={{ zIndex: 1 }}>Enter Max Tasks</label>
                            <input
                                className="form-control"
                                name="maxTask"
                                placeholder='Enter max task here'
                                value={godMode.maxTask}
                                onChange={(e) => handleChange(e)}
                                type="number"
                                required
                            />
                        </div>
                        {/* {isAPI ?
                            <div class="alert alert-danger mt-2 text-center p-2 mb-0" role="alert">
                                Api Key is not available. Click <Link className='custom-anchor' to="/help-and-support">Here</Link> to check how to add API Key!
                            </div> : ""} */}
                        <div className="form-group button-group d-flex justify-content-end">
                            <button className="demoLink" type='submit'> {loader ? <>Starting <i className="fa fa-spinner fa-spin mx-1" /> </> : "Start"}</button>
                        </div>
                    </form>
                </div>
            </Modal.Body>
        </Modal >
    )
}

export default GodModal