import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import ChatBox from './ChatBox'
import AgentGpt from './AgentGpt'
import { usePDF } from '@react-pdf/renderer'

const ChatCreateBox = ({ messages, setMessages, selectedcId, activeBlink, setActiveBlink, typingActive, staff, setTypingActive, isGodMode, conversationData }) => {

    const auth = useSelector(state => state.auth)
    const [showIntTyping, setShowIntTyping] = useState(false)
    return (
        <div className="chat-area-main" id="chat-data-box" style={+staff.staffType === 2 ? { height: "calc(100vh - 165px)" } : {}}>
            {
                messages.length > 0 ?
                    messages.map((curElem, index) => {
                        return (
                            <React.Fragment key={index}>
                                {curElem.messageType === "godMode" ?
                                    <AgentGpt
                                        conversationData={conversationData}
                                        selectedcId={selectedcId}
                                        staff={staff}
                                        godMode={curElem}
                                        messages={messages}
                                        setMessages={setMessages}
                                        index={index}
                                    />
                                    :
                                    <ChatBox
                                        curElem={curElem}
                                        auth={auth}
                                        index={index}
                                        length={messages.length}
                                        activeBlink={activeBlink}
                                        setActiveBlink={setActiveBlink}
                                        typingActive={typingActive}
                                        setTypingActive={setTypingActive}
                                        selectedcId={selectedcId}
                                        staff={staff}
                                        messages={messages}
                                        setMessages={setMessages}
                                        showIntTyping={showIntTyping}
                                        setShowIntTyping={setShowIntTyping}
                                        conversationData={conversationData}

                                    />
                                }
                            </React.Fragment>
                        )
                    }) : ""}


        </div>
    )
}

export default ChatCreateBox