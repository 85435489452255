import React from 'react'
import lessonImg from '../../images/lesson-img.png';
import { useSelector } from 'react-redux';
import { appName } from '../../Global/Global';

function Webinar() {
    const rebrand = useSelector(state => state.rebrand.data)
    return (
        <>
            <section className="lessonLink">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="lessonLink-left"><img alt="" src={lessonImg} /></div>
                        </div>
                        <div className="col-lg-6">
                            <div className="lessonLink-right">
                                <h6><span> {rebrand ? rebrand.name : appName}</span> LESSON #1</h6>
                                <h2>Join Us for our LIVE Webinar.</h2>
                                <p>Join us for a private training that will walk you through how to use {rebrand ? rebrand.name : appName} to help you build an Al powered team of professionals to build products, courses, do marketing, accounting and so much more. This training or upgrade will not be made available afterwards. Sign up and make sure to show up on this live training with all your questions.</p>
                                <a className="demoLink" href="https://reelapps.ewebinar.com/webinar/aistaffs-customers-only-training-11847" target='_blank'><i className="fa fa-play" aria-hidden="true"></i> SignUp for training</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Webinar
