import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import iconRight from "../../images/icon-arrow.svg";
import modalCloseIcon from "../../images/modal-close.png";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { appName } from "../../Global/Global";
import { BiConversation } from "react-icons/bi";
import ProgressBar from 'react-bootstrap/ProgressBar';
import { onFetchProgress, onFetchStaffDataForDashboard } from "../../Actions/StaffAction";
import BannerImg from "../../images/appbanner.jpg"

const DashboardHead = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const rebrandData = useSelector((state) => state.rebrand.data);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showClose, setShowClose] = useState(false)
  const [progress, setProgress] = useState(0)
  const [state, setState] = useState({
    hiredStaff: 0,
    totalStaff: 0,
  })
  const [showPopup, setShowPopup] = useState(false);

  const handelPopupClick = () => {
    document.cookie = `popupClicked${auth.user.id}=true; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
    setShowPopup(false);
  }

  const handelClose2 = () => {
    setShowPopup(false);
  }

  const handleMsgClick = () => {
    document.cookie = `popupClicked${auth.user.id}=true; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
    setShowPopup(false);
  }

  useEffect(() => {
    const popupCookie = document.cookie
    if (popupCookie.includes(`popupClicked${auth.user.id}=true`)) {
      setShowPopup(false)
    } else {
      setShowPopup(true)
    }
  }, []);

  const fetchDashbord = () => {
    if (+auth.user.isClient !== 1) {
      dispatch(onFetchStaffDataForDashboard({}, state, setState))
    }
    dispatch(onFetchProgress(setProgress))
  }
  useEffect(() => {
    fetchDashbord()
  }, [])


  const colorObj = {
    color: "#ffffff",
    position: "absolute",
    top: 30,
    left: "-141px",
    fontSize: 10,
    background: "#515151",
    width: 150,
    textAlign: "center",
    padding: 5,
    borderRadius: 5
  }

  return (
    <>
      <section className="hero-banner">
        <div className="hero-video">
          <video autoPlay loop muted style={{ width: "100%" }}
            src="https://reeelapps-app.s3.us-west-2.amazonaws.com/AISTAFF_BGVIDEO.mp4"
          />
        </div>
        <div className="hero-banner-txt">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-7">
                <div className="hero-banner-left">
                  <h2>WELCOME TO</h2>
                  <h3 className="text-uppercase">
                    {rebrandData ? rebrandData.name : appName}
                  </h3>
                  <p>
                    Revolutionize your business effortlessly:  {rebrandData ? rebrandData.name : appName}  intelligent, cost-saving AI workforce delivers expertise on demand and drives unprecedented success!
                  </p>
                  <button onClick={handleShow} className="demoLink">
                    <i className="fa fa-play" aria-hidden="true"></i> Walkthrough
                  </button>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="hero-banner-right">

                  <>
                    {+auth.user.isClient === 1 ? "" :
                      <Link to="/staff" className="bannerLink">
                        <div className="bannerLink-left">
                          <span>
                            <div className="numberCount">{state.hiredStaff}/{state.totalStaff}</div>
                          </span>
                          Your Staffs
                        </div>
                        <div className="bannerLink-right">
                          <img alt="" src={iconRight} />
                        </div>
                      </Link>}
                    <Link to="/conversations" className="bannerLink">
                      <div className="bannerLink-left">
                        <span>
                          <div className="numberCount"><BiConversation /></div>
                        </span>
                        Your Conversations
                      </div>
                      <div className="bannerLink-right">
                        <img alt="" src={iconRight} />
                      </div>
                    </Link>

                    <Link to="" className="bannerLink notClick">
                      <div className="bannerLink-left">
                        <span>
                          <div className="numberCount">{progress}%</div>
                        </span>
                      </div>
                      <div style={{ width: "100%" }}>
                        <ProgressBar variant="danger" animated now={progress < 2 ? progress + 2 : progress} />
                      </div>
                    </Link>
                  </>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal className="VideoModal" show={showPopup} onHide={handelClose2} centered>
        <Modal.Body>
          <div onClick={() => setShowClose(true)} className="vidClose" >
            <div style={{ position: "relative" }}>
              <img alt="" src={modalCloseIcon} />
              {showClose ?
                <p style={colorObj} onClick={handleMsgClick}>Don't show me again</p> : ""}

            </div>

          </div>
          <div className="modalVid">
            <a href="https://aistaffs.com/fb" target="_blank" rel="nooper noreferrer" onClick={handelPopupClick}>
              <img src={BannerImg} alt="bannerImg" title="AiStaff-Fb-Group" />
            </a>
          </div>

        </Modal.Body>
      </Modal>


      <Modal className="VideoModal" show={show} onHide={handleClose} centered>
        <Modal.Body>
          <div onClick={handleClose} className="vidClose">
            <img alt="" src={modalCloseIcon} />
          </div>
          <div className="modalVidWrap">
            <div className="modalVid">
              <iframe src="https://www.youtube.com/embed/6I5BkyXYYQA" title="AIStaffs Walkthrough" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              {/* <iframe
                src="https://www.youtube.com/watch?v=6I5BkyXYYQA"
                // src="https://player.vimeo.com/video/828569469?h=28c219f82c?loop=false&amp;autoplay=true&amp;muted=false&amp;gesture=media&amp;playsinline=true&amp;byline=false&amp;portrait=false&amp;title=false&amp;speed=true&amp;transparent=false;"
                allowFullScreen="true"
                allow="autoplay,fullscreen,picture-in-picture"
                title="Player for FastPaid Intro"
                data-ready="true"
                tabIndex="-1"
              ></iframe> */}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default DashboardHead;
