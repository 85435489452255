import React, { useState } from 'react'
import { BsUpload } from 'react-icons/bs'
import { useDispatch } from 'react-redux'
import Swal from 'sweetalert2'
import { uploadBotImage } from '../../Actions/StaffAction'
import defaultImg from '../../images/default_image.jpg'
import { setAlert } from '../../Actions/AlertAction'

const GeneralTab = ({ state, setState, handleChange, setNext }) => {
    const dispatch = useDispatch()
    const [loader, setLoader] = useState({
        uploadLoader: false
    })

    const onUploadFile = (e) => {
        if (e.target.files[0].type === 'image/png' || e.target.files[0].type === 'image/jpg' || e.target.files[0].type === 'image/jpeg') {
            if (e.target.files[0].size < 5000000) {
                const formData = new FormData()
                formData.append('upload_type', "images");
                formData.append('file', e.target.files[0]);
                setLoader({
                    ...loader,
                    uploadLoader: true
                })
                dispatch(uploadBotImage(formData, state, setState, loader, setLoader))
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Max allowed size is 5MB!',
                    confirmButtonColor: "#d03e3e"
                })
            }
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You have selected invalid file type!',
                confirmButtonColor: "#d03e3e"
            })
        }
    }

    const handleNext = () => {
        if (state.bot_name !== "" &&
            state.role !== "" &&
            state.description !== ""
        ) {
            setNext(true)
        }
        else {
            dispatch(setAlert("Please provide the agent name, role, description to continue!", "danger"))
        }
    }

    return (
        <div>
            <div className="form-group form-group-alt" style={{ marginTop: "-20px" }}>
                <label className="form-text text-muted" htmlFor="">youAi Name</label>
                <input
                    className="form-control"
                    type="text"
                    placeholder="Name"
                    name='bot_name'
                    value={state.bot_name}
                    onChange={handleChange}
                    maxLength={100}
                />
            </div>
            <div className="gender_wrapper">
                <div className="col-3">
                    <h5 style={{ fontSize: "15px", fontWeight: "400", paddingLeft: "13px" }}>Gender</h5>

                </div>
                <div className="col-9 d-flex justify-content-evenly">
                    <div className="form-check">
                        <input
                            className="form-check-input cursor-pointer"
                            type="radio"
                            name="gender"
                            id='male'
                            value={"male"}
                            checked={state.gender === "male" ? true : false}
                            onChange={handleChange}

                        />
                        <label className="form-check-label" htmlFor="male">
                            Male
                        </label>
                    </div>
                    <div className="form-check">
                        <input
                            className="form-check-input cursor-pointer"
                            type="radio"
                            name="gender"
                            id='female'
                            value={"female"}
                            checked={state.gender === "female" ? true : false}
                            onChange={handleChange}
                        />
                        <label className="form-check-label" htmlFor="female">
                            Female
                        </label>
                    </div>
                </div>

            </div>
            <div className="form-group form-group-alt mb-3">
                <label className="form-text text-muted" htmlFor="">youAi Agent Role</label>
                <input
                    className="form-control"
                    type="text"
                    placeholder="3-4 words to describe what the employees role is eg: Customer Support, Analyst, Accountant etc"
                    name='role'
                    value={state.role}
                    onChange={handleChange}
                    maxLength={100}
                />
            </div>
            As a : {state.role}
            <div className="form-group form-group-alt mt-0">
                <label className="form-text text-muted" htmlFor="" style={{ width: "100%", paddingBottom: "8px", borderTopLeftRadius: "8px", borderTopRightRadius: "8px", background: "#000", borderTop: "1px solid #7900b3", borderLeft: "1px solid #7900b3", borderRight: "1px solid #7900b3" }}>Role Description</label>
                <textarea
                    className="form-control"
                    placeholder="In one line describe what the role means&#13;&#10;Eg: As a customer support agent you will answer questions about a product."
                    name='description'
                    value={state.description}
                    onChange={handleChange}
                />
            </div>
            <div className="upload-box" style={{ padding: 0, height: "auto", borderStyle: "solid" }}>
                <div className="upload-box-in" style={{ width: "100%", height: "auto" }}>

                    <div className="row align-items-center">

                        <div className="col-lg-8">
                            <div className="upload_selected_image" style={{ height: '144px;' }}>
                                <BsUpload />
                                <p>Upload youAi Image Here (JPG, PNG, JPEG upto 5MB is allowed)</p>
                                <input
                                    type="file"
                                    onChange={(e) => onUploadFile(e)}
                                />
                            </div>

                        </div>
                        <div className="col-lg-4">
                            <div className="upload_img_wrapper"
                                style={{
                                    height: "160px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    padding: 5
                                }}>
                                {loader.uploadLoader ?
                                    <i className="fa fa-spinner fa-spin" style={{ fontSize: "30px", color: "#c238a4" }} />
                                    :
                                    <img src={state.image !== "" ? state.image : defaultImg} style={{ width: "auto", height: "100%", padding: "10px", borderRadius: '28px', background: "#c7c7c7" }} />}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="bot_btn text-end"><button className="demoLink btn text-light justify-content-center" onClick={() => handleNext()}>Next</button></div>
        </div >
    )
}

export default GeneralTab