import React, { useEffect, useState } from "react";
import ConnectReelapps from "./ConnectReelapps";
import { useDispatch, useSelector } from "react-redux";

import mailChimp_logo from "../../../images/responder/responder-1.png"
import getResponse_logo from "../../../images/responder/responder-3.png"
import awebar_logo from '../../../images/responder/responder-2.png'
import Mailvio_logo from '../../../images/responder/responder-16.png'
import { onFetchIntegrations } from "../../../Actions/SocialAction";

const Integration = () => {
    const dispatch = useDispatch()
    const intData = useSelector(state => state.social.integration)

    const fetchIntegration = () => {
        dispatch(onFetchIntegrations())
    }
    useEffect(() => {
        fetchIntegration()
    }, [])


    return (
        <>
            <div className="tabepaneIn">
                <h2 className="tabTitle">Integration</h2>
                <div className="tabepaneIn-main">

                    <div className="integration_wrapper">
                        <div className="container p-0">
                            <div className="row">
                                <section className="connection">
                                    <div className="container p-0">
                                        <div className="tabepaneIn-cont">
                                            <h3 style={{ fontSize: "18px" }}>Autoresponders</h3>
                                            <ConnectReelapps
                                                icon={getResponse_logo}
                                                name="GetResponse"
                                                type="gr"
                                                data={intData.getResponse}
                                                fetchIntegration={fetchIntegration}
                                            />
                                            <ConnectReelapps
                                                icon={awebar_logo}
                                                name="Aweber"
                                                type="aw"
                                                data={intData.aweber}
                                                fetchIntegration={fetchIntegration}
                                            />

                                            <ConnectReelapps
                                                icon={mailChimp_logo}
                                                name="MailChimp"
                                                type="mc"
                                                data={intData.mailchimp}
                                                fetchIntegration={fetchIntegration}
                                            />
                                            <ConnectReelapps
                                                icon={Mailvio_logo}
                                                name="Mailvio"
                                                type="mv"
                                                data={intData.mailvio}
                                                fetchIntegration={fetchIntegration}
                                            />
                                        </div>
                                    </div>
                                </section>

                            </div>
                        </div>

                    </div>
                </div>
            </div >
        </>
    )
}

export default Integration;