import React, { useEffect, useState } from 'react'
import { onfetchFolder } from '../../Actions/TrainingAction'
import { useDispatch } from 'react-redux'
import SelectBoxMultiple from './SelectBoxMultiple'
import { onSubmitBot } from '../../Actions/SettingAction'
import { setAlert } from '../../Actions/AlertAction'
import Select from 'react-select'
import { onFetchOptions } from '../../Actions/StaffAction'


const KnowledgeTab = ({ curElem, state, staffId, setState, handleClose, fetchBots }) => {
    const dispatch = useDispatch()
    const [loader, setLoader] = useState(false)
    const [folders, setFolders] = useState([])
    const [writingStyle, setWritingStyle] = useState([])
    const [tone, setTone] = useState([])

    const fetchFolders = () => {
        dispatch(onfetchFolder(setFolders))
    }

    const [selectAll, setSelectAll] = useState("")

    const handleSelectAll = (status) => {
        setSelectAll(status)
    }

    const handleSubmit = () => {
        let obj = { ...state }
        if (staffId) {
            obj.staffId = staffId
        }
        if (state.bot_name !== "" &&
            state.role !== "" &&
            state.description !== "" &&
            state.knowledge.length > 0) {
            setLoader(true)
            let ep = "create-bot"
            if (curElem) {
                ep = state.isfired ? "edit-bot" : "update-hire-staff"
                if (!+state.isfired) {
                    obj.name = obj.bot_name
                    obj.category = obj.role
                    delete obj.bot_name
                } else {
                    obj.botId = state.id
                    delete obj.id
                }
                // obj.botId = botId
            }
            dispatch(onSubmitBot(ep, obj, handleClose, fetchBots, setLoader))
        }
        else {
            dispatch(setAlert("Please provide the agent name, role , description & knowledge to create it!", "danger"))
        }
    }

    const handleChange = (e, name) => {
        setState({
            ...state,
            [name]: e.value
        })
    }


    const fetchWritingStyle = () => {
        let ep = "get-writing-style"
        let data = {}
        dispatch(onFetchOptions(ep, data, setWritingStyle, "select"))
    }
    const fetchTone = () => {
        let ep = "get-tone"
        let data = {}
        dispatch(onFetchOptions(ep, data, setTone, "select"))
    }



    useEffect(() => {
        fetchWritingStyle()
        fetchTone()
        fetchFolders()
    }, [])

    return (
        <div>

            <div className="Knowledge_Tab mt-3">
                <div className="col-6 p-0">
                    <div className="d-flex align-items-center">
                        <label htmlFor="">Tone</label>
                        <Select
                            className="ml-3"
                            options={tone}
                            value={tone.find(({ value }) => value === state.tone)}
                            isSearchable={false}
                            onChange={(e) => handleChange(e, "tone")}
                        />
                    </div>
                </div>
                <div className="col-6 p-0">
                    <div className="d-flex  align-items-center">
                        <label htmlFor="">Response Style</label>
                        <Select
                            className="ml-3"
                            options={writingStyle}
                            value={writingStyle.find(({ value }) => value === state.writingStyle)}
                            isSearchable={false}
                            onChange={(e) => handleChange(e, "writingStyle")}
                        />
                    </div>
                </div>

            </div>
            <div className="form-group form-group-alt pb-5 bots_second mb-3" >
                <label className=" form-text d-flex justify-content-center align-items-center gap-2 cursor-pointer p-0" >
                    <input
                        className="rounded  w-5 h-5 cursor-pointer"
                        type="checkbox"
                        checked={selectAll}
                        onChange={(e) => handleSelectAll(e.target.checked)}
                    />
                    <span style={{ fontSize: "20px" }}>All folders</span>
                </label>
            </div>
            <hr className="p-0" style={{ marginTop: "-6px" }} />
            <div className="multi_select_folder">
                {folders.length > 0 ?
                    folders.map((curElem, index) => {
                        return (
                            <React.Fragment key={index}>
                                <SelectBoxMultiple
                                    curElem={curElem}
                                    state={state}
                                    setState={setState}
                                    selectAll={selectAll}
                                    folders={folders}
                                    setSelectAll={setSelectAll}
                                />
                            </React.Fragment>
                        )
                    })
                    : ""}
            </div>
            <div className="bot_btn text-end">
                <button className="demoLink btn text-light justify-content-center" style={{ zIndex: "1" }} onClick={handleSubmit}> {loader ? <>{curElem ? "Updating" : "Creating"} <i className="fa fa-spinner fa-spin mx-1" /> </> : curElem ? "Update" : "Create"} </button>
            </div>
        </div>
    )
}

export default KnowledgeTab