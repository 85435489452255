import React, { useEffect, useState } from "react";
import ManageClient from "./ManageClient";
import ManageTeam from "./ManageTeam";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { addAccData, fetchAccData, onFetchConversation } from "../../Actions/AccountManageAction";

const AddAccount = () => {

    const dispatch = useDispatch();

    const [teamData, setTeamData] = useState([]);
    const [clientData, setClientData] = useState([]);
    const [conversationsOptions, setConversationsOptions] = useState([]);
    const [loader, setLoader] = useState(false);
    const [passwordMsg, setPasswordMsg] = useState({
        msg: "",
        validation: false
    })
    const [userDetails, setUserDetails] = useState({
        type: 'team',
        email: '',
        name: '',
        password: '',
        conversationsId: ""
    });

    const onInputChange = (e) => {
        setPasswordMsg({ ...passwordMsg, msg: "" })
        setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
    }

    const onSelectConversation = (e) => {
        setPasswordMsg({ ...passwordMsg, msg: "" })
        setUserDetails({ ...userDetails, conversationsId: e });
    }

    const fetchClientTeam = () => {
        dispatch(fetchAccData(setTeamData, setClientData))
    }

    const addClientTeam = () => {
        if (userDetails.type === 'team') {
            delete userDetails.conversationsId
        }
        dispatch(addAccData(userDetails, setUserDetails, fetchClientTeam, setLoader, setPasswordMsg, passwordMsg))
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        const pattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9\s]).{6,}$/;
        if (userDetails.password) {
            if (pattern.test(userDetails.password)) {
                setPasswordMsg({ ...passwordMsg, msg: "Password is valid!", validation: true })
                setLoader(true);
                addClientTeam();
            } else {
                setPasswordMsg({ ...passwordMsg, msg: "Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 digit, 1 special character and be at least 8 characters long.", validation: true })
            }
        } else {
            setLoader(true);
            addClientTeam();
        }
        // setLoader(true);
        // addClientTeam();
    }

    const fetchConversations = () => {
        let data = { offset: 0 }
        dispatch(onFetchConversation(data, setConversationsOptions))
    }

    useEffect(() => {
        fetchClientTeam();
        fetchConversations()
    }, []);

    return (
        <>

            <div className="profileBlock-wrap">
                <h2 className="infoTitle">Create Account</h2>
                <div className="profileBlock mt-0">

                    <form className="" method="post" onSubmit={(e) => onFormSubmit(e)}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group form-group-alt mt-0">
                                    <label className="form-text text-muted">Account Type</label>
                                    <select
                                        className="form-control"
                                        name="type"
                                        onChange={(e) => onInputChange(e)}
                                        style={{ height: '80px' }}
                                        value={userDetails.type}
                                    >
                                        <option style={{ backgroundColor: "#000", color: "#fff" }} value="team">Team Member</option>
                                        <option style={{ backgroundColor: "#000", color: "#fff" }} value="client">Client Account</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group form-group-alt mt-0">
                                    <label className="form-text text-muted ">Account Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Account Name"
                                        required
                                        name="name"
                                        value={userDetails.name}
                                        onChange={(e) => onInputChange(e)}
                                        onKeyDown={(e) => {
                                            if (e.key === ' ' || e.code === 32) {
                                                const inputValue = e.target.value;
                                                if (inputValue.length === 0 || inputValue[0] === ' ') {
                                                    e.preventDefault();
                                                }
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group form-group-alt">
                                    <label className="form-text text-muted">Account Email</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        placeholder="Enter Email"
                                        required
                                        name="email"
                                        value={userDetails.email}
                                        onChange={(e) => onInputChange(e)}
                                    />
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group form-group-alt">
                                    <label className="form-text text-muted">Account Password</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        placeholder="********"
                                        required
                                        name="password"
                                        value={userDetails.password}
                                        onChange={(e) => onInputChange(e)}
                                        minLength={8}
                                        maxLength={32}
                                    // onKeyDown={(e) => {
                                    //     if (e.code === "Space") {
                                    //         e.preventDefault()
                                    //     }
                                    // }}
                                    />
                                    <p style={{ fontSize: 13, margin: "5px" }}>{passwordMsg.validation ? passwordMsg.msg : ""}</p>
                                </div>
                            </div>
                        </div>

                        {userDetails.type === "client" ?

                            <div className="row">
                                <div className="col-md-12 mt-3">
                                    <div className="ac-conv-class staffS_selecters d-block" style={{ width: "100%" }}>
                                        <label htmlFor="" style={{ fontWeight: "normal" }}>Select Conversations</label>
                                        <Select
                                            name="conversationsId"
                                            closeMenuOnSelect={true}
                                            value={userDetails.conversationsId}
                                            isMulti
                                            isSearchable={true}
                                            options={conversationsOptions}
                                            // required={userDetails.type === "client"}
                                            onChange={(e) => onSelectConversation(e)}
                                        />
                                    </div>
                                </div>
                            </div>
                            : ""
                        }

                        <div className="form-group">
                            <button type="submit" className="fbtn btn-block demoLink" disabled={loader}>
                                {loader ? <>Creating <i className="fa fa-spinner fa-spin mr-2" /> </> : 'Create'}
                            </button>
                        </div>

                    </form>

                </div>


                <ManageClient users={clientData} fetchClientTeam={fetchClientTeam} type="Client" conversationsOptions={conversationsOptions} />
                <ManageTeam users={teamData} fetchClientTeam={fetchClientTeam} type="Team Member" />
            </div>

        </>
    )
}

export default AddAccount;