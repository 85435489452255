import './App.css';
import 'react-calendar/dist/Calendar.css';
import { BrowserRouter, Route, Routes } from "react-router-dom"
import Login from './Components/Auth/Login';
import ForgetPassword from './Components/Auth/ForgetPassword';
import ResetPassword from './Components/Auth/ResetPassword';
import MasterLogin from './Components/Auth/MasterLogin';
import { PrivateRoute } from './Components/PrivateRoute';
import { HelmetProvider } from 'react-helmet-async';
import Dashboard from './Components/Dashboard/Dashboard';
import Profile from './Components/User/Profile';
import Privacy from './Components/User/Privacy';
import AccountManagement from './Components/User/AccountManagement';
import Upgrades from './Components/User/Upgrades';
import HelpSupport from './Components/Support/HelpSupport';
import Training from './Components/Support/Training';
import TrainingArticles from './Components/Support/TrainingArticles';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadUser } from './Actions/AuthAction';
import { removeAlert } from './Actions/AlertAction';
import Alert from './Components/Alert';
import Staff from './Components/Staff/Staff';
import Conversations from './Components/Conversations/Conversations';
import Chat from './Components/Chat/Chat';
import TrainingPage from './Components/Training/TrainingPage';
import SettingPage from './Components/Settings/SettingPage';
import ConversationMain from './Components/Conversations/ConversationMain';
import AllEmbedTable from './Components/Staff/AllEmbedTable';
function App() {

  const dispatch = useDispatch()
  const alert = useSelector(state => state.alert)
  const [widget, setWidget] = useState(false)

  useEffect(() => {
    dispatch(loadUser())
  }, [])

  useEffect(() => {
    if (alert !== undefined) {
      if (alert.message !== "") {
        setTimeout(() => {
          dispatch(removeAlert())
        }, 4000);
      }
    }
  }, [alert, dispatch])

  // removing register widget from editor
  useEffect(() => {
    if (widget[0]) {
      widget[0].hidden = true
    } else {
      setWidget(false)
      setTimeout(() => {
        setWidget(document.getElementsByClassName("ewebinar__Widget"))
      }, 100);
    }
  }, [widget])

  return (
    <div className="App">
      <Alert />
      <HelmetProvider>
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<Login />} />
            <Route exact path='/login' element={<Login />} />
            <Route exact path='/forget-password' element={<ForgetPassword />} />
            <Route exact path='/reset-password' element={<ResetPassword />} />
            <Route exact path='/master-login' element={<MasterLogin />} />

            <Route exact path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
            <Route exact path="/profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
            <Route exact path="/privacy" element={<PrivateRoute><Privacy /></PrivateRoute>} />
            <Route exact path="/account-management" element={<PrivateRoute><AccountManagement /></PrivateRoute>} />
            <Route exact path="/upgrades" element={<PrivateRoute><Upgrades /></PrivateRoute>} />
            <Route exact path="/help-and-support" element={<PrivateRoute><HelpSupport /></PrivateRoute>} />
            <Route exact path="/training" element={<PrivateRoute><Training /></PrivateRoute>} />
            <Route exact path="/support-article" element={<PrivateRoute><TrainingArticles /></PrivateRoute>} />
            <Route exact path="/staff" element={<PrivateRoute><Staff /></PrivateRoute>} />
            <Route exact path="/all-embed" element={<PrivateRoute><AllEmbedTable /></PrivateRoute>} />

            <Route exact path="/conversations" element={<PrivateRoute><ConversationMain /></PrivateRoute>} >
              <Route index element={<Conversations />} />
              <Route path="chat" element={<PrivateRoute><Chat /></PrivateRoute>} />
            </Route>
            <Route exact path="/training-page" element={<PrivateRoute><TrainingPage /></PrivateRoute>} />
            <Route exact path="/settings" element={<PrivateRoute><SettingPage /></PrivateRoute>} />

            <Route path="*" element={<Login />} />
          </Routes>
        </BrowserRouter>
      </HelmetProvider>
    </div>
  );
}

export default App;
