import { commonAxios } from "../Global/CommonAxios"
import { setAlert } from "./AlertAction"

export const onStartGodMode = (data, handleClose, setIsAPI, messages, setMessages, setLoader) => (dispatch, getState) => {
    messages.push({
        results: [],
        messageType: "godMode",
        tasks: []
    })
    let newMessages = [...messages]
    let obj1 = [{
        message: data.goal,
    },
    {
        message: "Thinking...",
        type: "thinking"
    }
    ]
    newMessages[newMessages.length - 1].results = obj1
    setMessages(newMessages)
    dispatch({ type: "ON_SET_GOAL_MESSAGE" })
    handleClose()
    setLoader(false)
    commonAxios("god-mode-start", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let dataNew = res.data.tasks.map((curElem) => {
                    return {
                        ...curElem,
                        status: 0
                    }
                })
                newMessages[newMessages.length - 1].tasks = dataNew
                let arr = dataNew.map((curElem) => {
                    return {
                        message: curElem.task,
                        enable: false,
                        id: curElem.id,
                        status: curElem.status,
                        type: "addedTask"
                    }
                })
                newMessages[newMessages.length - 1].results = newMessages[newMessages.length - 1].results.concat(arr)
                setMessages(newMessages)
                dispatch({ type: "ON_SET_GOAL_RESPONSE", payload: res.data.goalId })
                let dataVal = {
                    ...data,
                    task: res.data.tasks[0].task,
                    taskId: res.data.tasks[0].id,
                    gId: res.data.goalId
                }
                if (getState().godMode.isGodModeActive) {
                    dispatch(onExecuteGodMode(dataVal, messages, setMessages))
                }

            } else {
                if (res.msg === "Api Key is not available") {
                    setIsAPI(true)
                } else {
                    dispatch(setAlert(res.msg, "danger"))
                }
            }

        }).catch((err) => {
            setLoader(false)
            console.log(err)
        })
}

export const onExecuteGodMode = (data, messages, setMessages) => (dispatch, getState) => {
    let newMessages = [...messages]
    setTimeout(() => {
        const taskIndex = newMessages[newMessages.length - 1].tasks.findIndex(({ status }) => status === 0)
        newMessages[newMessages.length - 1].tasks[taskIndex].status = 1
        newMessages[newMessages.length - 1].results.push({
            message: "Thinking...",
            type: "thinking"
        })
        setMessages(newMessages)
        commonAxios("god-mode-process", data, dispatch, getState().auth.token)
            .then((res) => {
                if (res.status) {
                    if (getState().godMode.isGodModeActive) {
                        let newRes = {
                            resVal: res.data,
                            taskId: data.taskId,
                            task: data.task,
                        }
                        dispatch({ type: "ON_EXECUTE_MODE", payload: newRes })
                        let doneTasks = 0
                        const executedTaskIndex = newMessages[newMessages.length - 1].tasks.findIndex(({ status }) => status === 1)
                        newMessages[newMessages.length - 1].tasks[executedTaskIndex].status = 2
                        newMessages[newMessages.length - 1].results.push({
                            message: res.data.response,
                            id: res.data.id,
                            type: "response",
                            task: data.task
                        })
                        setMessages(newMessages)
                        newMessages[newMessages.length - 1].tasks.forEach((curElem) => {
                            if (curElem.status === 2) {
                                doneTasks++
                            }
                        })
                        if (+getState().godMode.data.maxTask === doneTasks) {
                            newMessages[newMessages.length - 1].results.push({
                                message: "Task limit reached",
                                type: "limitReach"
                            })
                            setTimeout(() => {
                                setMessages(newMessages)
                            }, 1000)
                        } else {
                            dispatch(onCreateGodMode(messages, setMessages))
                        }
                    }
                    //  else {
                    //     if (newMessages[newMessages.length - 1].results[newMessages[newMessages.length - 1].results.length - 1].type === "thinking") {
                    //         newMessages[newMessages.length - 1].results.splice(newMessages[newMessages.length - 1].results.length - 1, 1)
                    //         setMessages(newMessages)
                    //     }
                    // }
                } else {
                    dispatch(setAlert(res.msg, "danger"))
                }
            }).catch((err) => {
                console.log(err)
            })
    }, 1000)
}


export const onCreateGodMode = (messages, setMessages) => (dispatch, getState) => {
    let newMessages = [...messages]
    let data = getState().godMode.data
    newMessages[newMessages.length - 1].results.push({
        message: "Thinking...",
        type: "thinking"
    })
    setMessages(newMessages)

    commonAxios("god-mode-create", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                if (getState().godMode.isGodModeActive) {
                    let tasksData = res.data.map((curElem) => {
                        return {
                            ...curElem,
                            status: 0
                        }
                    })
                    newMessages[newMessages.length - 1].tasks = newMessages[newMessages.length - 1].tasks.concat(tasksData)
                    let arr = tasksData.map((curElem) => {
                        return {
                            message: curElem.task,
                            enable: false,
                            id: curElem.id,
                            status: curElem.status,
                            type: "addedTask"
                        }
                    })
                    newMessages[newMessages.length - 1].results = newMessages[newMessages.length - 1].results.concat(arr)
                    setMessages(newMessages)
                    let newData = { ...data }
                    delete newData.completedTasks
                    delete newData.lastTask
                    delete newData.pendingTasks
                    const taskToExec = newMessages[newMessages.length - 1].tasks.find(({ status }) => status === 0)
                    newData.task = taskToExec.task
                    newData.taskId = taskToExec.id
                    setTimeout(() => {
                        dispatch(onExecuteGodMode(newData, messages, setMessages))
                    }, 1000)
                }
                // else {
                //     if (newMessages[newMessages.length - 1].results[newMessages[newMessages.length - 1].results.length - 1].type === "thinking") {
                //         newMessages[newMessages.length - 1].results.splice(newMessages[newMessages.length - 1].results.length - 1, 1)
                //         setMessages(newMessages)
                //     }
                // }
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
        }).catch((err) => {
            console.log(err)
        })
}

export const onCheckAPI = (setShow, selectedcId, staff, Swal, navigate) => (dispatch, getState) => {
    commonAxios("check-user-key", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let data = { selectedcId, staff }
                dispatch({ type: "ON_SET_INITIALS", payload: data })
                setShow(true)
            } else {
                Swal.fire({
                    title: 'API key is not available.',
                    html: "<p>Please go to settings page to add the API key!</p>",
                    icon: 'error',
                    showCancelButton: true,
                    confirmButtonColor: '#b32f3d',
                    cancelButtonColor: '#2a2c3a',
                    confirmButtonText: 'Ok'
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate('/settings')
                    }
                })
            }
        }).catch((err) => {
            console.log(err)
        })
}


// No Axios Here

export const onEnterGoalInfo = (name, value) => (dispatch) => {
    let data = { name, value }
    dispatch({ type: "ON_SET_GOAL", payload: data })
}

export const onStopGodMode = () => (dispatch) => {
    dispatch({ type: "ON_STOP_GOD_MODE" })
}
export const onResetTaskLimit = (limit) => (dispatch) => {
    dispatch({ type: "RESET_TASK_LIMIT", payload: limit })
}
