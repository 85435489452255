import { useEffect } from 'react'
import Swal from 'sweetalert2'

const SweetAlertTwo = (props) => {
    let swal = Swal
    const activeAlert = () => {
        swal.fire({
            title: props.title,
            text: props.text,
            icon: props.icon,
            showCancelButton: props.showCancelButton,
            confirmButtonColor: props.confirmButtonColor,
            cancelButtonColor: props.cancelButtonColor,
            confirmButtonText: props.confirmButtonText
        }).then((result) => {
            if (result.isConfirmed) {
                swal.fire({
                    title: props.confirmTitle,
                    text: props.confirmText,
                    icon: props.confirmIcon,
                    showConfirmButton: false
                })
                props.handlePerform(swal)
            } else {
                props.onCancel()
            }
        })
    }

    useEffect(() => {
        if (props.show) {
            activeAlert()
        }
    }, [props.show])

    useEffect(() => {
        return () => {
            swal.close()
        }
    }, [])
}

export default SweetAlertTwo