import React, { useEffect, useState } from 'react'
import Navbar from '../Navbar'
import Footer from '../Footer'
import { onFetchAllEmbed, onUpdateEmbed } from '../../Actions/StaffAction'
import { useDispatch } from 'react-redux'
import { ImEmbed2 } from 'react-icons/im'
import { FaUserCircle, FaTrash } from 'react-icons/fa'
import { Modal } from 'react-bootstrap'
import modalCloseIcon from "../../images/modal-close.png";
import SweetAlertTwo from '../SweetAlertTwo'


const AllEmbedTable = () => {
    const dispatch = useDispatch()
    const [loader, setLoader] = useState(false)
    const [data, setData] = useState([])
    const [info, setInfo] = useState({
        data: false,
        enable: false
    })
    const [embed, setEmbed] = useState({
        header: "",
        headerStatus: false,
        checkout: "",
        checkoutStatus: false,
    })

    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)

    const handleShow = (curElem) => {
        setEmbed({
            ...embed,
            header: `<script type="text/Javascript" id="ai_widget" src="https://backend.aistaffs.com/front-end/chat-box/embed.js?type=${curElem.type}&staffId=${curElem.staffId}&uId=${curElem.userId}&arId=${curElem.ar ? curElem.ar : 0}&arListId=${curElem.arList ? curElem.arList : 0}&icn=${curElem.image}&webUrl=${curElem.url}&embId=${curElem.id}"></script> `,
            checkout: `<div id = "aistaff_chat_box"></div > `,
        });
        setShow(true)
    }


    const fetchAllEmbed = () => {
        setLoader(true)
        dispatch(onFetchAllEmbed(setData, setLoader))
    }

    const updateEmbed = (curElem, type) => {
        let data = {
            id: curElem.id,
            isDeleted: type === "delete" ? 1 : 0,
            status: type === "delete" ? curElem.status : 0
        }
        setInfo({
            ...info,
            enable: true,
            data: data
        })

    }

    const handleCancle = () => {
        setInfo({
            ...info,
            enable: false,
            data: false
        })
    }

    const onPerformRemove = (Swal) => {
        dispatch(onUpdateEmbed(info.data, fetchAllEmbed, Swal))
        handleCancle()
    }

    const copyFunction = async (type) => {
        var copyText;
        if (type === "header") {
            copyText = document.getElementById("headerCode");
            setEmbed({
                ...embed,
                headerStatus: true,
                checkoutStatus: false,
            });
        } else {
            copyText = document.getElementById("checkoutCode");
            setEmbed({
                ...embed,
                headerStatus: false,
                checkoutStatus: true,
            });
        }
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        navigator.clipboard.writeText(copyText.value);
    }


    useEffect(() => {
        if (show) {
            return () => {
                setEmbed({
                    ...embed,
                    header: "",
                    headerStatus: false,
                    checkout: "",
                    checkoutStatus: false,
                })
            }
        }
    }, [show])


    useEffect(() => {
        fetchAllEmbed()
    }, [])
    return (
        <>
            <Navbar />
            <section className="siteWrap m-3">
                <div className="tabepaneIn">
                    <h2 className="tabTitle mt-2">Your All Embeds</h2>
                    <div className="tabepaneIn-main">
                        <div className="tabepaneIn-cont">
                            <h3>Embeds</h3>
                            <div className="offline doc-table">
                                <table className="table">
                                    <tr>
                                        <th>Image</th>
                                        <th>Name</th>
                                        <th>Staff</th>
                                        <th>Website</th>
                                        <th>Status</th>
                                        <th>Added</th>
                                        <th className='text-center'>Action</th>
                                    </tr>
                                    {data.length > 0 ?
                                        data.map((curElem, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>
                                                        <div className="proThumb">
                                                            {curElem.image ? <img src={curElem.image} alt='' /> : <FaUserCircle />}
                                                        </div>
                                                    </td>
                                                    <td>{curElem.name}</td>
                                                    <td>{curElem.staffName}</td>
                                                    <td>{curElem.url}</td>
                                                    <td>{+curElem.status ? "Active" : "Deactive"}</td>
                                                    <td>
                                                        {curElem.created}
                                                    </td>
                                                    <td className='text-center '>

                                                        <ImEmbed2
                                                            className='button-hover-effect me-1'
                                                            size={25}
                                                            cursor={"pointer"}
                                                            title='Generate Embed'
                                                            onClick={() => handleShow(curElem)}
                                                        />

                                                        <FaTrash
                                                            className='button-hover-effect '
                                                            size={20}
                                                            cursor={"pointer"}
                                                            title='Delete Embed'
                                                            onClick={() => updateEmbed(curElem, "disable", index)}
                                                        />
                                                    </td>
                                                </tr>
                                            )
                                        })

                                        : ""
                                    }
                                </table>

                            </div>
                            <div className="text-center my-3 ">
                                {data.length === 0 ?
                                    loader ?
                                        <i className="fa fa-spinner fa-spin" style={{ color: "#9c1d6d", fontSize: 27 }} />
                                        : "No embed generated yet!"

                                    : ""}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />

            <Modal className="VideoModal dark" show={show} onHide={handleClose} centered>
                <Modal.Body>
                    <div onClick={handleClose} className="vidClose">
                        <img alt="" src={modalCloseIcon} />
                    </div>
                    <div className="modal-txt">
                        <div className="modal-txt-top">
                            <h4>Copy Paste Embed Code</h4>
                        </div>
                        <div>
                            <div className="form-group form-group-alt embed_content">
                                <label className="form-text text-muted mb-2" htmlFor="">Insert this code in the header section between &lt;head&gt;&lt;/head&gt; tags in your page:</label>
                                <textarea
                                    className="form-control"
                                    name="header"
                                    id="headerCode"
                                    value={embed.header}
                                    readOnly={true}
                                    type="text"
                                />
                            </div>
                            <div className="form-group button-group d-flex justify-content-end">
                                <button className="demoLink" onClick={() => copyFunction("header")}>
                                    {embed.headerStatus ? "Copied" : "Copy"}
                                </button>
                            </div>
                        </div>

                        <div>
                            <div className="form-group form-group-alt embed_content">
                                <label className="form-text text-muted" htmlFor="">Put this code anywhere in your page's body to make the widget appear there:</label>
                                <textarea
                                    className="form-control"
                                    name="checkout"
                                    id="checkoutCode"
                                    value={embed.checkout}
                                    readOnly={true}
                                    type="text"

                                />
                            </div>
                            <div className="form-group button-group d-flex justify-content-end">
                                <button className="demoLink" onClick={() => copyFunction("checkout")}>
                                    {embed.checkoutStatus ? "Copied" : "Copy"}
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal >


            <SweetAlertTwo
                show={info.enable}
                title="Are You Sure?"
                text="Once you delete this embed. It will be removed from all the websites where you added this!"
                icon="warning"
                showCancelButton={true}
                confirmButtonColor='#b32f3d'
                cancelButtonColor='#2a2c3a'
                confirmButtonText='Yes, delete!'
                confirmTitle='Deleting...'
                confirmText="This won't be revert!"
                confirmIcon='success'
                handlePerform={onPerformRemove}
                onCancel={handleCancle}
            />
        </>
    )
}

export default AllEmbedTable