import React, { useEffect, useState } from 'react'
import { SlCheck, SlClose, } from "react-icons/sl"
import { BsFillChatDotsFill } from "react-icons/bs"
import SweetAlertTwo from '../SweetAlertTwo';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { onChangeStatus, onHireStaff, uploadBotImage } from '../../Actions/StaffAction';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
// import { Modal } from "react-bootstrap";
import { BsUpload } from 'react-icons/bs';
import Swal from 'sweetalert2';
import defaultImg from '../../images/default_image.jpg'
import modalCloseIcon from "../../images/modal-close.png";

import Dropdown from 'react-bootstrap/Dropdown';
import Modal from 'react-bootstrap/Modal';
import { BiDotsHorizontalRounded, BiEdit, BiTrash } from "react-icons/bi"
import { ImEmbed2 } from 'react-icons/im'
import EmbedModal from './EmbedModal';
import CreateBotModal from '../Training/CreateBotModal';
import ribbon from '../../images/ribbon.png'
import { onSubmitBot } from '../../Actions/SettingAction';
import { useSelector } from 'react-redux';
import { setAlert } from '../../Actions/AlertAction';

const StaffCard = ({ curElem, fetchStaffData, autoResponder, media, fetchUserUploadMedia }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const auth = useSelector(state => state.auth)
    const [dataEdit, setDataEdit] = useState(false)
    const [memberShip, setMemberShip] = useState([])
    const [isAccess, setIsAccess] = useState(false)
    const [info, setInfo] = useState({
        id: -1,
        enable: false,
        text: "",
        title: "",
        confirm: "",
        type: ""
    })
    const [loader, setLoader] = useState({
        hireLoader: false,
        uploadLoader: false
    })

    const [state, setState] = useState({
        staffId: false,
        name: "",
        image: "",
        department: "",
        description: "",
        type: "",
        isUpdateStaffDefault: 0,

    })

    const [show3, setShow3] = useState(false)
    const handleClose3 = () => setShow3(false)

    const [show2, setShow2] = useState(false)
    const handleClose2 = () => setShow2(false)
    const handleShow2 = () => setShow2(true)

    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const handleShow = (data, forConfirm) => {
        setState({
            ...state,
            staffId: data.id,
            name: +data.isfired === 1 ? data.name : data.hire_name,
            image: +data.isfired === 1 ? data.image : data.hire_image,
            department: data.category,
            description: +data.isfired === 1 ? data.description : data.hire_description,
            type: data.type,
            isUpdateStaffDefault: forConfirm,
            gender: data.gender,
            tone: data.tone,
            writingStyle: data.writingStyle,
            staffType: data.staffType
        })
        setShow(true);
    }


    const handleChange = (e) => {
        const { name, value } = e.target
        setState({ ...state, [name]: value });
    };

    const handleBtn = (id, type, staffType) => {
        if (type === "close") {
            setInfo({
                ...info,
                id: id,
                enable: true,
                text: "You want to fire this staff?",
                title: "firing...",
                confirm: 'Yes, fire!',
                type: staffType,
                ep: "fire-staff"

            })
        } else if (type === "staff") {
            setInfo({
                ...info,
                id: id,
                enable: true,
                text: "You want to delete this staff?",
                title: "deleting...",
                confirm: 'Yes, delete!',
                ep: "delete-bot"
            })
        } else {
            setInfo({
                ...info,
                id: id,
                enable: true,
                text: "You want to hire this staff?",
                title: "hiring...",
                confirm: 'Yes, hire!',
                ep: "hire-staff"
            })
        }
    }

    const gotoChat = (curElem) => {
        navigate(`/conversations/chat?sId=${curElem.id}&hId=${curElem.hireId}`)
    }

    const handleCancel = () => {
        setInfo({
            ...info,
            id: -1,
            enable: false,
        })
    }

    const handleHiredStaff = () => {
        if (state.name !== "" && state.description !== "") {
            setLoader({
                ...loader,
                hireLoader: true
            })
            if (state.isUpdateStaffDefault) {
                let dataVal = {
                    botId: curElem.botId,
                    hireId: +curElem.hireId,
                    type: curElem.type,
                    id: curElem.id,
                    name: state.name,
                    description: state.description,
                    image: state.image,
                    // knowledge: curElem.knowledge,
                    category: state.department,
                    isfired: curElem.isfired,
                    gender: state.gender,
                    tone: state.tone,
                    writingStyle: state.writingStyle,
                    staffType: curElem.staffType
                }
                dispatch(onSubmitBot("update-hire-staff", dataVal, handleClose, fetchStaffData, setLoader, loader))
            } else {
                let data = {
                    ...state,
                    staffType: curElem.staffType
                }
                dispatch(onHireStaff(data, fetchStaffData, handleClose, loader, setLoader))
            }
        } else {
            dispatch(setAlert("Please provide the name & description to hire the staff!", "danger"))
        }

    }

    const deleteAgencyData = (swal) => {
        let data = {
            staffId: info.id,
            type: info.type
        }
        if (info.ep === "delete-bot") {
            data = { botId: info.id }
        }
        dispatch(onChangeStatus(data, info.ep, swal, fetchStaffData))
        setInfo({
            ...info,
            id: -1,
            enable: false,
            text: "",
            title: "",
            confirm: ""
        })
    }

    const onUploadFile = (e) => {
        if (e.target.files[0].type === 'image/png' || e.target.files[0].type === 'image/jpg' || e.target.files[0].type === 'image/jpeg') {
            if (e.target.files[0].size < 5000000) {
                const formData = new FormData()
                formData.append('upload_type', "images");
                formData.append('file', e.target.files[0]);
                setLoader({
                    ...loader,
                    uploadLoader: true
                })
                dispatch(uploadBotImage(formData, state, setState, loader, setLoader))
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Max allowed size is 5MB!',
                    confirmButtonColor: "#d03e3e"
                })
            }
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You have selected invalid file type!',
                confirmButtonColor: "#d03e3e"
            })
        }
    }

    const handleEdit = (type) => {
        if (type === "youAiStaff") {
            setDataEdit({
                botId: curElem.botId,
                hireId: +curElem.hireId,
                type: curElem.type,
                id: curElem.id,
                bot_name: +curElem.isfired === 1 ? curElem.name : curElem.hire_name,
                description: +curElem.isfired === 1 ? curElem.description : curElem.hire_description,
                image: +curElem.isfired === 1 ? curElem.image : curElem.hire_image,
                knowledge: curElem.knowledge,
                role: +curElem.isfired === 1 ? curElem.category : curElem.hire_category,
                isfired: curElem.isfired,
                gender: curElem.gender,
                tone: curElem.tone,
                writingStyle: curElem.writingStyle,
                // staffId : 
                // personality: {
                //     botRole: "",
                //     botStrictness: "",
                //     botShow: [],
                //     botAnswer: [],
                //     botCantAnswer: ""
                // },
            })
            setShow3(true)
        } else {
            handleShow(curElem, 1)
        }
    }

    useEffect(() => {
        if (auth.user) {
            setMemberShip(auth.user.membership.split("__"))
        }
    }, [auth.user])

    useEffect(() => {
        if (memberShip.length > 0) {
            setIsAccess(memberShip.includes("plus") || memberShip.includes("plus-lite") || memberShip.includes("fastpass") || memberShip.includes("bundle"))
        }
    }, [memberShip])
    return (
        <>
            <div className="col-sm-6 col-lg-4 col-xl-3">
                <div className="staff-single tt">

                    <div className="staff-img">
                        <img src={+curElem.isfired === 1 ? curElem.image : curElem.hire_image} alt="" />
                        {+auth.user.isClient === 1 ?
                            ""
                            :
                            +curElem.isfired === 0 ?
                                <>
                                    <div className='hireBtn'>
                                        <button className="demoLink mt-0" onClick={() => handleBtn(curElem.id, 'close', curElem.type)}>Fire</button>
                                        <button className="demoLink mt-0" onClick={() => gotoChat(curElem)}>Chat</button>
                                    </div>
                                </> :
                                <div className='hireBtn'>
                                    <button className='demoLink mt-0' onClick={() => handleShow(curElem)}>Hire Now</button>
                                </div>
                        }
                        {curElem.type === "youAiStaff" ?
                            <div className="staff_ribbon">
                                <img src={ribbon} alt="" />
                            </div> : ""}

                        {+auth.user.isClient === 1 ? "" :
                            <div className='stDrop dropLink'>
                                <Dropdown>
                                    <Dropdown.Toggle variant="t" id="dropdown-basic">
                                        <BiDotsHorizontalRounded />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Link to={"/all-embed"}><ImEmbed2 /> All Embeds</Link>
                                        <Link onClick={handleShow2}><ImEmbed2 /> Embed code</Link>
                                        {
                                            curElem.type === "youAiStaff" ?
                                                <>
                                                    <Link onClick={() => handleEdit(curElem.type)}><BiEdit /> Edit</Link>
                                                    {+curElem.isfired === 1 ?
                                                        <Link onClick={() => handleBtn(curElem.id, 'staff')}><BiTrash /> Delete</Link> : ""}
                                                </>
                                                :
                                                +curElem.isfired === 0 ?
                                                    <Link onClick={() => handleEdit(curElem.type)}><BiEdit /> Edit</Link> : ""
                                        }
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        }

                    </div>

                    <div className="staff-txt" >
                        <h6 className='text-capitalize' title={+curElem.isfired === 1 ? curElem.name : curElem.hire_name}>
                            {
                                +curElem.isfired === 1 ?
                                    curElem.name.length > 50 ? curElem.name.slice(0, 50) + "..." : curElem.name :
                                    curElem.hire_name.length > 50 ? curElem.hire_name.slice(0, 50) + "..." : curElem.hire_name
                            }
                            <br />
                            <span>{+curElem.isfired === 1 ? curElem.category : curElem.hire_category}</span>
                        </h6>
                        <OverlayTrigger
                            placement={'top'}
                            overlay={
                                <Tooltip id="tooltip-top" style={{ zIndex: '4' }} >
                                    {+curElem.isfired === 1 ? curElem.description : curElem.hire_description}
                                </Tooltip>
                            }
                        >
                            <p style={{ cursor: "n-resize" }} >
                                {+curElem.isfired === 1 ? curElem.description : curElem.hire_description}
                            </p>
                        </OverlayTrigger>
                    </div>
                </div>
            </div>

            <EmbedModal
                show={show2}
                handleClose={handleClose2}
                curElem={curElem}
                autoResponder={autoResponder}
                media={media}
                fetchUserUploadMedia={fetchUserUploadMedia}
            />

            <CreateBotModal
                staffId={curElem.id}
                curElem={dataEdit}
                show={show3}
                handleClose={handleClose3}
                fetchBots={fetchStaffData}
            />




            <Modal className="VideoModal dark" show={show} onHide={handleClose} centered>
                <Modal.Body>
                    <div onClick={handleClose} className="vidClose">
                        <img alt="" src={modalCloseIcon} />
                    </div>
                    <div className="modal-txt">
                        <div className="modal-txt-top">
                            <h4>{state.isUpdateStaffDefault ? "Update" : "Hire"} Your Staffs</h4>
                        </div>
                        {!isAccess && !curElem.isfired ?
                            <div class="alert alert-info mt-3">
                                <strong>Info!</strong> You do not have the right to update staff. To update staff please update your membership.
                            </div> : ""}
                        <div>
                            <div className="form-group form-group-alt">
                                <label className="form-text text-muted" htmlFor="">Give a name to your staff</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name='name'
                                    placeholder="Enter name here"
                                    value={state.name}
                                    onChange={handleChange}
                                    readOnly={!isAccess}
                                    maxLength={100}
                                />
                            </div>

                            <div className="form-group form-group-alt" style={{ overflow: "hidden" }}>
                                <label className="form-text text-muted" htmlFor="" style={{ width: "100%", paddingBottom: "8px", borderTopLeftRadius: "8px", borderTopRightRadius: "8px", background: "#000", borderTop: "1px solid #7900b3", borderLeft: "1px solid #7900b3", borderRight: "1px solid #7900b3" }}>Give a description to your staff</label>
                                <textarea
                                    className="form-control"
                                    type="text"
                                    name='description'
                                    placeholder="Enter description"
                                    value={state.description}
                                    onChange={handleChange}
                                    readOnly={!isAccess}
                                />
                            </div>
                            <div className="staff_modal_upload_img">
                                <div className="upload-box" style={{ padding: 0, height: "auto", borderStyle: "solid" }}>
                                    <div className="upload-box-in" style={{ width: "100%", height: "auto" }}>

                                        <div className="row align-items-center">

                                            <div className="col-lg-8">
                                                <div className="upload_selected_image" style={{ height: '144px;' }}>
                                                    <BsUpload />
                                                    <p>Upload staff image here (JPG, PNG, JPEG upto 5MB is allowed)</p>
                                                    {isAccess ?
                                                        <input
                                                            type="file"
                                                            onChange={(e) => onUploadFile(e)}
                                                        /> : ""}
                                                </div>

                                            </div>
                                            <div className="col-lg-4">
                                                <div className="upload_img_wrapper" style={{ height: "160px" }}>
                                                    {loader.uploadLoader ?
                                                        <i className="fa fa-spinner fa-spin" style={{ fontSize: "30px", color: "#c238a4", display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }} />
                                                        :
                                                        <img src={state.image !== "" ? state.image : defaultImg} style={{ maxWidth: "100%", maxHeight: "100%", padding: "10px", borderRadius: '28px' }} />}
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="form-group button-group d-flex justify-content-end">
                                <button
                                    className="demoLink"
                                    style={{ background: !isAccess && !+curElem.isfired ? "gray" : "" }}
                                    onClick={() => handleHiredStaff(curElem)}
                                    disabled={!isAccess && !curElem.isfired}
                                >
                                    {loader.hireLoader ? <>  {state.isUpdateStaffDefault ? "Updating" : "Hiring"} <i className="fa fa-spinner fa-spin mx-1" /> </> : state.isUpdateStaffDefault ? "Update" : "Hire"}
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal >



            <SweetAlertTwo
                show={info.enable}
                title="Are You Sure?"
                text={info.text}
                icon="question"
                showCancelButton={true}
                confirmButtonColor='#b32f3d'
                cancelButtonColor='#2a2c3a'
                confirmButtonText={info.confirm}
                confirmTitle={info.title}
                confirmIcon='success'
                handlePerform={deleteAgencyData}
                onCancel={handleCancel}
            />
        </>
    )
}

export default StaffCard