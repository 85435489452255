import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { onUpdateUserSetting, onGetUserSetting } from '../../Actions/SettingAction'
import { useDispatch } from 'react-redux'
import { onFetchOptions } from '../../Actions/StaffAction'

const DefaultSetting = () => {
    const dispatch = useDispatch()
    const setting = useSelector(state => state.setting.data)
    const [textLanguage, setTextLanguage] = useState([])

    const handleChange = (e) => {
        let { name, checked, value } = e.target
        if (name === "autoSubmit") {
            let data = {
                is_enter: setting.isEnter ? 1 : 0,
                autoSubmit: checked === false ? 0 : 1,
                disableStaffNonWorking: setting.disableStaffNonWorking ? 1 : 0,
                openingHours: setting.openingHours,
                langCode: setting.langCode,
                timeZone: setting.timeZone
            }
            dispatch(onUpdateUserSetting(data, name, checked))
        } else if (name === "disableStaffNonWorking") {
            let data = {
                is_enter: setting.isEnter ? 1 : 0,
                autoSubmit: setting.autoSubmit ? 1 : 0,
                disableStaffNonWorking: checked === false ? 0 : 1,
                openingHours: setting.openingHours,
                timeZone: setting.timeZone,
                langCode: setting.langCode,
            }
            dispatch(onUpdateUserSetting(data, name, checked))
        }
        else if (name === "langCode") {
            let data = {
                is_enter: setting.isEnter ? 1 : 0,
                autoSubmit: setting.autoSubmit ? 1 : 0,
                disableStaffNonWorking: setting.disableStaffNonWorking ? 1 : 0,
                openingHours: setting.openingHours,
                timeZone: setting.timeZone,
                langCode: value,
            }
            checked = value
            dispatch(onUpdateUserSetting(data, name, checked))
        }
        else {
            let data = {
                is_enter: checked === false ? 0 : 1,
                autoSubmit: setting.autoSubmit ? 1 : 0,
                disableStaffNonWorking: setting.disableStaffNonWorking ? 1 : 0,
                openingHours: setting.openingHours,
                timeZone: setting.timeZone,
                langCode: setting.langCode,
            }
            dispatch(onUpdateUserSetting(data, name, checked))
        }
    }


    const fetchTextLanguage = () => {
        let ep = "get-record-language"
        dispatch(onFetchOptions(ep, {}, setTextLanguage))
    }

    const fetchUserSetting = () => {
        dispatch(onGetUserSetting())
    }

    useEffect(() => {
        fetchUserSetting()
        fetchTextLanguage()
    }, [])

    return (
        <div className="tabepaneIn">
            <h2 className="tabTitle">General Settings</h2>
            <div className="tabepaneIn-main">
                <div className="tabepaneIn-cont">
                    <h3>General Settings</h3>
                    <div className="bullets_point">
                        <span >Press "ENTER" to submit chat messages.</span>
                        <div className="d-flex mode_wrap">
                            <label className="switch">
                                <input
                                    type="checkbox"
                                    checked={setting.isEnter}
                                    name='isEnter'
                                    onChange={(e) => handleChange(e)}
                                />
                                <span className="slider round"></span>
                            </label>
                        </div>

                    </div>
                    <hr style={{ opacity: "1", color: "#333" }} />
                    <div className="bullets_point">
                        <span >Auto-submit voice chat.</span>
                        <div className="d-flex mode_wrap">
                            <label className="switch">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={setting.autoSubmit}
                                    name='autoSubmit'
                                    onChange={(e) => handleChange(e)}
                                />
                                <span className="slider round"></span>
                            </label>
                        </div>
                    </div>
                    <hr style={{ opacity: "1", color: "#333" }} />

                    <div className="bullets_point">
                        <span >Disable staffs from application in non-working hours.</span>
                        <div className="d-flex mode_wrap">
                            <label className="switch">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={setting.disableStaffNonWorking}
                                    name='disableStaffNonWorking'
                                    onChange={(e) => handleChange(e)}
                                />
                                <span className="slider round"></span>
                            </label>
                        </div>
                    </div>
                    <hr style={{ opacity: "1", color: "#333" }} />
                    <div className="bullets_point">
                        <span >AI Staff Conversation Language.</span>
                        <div className="d-flex mode_wrap ai_lang">
                            <select
                                value={setting.langCode}
                                onChange={(e) => handleChange(e)}
                                name='langCode'
                            >
                                <option value="">Select Language</option>
                                {textLanguage.length > 0 ?
                                    textLanguage.map((curElem, index) => (
                                        <option key={index} value={curElem.code}>
                                            {curElem.name}
                                        </option>
                                    ))
                                    : ""
                                }
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DefaultSetting