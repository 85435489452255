import produce from "immer"
const initialState = {
    data: {
        gId: "",
        cid: "",
        staffId: "",
        type: "",
        hireId: "",
        goal: "",
        maxTask: 10,
        completedTasks: [],
        result: "",
        lastTask: "",
        pendingTasks: []
    },
    tasks: [],
    results: [],
    isGodModeActive: false
}

export const GodModeReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ON_SET_GOAL":
            return produce(state, (draft) => {
                draft.data = {
                    ...draft.data,
                    [action.payload.name]: action.payload.value
                }
            })

        case "ON_SET_INITIALS":
            return produce(state, (draft) => {
                draft.data.cid = action.payload.selectedcId
                draft.data.staffId = action.payload.staff.staffId
                draft.data.type = action.payload.staff.type
                draft.data.hireId = action.payload.staff.hire_id
            })

        case "ON_SET_GOAL_MESSAGE":
            return produce(state, (draft) => {
                draft.isGodModeActive = true
            })
        case "ON_STOP_GOD_MODE":
            return produce(state, (draft) => {
                draft.isGodModeActive = false
            })
        // return initialState
        case "ON_SET_GOAL_RESPONSE":
            return produce(state, (draft) => {
                draft.data.gId = action.payload
            })

        case "ON_EXECUTE_MODE":
            return produce(state, (draft) => {

                // const executedTaskIndex = draft.tasks.findIndex(({ status }) => status === 1)
                // draft.tasks[executedTaskIndex].status = 2

                // draft.results.push({
                //     message: action.payload.resVal.response,
                //     id: action.payload.resVal.id,
                //     type: "response",
                //     task: action.payload.task
                // })
                draft.data.completedTasks.push(action.payload.task)
                draft.data.result = action.payload.resVal.response
                draft.data.lastTask = action.payload.task
                const data = draft.tasks.filter((curElem) => {
                    return curElem.id !== action.payload.taskId
                })
                draft.data.pendingTasks = data.map((curElem) => {
                    return curElem.task
                })
            })


        // case "ON_CREATE_TASKS":
        //     return produce(state, (draft) => {
        //         // draft.tasks = draft.tasks.concat(action.payload)
        //         let arr = action.payload.map((curElem) => {
        //             return {
        //                 message: curElem.task,
        //                 enable: false,
        //                 id: curElem.id,
        //                 status: curElem.status,
        //                 type: "addedTask"
        //             }
        //         })
        //         draft.results = draft.results.concat(arr)
        //     })

        case "ON_CREATE_TASK_THINK":
            return produce(state, (draft) => {

            })
        case "TASK_LIMIT_REACH":
            return produce(state, (draft) => {
                draft.results.push({
                    message: "Task limit reached",
                    type: "limitReach"
                })
            })

        case "RESET_TASK_LIMIT":
            return produce(state, (draft) => {
                draft.data.maxTask = action.payload
            })

        default:
            return state
    }
}