import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { onFetchConversationData, onFetchStaffData } from "../../Actions/StaffAction";
import ConversationCard from "../Conversations/ConversationCard";
import { useSelector } from "react-redux";

const DashboardContent = () => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const [staffLoader, setStaffLoader] = useState(false)
    const [convLoader, setConvLoader] = useState(false)
    const [staff, setStaff] = useState([])
    const [conver, setConver] = useState([])

    const fetchStaff = () => {
        setStaffLoader(true)
        let data = {}
        dispatch(onFetchStaffData(data, setStaff, false, false, setStaffLoader, 4))
    }

    const fetchConversation = (Swal) => {
        setConvLoader(true)
        let data = { offset: 0 }
        dispatch(onFetchConversationData(data, false, setConver, setConvLoader, 3, Swal))
    }
    useEffect(() => {
        if (+auth.user.isClient !== 1) {
            fetchStaff()
        }
        fetchConversation()
    }, [])

    return (
        <>

            <section className="videoProject d-none">
                <div className="container-fluid">
                    <div className="videoProject-top d-none">
                        <h2>Your <span>Business</span></h2>
                        <Link to="/business" className="proView">View all <span><i className="fa fa-angle-right" aria-hidden="true"></i></span> </Link>
                    </div>
                    <div className="videoProject-bottom">

                    </div>
                </div>
            </section>

            {+auth.user.isClient === 1 ? "" :

                <section className="staffSec">
                    <div className="container">
                        <div className="videoProject-top">
                            <h2>Your <span>Staffs</span></h2>
                            <Link to="/staff" className="proView">View all <span><i className="fa fa-angle-right" aria-hidden="true"></i></span> </Link>
                        </div>
                        <div className="staffSec-wrap">
                            <div className="row">
                                {staff.length > 0 ?
                                    staff.map((curElem, index) => {
                                        return (
                                            <div className="col-sm-6 col-lg-3" key={index}>
                                                <div className="staff-single">
                                                    <div className="  staff-img"><img src={curElem.hire_image} alt="" /></div>
                                                    <div className="dashboard staff-txt">
                                                        <h6>{curElem.hire_name}</h6>
                                                        <Link to={`/conversations/chat?sId=${curElem.id}&hId=${curElem.hireId}`} className="demoLink mt-2">Chat</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                    :
                                    <div className="col-sm-12 text-light text-center mt-3">
                                        {staffLoader ?
                                            <i className="fa fa-spinner fa-spin" style={{ fontSize: "30px", color: "#c238a4" }} />
                                            :
                                            "There is no staff you have hired!"}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </section>}

            <section className="convSec">
                <div className="container">
                    <div className="videoProject-top">
                        <h2>Your <span>Conversations</span></h2>
                        <Link to="/conversations" className="proView">View all <span><i className="fa fa-angle-right" aria-hidden="true"></i></span> </Link>
                    </div>
                    <div className="convSec-wrap">
                        <div className="row text-center">

                            {conver.length > 0 ?
                                conver.map((curElem, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <ConversationCard
                                                curElem={curElem}
                                                conversationData={fetchConversation}
                                                setConversation={setConver}
                                            />
                                        </React.Fragment>

                                    )
                                })
                                :
                                <div className="col-sm-12 text-light text-center mt-3">
                                    {convLoader ?
                                        <i className="fa fa-spinner fa-spin" style={{ fontSize: "30px", color: "#c238a4" }} />
                                        :
                                        "No conversation created yet"}
                                </div>
                            }

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default DashboardContent;