import React, { useState } from 'react'
import { FiEdit, FiTrash2, FiEye } from "react-icons/fi"
import { useDispatch } from 'react-redux'
import { onDeleteDoc } from '../../Actions/TrainingAction'
import SweetAlertTwo from '../SweetAlertTwo'

const DocListTable = ({ tableData, fetchFolders, setDataToShow }) => {
    const dispatch = useDispatch()
    const [info, setInfo] = useState({
        id: -1,
        enable: false,
    })


    const deleteDocData = (Swal) => {
        let data = {
            docId: info.id
        }
        dispatch(onDeleteDoc(data, fetchFolders, Swal))
        setInfo({
            ...info,
            id: -1,
            enable: false,
        })
    }

    const handleCancel = () => {
        setInfo({
            ...info,
            id: -1,
            enable: false,
        })
    }

    const handleEdit = (docId) => {
        let obj = tableData.find(({ id }) => id === docId)
        setDataToShow(obj)
    }

    const handleDelete = (id) => {
        setInfo({
            ...info,
            id: id,
            enable: true
        })
    }

    const handleView = (docId) => {
        let obj = tableData.find(({ id }) => id === docId)
        if (obj.type === "upload" || obj.type === "import" ||  obj.type === "docx") {
            window.open(obj.content, "_blank")
        }
    }


    return (
        <>
            <table className="table">
                <tr>
                    <th>Name</th>
                    <th>Status</th>
                    <th>Created On</th>
                    <th></th>
                </tr>
                {tableData.length > 0 ?
                    tableData.map((curElem, index) => {
                        return (
                            <tr key={index}>
                                <td>{curElem.name}</td>
                                <td>{+curElem.docStatus === 1 ? "Created" : +curElem.docStatus === 2 ? "Learning" : "Learned"}</td>
                                <td>{curElem.created}</td>
                                <td>
                                    {curElem.type === "write" ? "" :
                                        <span className="table-link" style={{ color: "#0099de", cursor: "pointer", fontSize: 17, margin: "5px" }} onClick={() => handleView(curElem.id)}><FiEye title='See' /></span>}
                                    <span className="table-link mx-1" style={{ color: "#0099de", cursor: "pointer", fontSize: 17, margin: "5px" }} onClick={() => handleEdit(curElem.id)}><FiEdit title='Edit' /></span>
                                    <span className="table-link " style={{ color: "#0099de", cursor: "pointer", fontSize: 17, margin: "5px" }} onClick={() => handleDelete(curElem.id)}><FiTrash2 title='Delete' /> </span>
                                </td>
                            </tr>
                        )
                    })

                    : ""

                }
            </table>

            {tableData.length === 0 ?
                <div className='text-center text-light'>
                    "No document created yet"
                </div>
                : ""}

            <SweetAlertTwo
                show={info.enable}
                title="Are You Sure?"
                text="You won't be able to revert this!"
                icon="warning"
                showCancelButton={true}
                confirmButtonColor='#b32f3d'
                cancelButtonColor='#2a2c3a'
                confirmButtonText='Yes, delete it!'
                confirmTitle='Deleting...'
                confirmText="This won't be revert!"
                confirmIcon='success'
                handlePerform={deleteDocData}
                onCancel={handleCancel}
            />
        </>
    )
}

export default DocListTable