import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { onFetchOptions } from '../../Actions/StaffAction'
import ClickPromptModal from './ClickPromptModal'


const VoiceTypes = ({ state, setState, sId, handleSubmit }) => {
    const dispatch = useDispatch()

    const [voiceLanguage, setVoiceLanguage] = useState([])
    const [writingStyle, setWritingStyle] = useState([])
    const [tone, setTone] = useState([])
    const [prompts, setPrompts] = useState([])
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)

    const fetchVoiceLanguage = () => {
        let ep = "get-response-language"
        let data = {}
        dispatch(onFetchOptions(ep, data, setVoiceLanguage))
    }

    const fetchWritingStyle = () => {
        let ep = "get-writing-style"
        let data = {}
        dispatch(onFetchOptions(ep, data, setWritingStyle))
    }
    const fetchTone = () => {
        let ep = "get-tone"
        let data = {}
        dispatch(onFetchOptions(ep, data, setTone))
    }
    const fetchExtra = () => {
        let ep = "fetch-creatives"
        let data = {
            staffId: sId
        }
        dispatch(onFetchOptions(ep, data, setPrompts))
    }


    const handleChange = (e) => {
        const { name, value } = e.target
        setState({
            ...state,
            [name]: value
        })
        if (name === "clickPrompt" && value !== "") {
            setShow(true)
        }
    }

    useEffect(() => {
        fetchVoiceLanguage()
        fetchWritingStyle()
        fetchTone()
        fetchExtra()
    }, [])
    return (
        <div className="row">
            <div className="col-lg-3">
                <label htmlFor="">Write In</label>
                <select
                    name="user_response_lang"
                    onChange={handleChange}
                    value={state.user_response_lang}
                >
                    <option value="">Select Language</option>
                    {voiceLanguage.length > 0 ?
                        voiceLanguage.map((curElem, index) => {
                            return (
                                <option key={index} value={curElem.code}>
                                    {curElem.name}
                                </option>
                            )
                        })
                        : ""
                    }
                </select>
            </div>
            <div className="col-lg-3">
                <label htmlFor="">Tone</label>
                <select
                    name="user_tone"
                    onChange={handleChange}
                    value={state.user_tone}
                >
                    <option value="">Select Tone</option>
                    {tone.length > 0 ?
                        tone.map((curElem, index) => {
                            return (
                                <option key={index} value={curElem.name}>
                                    {curElem.name}
                                </option>
                            )
                        })
                        : ""
                    }
                </select>
            </div>
            <div className="col-lg-3">
                <label htmlFor="">Response Style</label>
                <select
                    name="user_writing_style"
                    onChange={handleChange}
                    value={state.user_writing_style}
                >
                    <option value="">Select Response Style</option>
                    {writingStyle.length > 0 ?
                        writingStyle.map((curElem, index) => {
                            return (
                                <option key={index} value={curElem.name}>
                                    {curElem.name}
                                </option>
                            )
                        })
                        : ""
                    }
                </select>
            </div>
            {prompts.length > 0 ?
                <div className="col-lg-3">
                    <label htmlFor="">DFY Tasks</label>
                    <select
                        name="clickPrompt"
                        onChange={handleChange}
                    >
                        <option value="">Select Task</option>
                        {
                            prompts.map((curElem, index) => {
                                return (
                                    <option key={index} value={curElem.creative}>
                                        {curElem.creative}
                                    </option>
                                )
                            })
                        }
                    </select>
                </div> : ""}

            <ClickPromptModal
                show={show}
                handleClose={handleClose}
                handleSubmit={handleSubmit}
                state={state}
                setState={setState}
            />
        </div>
    )
}

export default VoiceTypes