import React from "react";
import { NavLink } from "react-router-dom";

const HelpSubContent = (props) => {
    return (
        <>
            <div className="col-xl-3 col-lg-4 col-sm-6">
                <NavLink to={props.url}>
                    <div className="impTopic-single">
                        <div className="text-center">
                            <img src={props.image} alt="union" />
                            <h3>{props.name}</h3>
                        </div>
                    </div>
                </NavLink>
            </div>

        </>
    )
}

export default HelpSubContent;