import React from 'react'
import { Modal } from 'react-bootstrap'
import modalCloseIcon from "../../images/modal-close.png";

const ClickPromptModal = ({ show, handleClose, handleSubmit, state, setState }) => {

    const submitClickPrompt = (e) => {
        e.preventDefault()
        handleClose()
        handleSubmit()
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setState({ ...state, [name]: value, messages: `<b>DFY Task: </b> ${state.clickPrompt} - ${value}` })
    }

    const handleResetClose = () => {
        setState({ ...state, services: "", clickPrompt: "" })
        handleClose()
    }


    return (
        <Modal className="VideoModal dark" show={show} onHide={handleResetClose} centered>
            <Modal.Body>
                <div onClick={handleResetClose} className="vidClose">
                    <img alt="" src={modalCloseIcon} />
                </div>
                <div className="modal-txt">
                    <h1 className='text-center' style={{ fontSize: "15px", paddingTop: "20px", fontWeight: "400" }}>Give Product or Service name</h1>
                    <form onSubmit={submitClickPrompt} style={{ marginTop: "-8px" }}>
                        <div className="form-group form-group-alt">
                            <label className="form-text text-muted" htmlFor="">Please enter your Product or Service name</label>
                            <input
                                className="form-control"
                                name="services"
                                placeholder='Enter product & service name'
                                value={state.productName}
                                onChange={(e) => handleChange(e)}
                                type="text"
                                required
                            />
                        </div>
                        <div className="form-group button-group d-flex justify-content-end">
                            <button className="demoLink" type='submit'> Send</button>
                        </div>
                    </form>
                </div>
            </Modal.Body>
        </Modal >
    )
}

export default ClickPromptModal