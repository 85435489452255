import React, { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { onDeleteMessages, onGetOfflineMessages } from '../../Actions/SettingAction'
import { useSelector } from 'react-redux'
import { FaUserCircle } from 'react-icons/fa'
import Pagination from '../../Pagination'
import { BsTrashFill } from 'react-icons/bs'
import SweetAlertTwo from '../SweetAlertTwo'

const OfflineMessages = () => {
    const dispatch = useDispatch()
    const userData = useSelector(state => state.auth.user)
    const [tableData, setTableData] = useState([])
    const [loader, setLoader] = useState(false)
    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItemonPage: 10
    });
    const [info, setInfo] = useState({
        id: -1,
        enable: false,
    })

    const handleCancel = () => {
        setInfo({
            ...info,
            id: -1,
            enable: false,
        })
    }

    const deleteMessage = (mId) => {
        setInfo({
            ...info,
            id: mId,
            enable: true
        })
    }



    const fetchOfflineMessages = () => {
        setLoader(true)
        let data = {
            userId: userData.id
        }
        dispatch(onGetOfflineMessages(data, setTableData, setLoader))
    }

    const handleDelete = (Swal) => {
        let data = {
            id: info.id
        }
        dispatch(onDeleteMessages(data, fetchOfflineMessages, Swal))
        setInfo({
            ...info,
            id: -1,
            enable: false,
        })
    }

    const indexofLastTodo = pagination.currentPage * pagination.totalItemonPage;
    const indexofFirstTodo = indexofLastTodo - pagination.totalItemonPage;
    const currentTodo = tableData.slice(indexofFirstTodo, indexofLastTodo);

    useEffect(() => {
        fetchOfflineMessages()
    }, [])


    return (
        <>
            <div className="tabepaneIn">
                <h2 className="tabTitle">Offline messages</h2>
                <div className="tabepaneIn-main">
                    <div className="tabepaneIn-cont">
                        <h3>Offline messages</h3>
                        <div className="offline doc-table">
                            <table className="table">
                                <tr>
                                    <th>Image</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Message</th>
                                    <th className='text-center'>Action</th>
                                </tr>
                                {currentTodo.length > 0 ?
                                    currentTodo.map((curElem, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    <div className="proThumb">

                                                        {curElem.image ? <img src={curElem.image} alt='' /> : <FaUserCircle />}
                                                    </div>
                                                </td>
                                                <td>{curElem.name}</td>
                                                <td>{curElem.email}</td>
                                                <td style={{ whiteSpace: "break-spaces" }}>
                                                    {curElem.message}
                                                </td>
                                                <td className='text-center '>
                                                    <BsTrashFill
                                                        className='button-hover-effect'
                                                        size={20}
                                                        cursor={"pointer"}
                                                        title='Delete'
                                                        onClick={() => deleteMessage(curElem.id)}
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    })

                                    : ""
                                }
                            </table>

                        </div>
                        <div className="text-center my-3 text-light">
                            {tableData.length === 0 ?
                                loader ?
                                    <i className="fa fa-spinner fa-spin" style={{ color: "#9c1d6d", fontSize: 30 }} />
                                    : "No offline messages data found!"

                                : ""}
                        </div>
                    </div>
                </div>
            </div>

            <Pagination
                designData={tableData}
                pagination={pagination}
                setPagination={setPagination}
                currentTodo={currentTodo}
                loader={loader}
            />

            <SweetAlertTwo
                show={info.enable}
                title="Are You Sure?"
                text={"Do you want to delete this message?"}
                icon="question"
                showCancelButton={true}
                confirmButtonColor='#b32f3d'
                cancelButtonColor='#2a2c3a'
                confirmButtonText={"Yes, Delete"}
                confirmText="This won't be revert!"
                confirmTitle={"Deleting..."}
                confirmIcon='success'
                handlePerform={handleDelete}
                onCancel={handleCancel}
            />
        </>
    )
}

export default OfflineMessages
