import React from "react";
import img1 from "../../images/20.png"
import img2 from "../../images/60.png"
import img3 from "../../images/10.png"
import img4 from "../../images/50.png"
import img5 from "../../images/30.png"
import img6 from "../../images/40.png"
import { useSelector } from "react-redux";
import { appName } from "../../Global/Global";


const DashboardAbout = () => {
    const rebrandData = useSelector(state => state.rebrand.data)
    return (
        <>
            <section className="featureSec">
                <div className="container-fluid">
                    <div className="featureSec-wrap">
                        <div className="featureSec-single">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="featureSec-left">
                                        {/* <h6>ULTIMATE SOLUTION TO DRIVE PERPETUAL LEADS</h6> */}
                                        <h2> <span className="add_color">Cost</span>-efficiency</h2>

                                        <ul className="dash-list mt-2" style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                                            <li>Eliminates the need for hiring and maintaining a large human workforce, reducing labor costs.</li>
                                            <li>Saves businesses money by avoiding expenses such as monthly salaries, benefits, and training.</li>
                                            <li>Offers a more affordable alternative to hiring traditional employees or outsourcing tasks.</li>
                                        </ul>

                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="featureSec-right"><img alt="" src={img1} /></div>
                                </div>
                            </div>
                        </div>
                        <div className="featureSec-single">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="featureSec-left">
                                        {/* <h6>AUTOMATIC AD CREATION</h6> */}
                                        <h2>Time-saving & increased <span className="add_color">productivity</span> </h2>
                                        <ul className="dash-list mt-2" style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                                            <li>AI employees can work 24/7/365, ensuring tasks are completed efficiently without delays.</li>
                                            <li>Enables businesses to focus on their core competencies and higher-priority tasks while {rebrandData ? rebrandData.name : appName} handles routine or specialized tasks.</li>
                                            <li>Streamlines operations by automating various aspects of the business, allowing for quicker decision-making and implementation.</li>
                                        </ul>

                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="featureSec-right"><img alt="" src={img2} /></div>
                                </div>
                            </div>
                        </div>
                        <div className="featureSec-single">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="featureSec-left">
                                        {/* <h6>INSTANT VIDEO LEAD GENERATION WEBSITE</h6> */}
                                        <h2>Expertise in multiple <span className="add_color">domains</span> </h2>
                                        <ul className="dash-list mt-2" style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                                            <li>Provides access to a diverse range of AI-driven chatbots, each specialized in a specific field (e.g., marketing, legal advising, sales, etc.).</li>
                                            <li>Allows businesses to tap into the expertise of AI employees without needing to invest time and resources in hiring and training human employees.</li>
                                            <li>Offers an opportunity for businesses to explore new areas of growth and expansion with the help of AI expertise.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="featureSec-right"><img alt="" src={img3} /></div>
                                </div>
                            </div>
                        </div>
                        <div className="featureSec-single">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="featureSec-left">
                                        {/* <h6>INSTANT VIDEO LEAD GENERATION WEBSITE</h6> */}
                                        <h2> <span className="add_color">Scalability</span> & adaptability</h2>
                                        <ul className="dash-list mt-2" style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                                            <li>Facilitates rapid scaling by enabling businesses to onboard AI employees as needed to accommodate growing demands.</li>
                                            <li>Allows businesses to adapt to market changes quickly by easily adding or removing AI employees based on their needs.</li>
                                            <li>Provides a flexible workforce solution that can be customized according to a business's unique requirements.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="featureSec-right"><img alt="" src={img4} /></div>
                                </div>
                            </div>
                        </div>
                        <div className="featureSec-single">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="featureSec-left">
                                        {/* <h6>INSTANT VIDEO LEAD GENERATION WEBSITE</h6> */}
                                        <h2>Enhanced customer <span className="add_color">support</span> </h2>
                                        <ul className="dash-list mt-2" style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                                            <li>Integrates AI employees as live chat support on websites or members areas, offering customers instant assistance and information.</li>
                                            <li>Ensures consistent and personalized support, improving customer satisfaction and retention.</li>
                                            <li>Reduces response times and helps businesses cater to customers across different time zones.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="featureSec-right"><img alt="" src={img5} /></div>
                                </div>
                            </div>
                        </div>
                        <div className="featureSec-single">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="featureSec-left">
                                        {/* <h6>INSTANT VIDEO LEAD GENERATION WEBSITE</h6> */}
                                        <h2>Continuous learning & <span className="add_color">improvement</span> </h2>
                                        <ul className="dash-list mt-2" style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                                            <li>Allows AI employees to be trained with business-specific documents and data, enabling them to adapt to unique needs and nuances.</li>
                                            <li>Ensures {rebrandData ? rebrandData.name : appName} stay updated on industry trends and technologies, providing businesses with the most relevant and accurate support.</li>
                                            <li>Offers an opportunity for businesses to explore new areas of growth and expansion with the help of AI expertise.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="featureSec-right"><img alt="" src={img6} /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </>
    )
}

export default DashboardAbout;