import produce from "immer"
const initialState = {
    integration: {
        getResponse: [],
        aweber: [],
        mailchimp: [],
        mailvio: []
    }
}

export const SocialResponder = (state = initialState, action) => {
    switch (action.type) {
        case "SET_RESPONDER_LIST":
            return produce(state, (draft) => {
                draft.integration = action.payload
            })

        default:
            return state
    }
}