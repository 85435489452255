import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { onAddMeridiemFrom, onAddMeridiemTo, onAddTimeFrom, onAddTimeTo, onEnableDay } from '../../Actions/SettingAction'

const OpeningHours = ({ curElem, index, format, state }) => {

    const dispatch = useDispatch()

    const handleTime = (e, isFrom, isTo) => {
        const { checked, name, value } = e.target;
        if (name === "enable") {

            let data = { ...state.openingHours }
            data[index] = {
                ...data[index],
                enable: checked
            }
            let obj = {
                ...state, openingHours: data,
                is_enter: state.isEnter
            }
            delete obj.isEnter;
            dispatch(onEnableDay(obj, index, checked))

        }
        else if (name === "meridiem") {
            if (isFrom) {
                let data = { ...state.openingHours }
                data[index] = {
                    ...data[index],
                    fromMeridiem: value
                }
                let obj = {
                    ...state, openingHours: data,
                    is_enter: state.isEnter
                }
                delete obj.isEnter;
                dispatch(onAddMeridiemFrom(obj, index, value))
            }
            if (isTo) {
                let data = { ...state.openingHours }
                data[index] = {
                    ...data[index],
                    toMeridiem: value
                }
                let obj = {
                    ...state, openingHours: data,
                    is_enter: state.isEnter
                }
                delete obj.isEnter;
                dispatch(onAddMeridiemTo(obj, index, value))
            }
        }
        else {
            if (isFrom) {
                let data = { ...state.openingHours }
                data[index] = {
                    ...data[index],
                    fromTime: value
                }
                let obj = {
                    ...state, openingHours: data,
                    is_enter: state.isEnter
                }
                delete obj.isEnter;
                dispatch(onAddTimeFrom(obj, index, value))
            }
            if (isTo) {
                let data = { ...state.openingHours }
                data[index] = {
                    ...data[index],
                    toTime: value
                }
                let obj = {
                    ...state, openingHours: data,
                    is_enter: state.isEnter
                }
                delete obj.isEnter;
                dispatch(onAddTimeTo(obj, index, value))
            }
        }
    }
    return (

        <div className="openHr-single">
            <div className="openHr-single-left">
                <div style={{ width: "102px" }}>{curElem.day}</div>
                <div>
                    <label className="switch flex">
                        <input
                            type="checkbox"
                            name="enable"
                            checked={curElem.enable}
                            onChange={(e) => handleTime(e)}
                        />
                        <span className="slider round"></span>
                        <span className="slClose">Closed</span>
                        <span className="slOpen">Open</span>
                    </label>
                </div>
            </div>
            {curElem.enable ?

                <div className="openHr-single-right">
                    <div className="timeFrom">
                        <div style={{ color: "#000", fontSize: "13px" }}>From</div>
                        {format !== "24" ? (
                            <>
                                <div
                                    className="timeFrom-box"
                                >
                                    <select
                                        name="from"
                                        value={curElem.fromTime}
                                        onChange={(e) => handleTime(e, true, false)}
                                        style={{ width: "65%" }}
                                    >
                                        <option value="">Select Time</option>
                                        <option value="1:00">1:00</option>
                                        <option value="2:00">2:00</option>
                                        <option value="3:00">3:00</option>
                                        <option value="4:00">4:00</option>
                                        <option value="5:00">5:00</option>
                                        <option value="6:00">6:00</option>
                                        <option value="7:00">7:00</option>
                                        <option value="8:00">8:00</option>
                                        <option value="9:00">9:00</option>
                                        <option value="10:00">10:00</option>
                                        <option value="11:00">11:00</option>
                                        <option value="12:00">12:00</option>
                                    </select>

                                    <select
                                        name="meridiem"
                                        value={curElem.fromMeridiem}
                                        onChange={(e) => handleTime(e, true, false)}
                                        style={{ width: "35%" }}
                                    >
                                        <option value="">Select Meridiem</option>
                                        <option value="AM">AM</option>
                                        <option value="PM">PM</option>
                                    </select>
                                </div>
                            </>)

                            : (
                                <>

                                    <div
                                        className="timeFrom-box"
                                    >
                                        <select
                                            style={{ width: "100%" }}
                                            name="from"
                                            value={curElem.fromTime}
                                            onChange={(e) => handleTime(e, true, false)}
                                        >
                                            <option value="">Select Time</option>
                                            <option value="1:00">1:00</option>
                                            <option value="2:00">2:00</option>
                                            <option value="3:00">3:00</option>
                                            <option value="4:00">4:00</option>
                                            <option value="5:00">5:00</option>
                                            <option value="6:00">6:00</option>
                                            <option value="7:00">7:00</option>
                                            <option value="8:00">8:00</option>
                                            <option value="9:00">9:00</option>
                                            <option value="10:00">10:00</option>
                                            <option value="11:00">11:00</option>
                                            <option value="12:00">12:00</option>
                                            <option value="13:00">13:00</option>
                                            <option value="14:00">14:00</option>
                                            <option value="15:00">15:00</option>
                                            <option value="16:00">16:00</option>
                                            <option value="17:00">17:00</option>
                                            <option value="18:00">18:00</option>
                                            <option value="19:00">19:00</option>
                                            <option value="20:00">20:00</option>
                                            <option value="21:00">21:00</option>
                                            <option value="22:00">22:00</option>
                                            <option value="23:00">23:00</option>
                                            <option value="24:00">24:00</option>

                                        </select>
                                    </div>
                                </>)
                        }



                    </div>
                    <div className="timeFrom">
                        <div style={{ color: "#000", fontSize: "13px" }}>TO</div>
                        {format !== "24" ? (
                            <>

                                <div
                                    className="timeFrom-box"
                                >
                                    <select
                                        name="to"
                                        value={curElem.toTime}
                                        onChange={(e) => handleTime(e, false, true)}
                                        style={{ width: "65%" }}
                                    >
                                        <option value="">Select Time</option>
                                        <option value="1:00">1:00</option>
                                        <option value="2:00">2:00</option>
                                        <option value="3:00">3:00</option>
                                        <option value="4:00">4:00</option>
                                        <option value="5:00">5:00</option>
                                        <option value="6:00">6:00</option>
                                        <option value="7:00">7:00</option>
                                        <option value="8:00">8:00</option>
                                        <option value="9:00">9:00</option>
                                        <option value="10:00">10:00</option>
                                        <option value="11:00">11:00</option>
                                        <option value="12:00">12:00</option>
                                    </select>
                                    <select
                                        name="meridiem"
                                        value={curElem.toMeridiem}
                                        onChange={(e) => handleTime(e, false, true)}
                                        style={{ width: "35%" }}
                                    >
                                        <option value="">Select Meridiem</option>
                                        <option value="AM">AM</option>
                                        <option value="PM">PM</option>
                                    </select>
                                </div>
                            </>)


                            : (
                                <>

                                    <div
                                        className="timeFrom-box"
                                    >
                                        <select
                                            name="to"
                                            style={{ width: "100%" }}
                                            value={curElem.toTime}
                                            onChange={(e) => handleTime(e, false, true)}
                                        >
                                            <option value="">Select Time</option>
                                            <option value="1:00">1:00</option>
                                            <option value="2:00">2:00</option>
                                            <option value="3:00">3:00</option>
                                            <option value="4:00">4:00</option>
                                            <option value="5:00">5:00</option>
                                            <option value="6:00">6:00</option>
                                            <option value="7:00">7:00</option>
                                            <option value="8:00">8:00</option>
                                            <option value="9:00">9:00</option>
                                            <option value="10:00">10:00</option>
                                            <option value="11:00">11:00</option>
                                            <option value="12:00">12:00</option>
                                            <option value="13:00">13:00</option>
                                            <option value="14:00">14:00</option>
                                            <option value="15:00">15:00</option>
                                            <option value="16:00">16:00</option>
                                            <option value="17:00">17:00</option>
                                            <option value="18:00">18:00</option>
                                            <option value="19:00">19:00</option>
                                            <option value="20:00">20:00</option>
                                            <option value="21:00">21:00</option>
                                            <option value="22:00">22:00</option>
                                            <option value="23:00">23:00</option>
                                            <option value="24:00">24:00</option>

                                        </select>
                                    </div>
                                </>)
                        }
                    </div>
                </div > : ""
            }
        </div>

    )
}

export default OpeningHours