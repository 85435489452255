import { commonAxios } from "../Global/CommonAxios";
import { setAlert } from "./AlertAction";

export const onFetchIntegrations = (token) => (dispatch, getState) => {
    let thisToken = token ? token : getState().auth.token
    commonAxios("autoresponder-list", {}, dispatch, thisToken)
        .then((res) => {
            if (res.status) {
                dispatch({ type: "SET_RESPONDER_LIST", payload: res.data })
            }
        }).catch((err) => {
            console.log(err)
        })
}


export const onConnectAutoRes = (data, checkStatus, setCheckStatus, randomString, intervalData, setIntervalData, setLoader) => (dispatch, getState) => {
    commonAxios("autoresponder-auth-url", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let myWindow = window.open(
                    res.data.outhUrl,
                    "_blank",
                    "width=700, height=500"
                );
                setIntervalData(
                    setInterval(() => {
                        if (checkStatus) {
                            dispatch(checkResponse(randomString, intervalData, setIntervalData, myWindow, setCheckStatus)
                            );
                        }
                    }, 5000)
                )
            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
            setLoader(false);
        })
        .catch((err) => {
            console.log(err);
            setLoader(false);
        });
}

export const onConnectMailvio = (data, setLoader, handleClose) => (dispatch, getState) => {
    commonAxios("autoresponder-auth-url", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"));
                let obj = {
                    key: data.key
                }
                dispatch(checkResponseMailvio(obj))
                handleClose()
            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err);
            setLoader(false)
        })
}

export const checkResponseMailvio = (obj) => (dispatch, getState) => {
    let data = {
        key: obj.key,
    };
    commonAxios("check-network-status", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(onFetchIntegrations())
                dispatch(setAlert(res.msg, "success"));
            }
        })
        .catch((err) => {
            console.log(err)
        })
}

export const checkResponse = (string1, intervalData, setIntervalData, myWindow, setCheckStatus) => (dispatch, getState) => {
    let data = {
        key: string1
    };
    commonAxios("check-network-status", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(onFetchIntegrations())
                myWindow.close()
                setCheckStatus(false)
                dispatch(setAlert(res.msg, "success"));
                clearInterval(intervalData);
                setIntervalData(false)
            }
        })
        .catch((err) => {
            window.close();
            console.log(err)
        })
}

export const onDeleteAutoRes = (data, fetchIntegration, Swal) => (dispatch, getState) => {
    commonAxios("delete-autoresponder-connection", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchIntegration()
                dispatch(setAlert(res.msg, "success"))
            }
            else {
                dispatch(setAlert(res.msg, "danger"))
            }
            Swal.close()
        }).catch((err) => {
            console.log(err)
            Swal.close()
            dispatch(setAlert(err.message, "danger"))
        })
}