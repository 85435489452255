import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import modalCloseIcon from "../../images/modal-close.png";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { onFetchSelectAutoResponderData, onSaveDataEmbed, uploadEmbedIcon } from '../../Actions/StaffAction';
import Swal from 'sweetalert2';
import { setAlert } from '../../Actions/AlertAction';

const EmbedModal = ({ show, handleClose, curElem, autoResponder, media }) => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const [loader, setLoader] = useState({
        uploadLoader: false,
        tab: true,
        generateLoader: false
    })
    const [embedId, setEmbedId] = useState(0)
    const [embed, setEmbed] = useState({
        header: "",
        headerStatus: false,
        checkout: "",
        checkoutStatus: false,
    })
    const [state, setState] = useState({
        staffId: curElem.id,
        type: "",
        autoresponderId: "",
        listOption: "",
        image: "https://reeelapps-app.s3.us-west-2.amazonaws.com/aistaff/embed_logo/Embed_logo.png",
        url: "https://",
        name: ""
    });
    const [responderList, setResponderList] = useState([])


    const copyFunction = async (type) => {
        if (state.url) {
            var copyText;
            if (type === "header") {
                copyText = document.getElementById("headerCode");
                setEmbed({
                    ...embed,
                    headerStatus: true,
                    checkoutStatus: false,
                });
            } else {
                copyText = document.getElementById("checkoutCode");
                setEmbed({
                    ...embed,
                    headerStatus: false,
                    checkoutStatus: true,
                });
            }
            copyText.select();
            copyText.setSelectionRange(0, 99999);
            navigator.clipboard.writeText(copyText.value);
        } else {
            dispatch(setAlert("Please enter website url where you want to put this embed code", "danger"))
        }
    };

    const selectAutoResponder = (e) => {
        const { value } = e.target
        const selectedResponder = autoResponder.find((elem) => elem.id === value);
        setResponderList([])
        if (selectedResponder) {
            let ep;
            if (selectedResponder.name === "mc") {
                ep = "mc-list"
                setState({ ...state, autoresponderId: selectedResponder.id, type: "mc", listOption: "" })
            } else if (selectedResponder.name === "aw") {
                ep = "aw-list"
                setState({ ...state, autoresponderId: selectedResponder.id, type: "aw", listOption: "" })
            } else if (selectedResponder.name === "gr") {
                ep = "gr-list"
                setState({ ...state, autoresponderId: selectedResponder.id, type: "gr", listOption: "" })
            } else if (selectedResponder.name === "mv") {
                ep = "mv-list"
                setState({ ...state, autoresponderId: selectedResponder.id, type: "mv", listOption: "" })
            }
            let data = { id: selectedResponder.id }
            dispatch(onFetchSelectAutoResponderData(ep, data, setResponderList))
        } else {
            setState({ ...state, autoresponderId: "", type: "", listOption: "" })
        }
    }

    const handleGenerateEmbed = (e) => {
        e.preventDefault()
        setLoader({
            ...loader,
            generateLoader: true
        })
        dispatch(onSaveDataEmbed(state, curElem, setEmbedId, setLoader, loader))
    }

    const handleChange = (e) => {
        const { value } = e.target
        setState({ ...state, listOption: value })
    }


    const handleImgChoose = (e, type) => {
        if (type === "upload") {
            const formData = new FormData()
            let uploadImgType = ['image/png', 'image/jpg', 'image/jpeg']

            if (e.target.files[0].size <= 5000000) {
                if (uploadImgType.includes(e.target.files[0].type)) {
                    setLoader({
                        ...loader,
                        uploadLoader: true
                    })
                    formData.append("file", e.target.files[0])
                    formData.append("type", "images")
                    dispatch(uploadEmbedIcon(formData, state, setState, loader, setLoader))
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Max allowed size is 5MB!',
                    confirmButtonColor: "#d03e3e"
                })
            }
        } else {
            setState({
                ...state,
                image: e
            })
        }
    }

    useEffect(() => {
        if (show) {
            setEmbed({
                ...embed,
                header: `<script type="text/Javascript" id="ai_widget" src="https://backend.aistaffs.com/front-end/chat-box/embed.js?type=${curElem.type}&staffId=${curElem.id}&uId=${auth.user.id}&arId=${state.autoresponderId !== "" ? state.autoresponderId : 0}&arListId=${state.listOption !== "" ? state.listOption : 0}&icn=${state.image}&webUrl=${state.url}&embId=${embedId}"></script> `,
                checkout: `<div id = "aistaff_chat_box"></div > `,
            });
        }


    }, [show, state, embedId])



    useEffect(() => {
        return () => {
            setLoader({
                ...loader,
                tab: true
            })
            setState({
                ...state,
                staffId: curElem.id,
                type: "",
                autoresponderId: "",
                listOption: "",
                image: "https://reeelapps-app.s3.us-west-2.amazonaws.com/aistaff/embed_logo/Embed_logo.png",
                url: "https://",
                name: ""
            })
        }
    }, [handleClose])



    return (
        <Modal className="VideoModal dark" show={show} onHide={handleClose} centered>
            <Modal.Body>
                <div onClick={handleClose} className="vidClose">
                    <img alt="" src={modalCloseIcon} />
                </div>
                <div className="modal-txt">
                    <div className="modal-txt-top">
                        <h4>Copy Paste Embed Code</h4>
                    </div>

                    {/* <div className="modal-txt-mid mb-3">
                        <span >Enable Internet access for the staff </span>
                        <div className="d-flex mode_wrap">
                            <label className="switch">
                                <input
                                    className="form-check-input"
                                    type="checkbox"

                                />
                                <span className="slider round"></span>
                            </label>
                        </div>
                    </div> */}

                    {loader.tab ?
                        <form onSubmit={handleGenerateEmbed}>
                            <div className="AutoResponder_wrapper">
                                <div className="row align-items-center m-0" style={{ maxHeight: "100%", minHeight: "125px" }}>
                                    <div className="col-lg-12 mb-2 ">
                                        <div className="d-flex justify-content-between align-items-center  ">
                                            <label htmlFor="" className='pe-1' style={{ fontSize: "14px" }}>Name</label>
                                            <input
                                                type='text'
                                                className="form-control"
                                                style={{ width: "50%", background: "#292c3a", border: "none", color: "#fff", paddingLeft: 23 }}
                                                placeholder='Enter Name'
                                                value={state.name}
                                                onChange={(e) => setState({
                                                    ...state,
                                                    name: e.target.value
                                                })}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 mb-2 ">
                                        <div className="d-flex justify-content-between align-items-center  ">
                                            <label htmlFor="" className='pe-1' style={{ fontSize: "14px" }}>Website URL</label>
                                            <input
                                                type='url'
                                                className="form-control"
                                                style={{ width: "50%", background: "#292c3a", border: "none", color: "#fff", paddingLeft: 23 }}
                                                placeholder='Enter Website URL Here'
                                                value={state.url}
                                                onChange={(e) => setState({
                                                    ...state,
                                                    url: e.target.value
                                                })}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 mb-2">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <label htmlFor="" className='pe-1' style={{ fontSize: "14px" }}>Autoresponder</label>
                                            <select
                                                name="autoResponder"
                                                onChange={(e) => selectAutoResponder(e)}
                                                value={state.autoresponderId}
                                                style={{ width: "50%" }}
                                            >
                                                <option value="" style={{ fontWeight: "bold" }}>Select Autoresponder</option>
                                                {autoResponder.length > 0 ?
                                                    autoResponder.map((curElem, index) => {
                                                        let info = JSON.parse(curElem.data)
                                                        return (
                                                            <option key={index} value={curElem.id} className='text-capitalize'>
                                                                {`${info.user_name} - ${curElem.social}`}
                                                            </option>
                                                        )
                                                    })
                                                    : ""
                                                }
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 mb-2 ">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <label htmlFor="" className='pe-1' style={{ fontSize: "14px" }}>Autoresponder list</label>
                                            <select
                                                name="autoResponderList"
                                                onChange={handleChange}
                                                value={state.listOption}
                                                style={{ width: "50%" }}
                                            >
                                                <option value="">Select Autoresponder list</option>
                                                {responderList.length > 0 ?
                                                    responderList.map((curElem, index) => {
                                                        return (
                                                            <option key={index} value={curElem.campaignId}>
                                                                {curElem.name}
                                                            </option>
                                                        )
                                                    })
                                                    : ""
                                                }
                                            </select>
                                        </div>
                                    </div>


                                </div>
                            </div>

                            <h6 className='mt-4'>Embbed Icon  <span style={{ fontSize: 13, fontWeight: "normal" }}>(Maximum pixels 1000 x 1000 px & size is 5MB)</span> </h6>
                            <div>
                                <div className="form-group form-group-alt embed_content d-flex mt-1 upload-box " style={{ padding: 5 }}>
                                    <input
                                        className="form-control me-3"
                                        name="header"
                                        type="file"
                                        onChange={(e) => handleImgChoose(e, "upload")}
                                    />
                                    <div style={{ height: 90, width: 80, padding: 10, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        {loader.uploadLoader ?
                                            <>
                                                <i className="fa fa-spinner fa-spin" style={{ fontSize: "30px", color: "#c238a4" }} />
                                            </> :
                                            <>
                                                <img src={state.image} style={{ height: "100%", width: "100%" }} />
                                            </>
                                        }

                                    </div>


                                </div>
                            </div>
                            {media ?
                                media.length > 0 ?
                                    <div>
                                        <h6 Icon style={{ marginTop: "20px" }} > Your Uploaded Icons </h6>
                                        <div className="uploadImages_wrapper ">
                                            <div
                                                className="img_wrapper cursor-pointer"
                                                onClick={() => handleImgChoose("https://reeelapps-app.s3.us-west-2.amazonaws.com/aistaff/embed_logo/Embed_logo.png", "choose")}
                                                style={{ borderRadius: 8, border: "https://reeelapps-app.s3.us-west-2.amazonaws.com/aistaff/embed_logo/Embed_logo.png" === state.image ? "1px solid #ac294b" : "" }}
                                            >
                                                <img src="https://reeelapps-app.s3.us-west-2.amazonaws.com/aistaff/embed_logo/Embed_logo.png" alt="" style={{ borderRadius: 8 }} />
                                            </div>
                                            {
                                                media.map((curElem, index) => {
                                                    return (
                                                        <div
                                                            className="img_wrapper cursor-pointer"
                                                            key={index} onClick={() => handleImgChoose(curElem.url, "choose")}
                                                            style={{ borderRadius: 8, border: curElem.url === state.image ? "1px solid #ac294b" : "" }}
                                                        >
                                                            <img src={curElem.url} alt="" style={{ borderRadius: 8 }} />
                                                        </div>
                                                    )
                                                })
                                            }

                                        </div>
                                    </div>
                                    : "" : ""
                            }
                            <div className="form-group button-group d-flex justify-content-end">
                                <button
                                    className="demoLink"
                                    type='submit'
                                >
                                    {loader.generateLoader ?
                                        <>Generating <i className="fa fa-spinner fa-spin ms-1" /></> : "Generate Embed"
                                    }
                                </button>
                            </div>
                        </form> :
                        <>
                            <div>
                                <div className="form-group form-group-alt embed_content">
                                    <label className="form-text text-muted mb-2" htmlFor="">Insert this code in the header section between &lt;head&gt;&lt;/head&gt; tags in your page:</label>
                                    <textarea
                                        className="form-control"
                                        name="header"
                                        id="headerCode"
                                        value={embed.header}
                                        readOnly={true}
                                        type="text"
                                    />
                                </div>
                                <div className="form-group button-group d-flex justify-content-end">
                                    <button className="demoLink" onClick={() => copyFunction("header")}>
                                        {embed.headerStatus ? "Copied" : "Copy"}
                                    </button>
                                </div>
                            </div>

                            <div>
                                <div className="form-group form-group-alt embed_content">
                                    <label className="form-text text-muted" htmlFor="">Put this code anywhere in your page's body to make the widget appear there:</label>
                                    <textarea
                                        className="form-control"
                                        name="checkout"
                                        id="checkoutCode"
                                        value={embed.checkout}
                                        readOnly={true}
                                        type="text"

                                    />
                                </div>
                                <div className="form-group button-group d-flex justify-content-end">
                                    <button className="demoLink" onClick={() => copyFunction("checkout")}>
                                        {embed.checkoutStatus ? "Copied" : "Copy"}
                                    </button>
                                </div>
                            </div>
                        </>}
                </div>
            </Modal.Body>
        </Modal >

    )
}

export default EmbedModal