import React from "react";
import Navbar from "../Navbar";
import TitleBar from "../TitleBar";
import { Outlet } from "react-router-dom";

const ConversationMain = () => {
   
    return (
        <>
            <TitleBar title="Conversation" />
            <Navbar />
            <section >
                <Outlet />
            </section>

        </>
    )
}

export default ConversationMain;