import React, { useEffect, useState } from 'react'
import { onWriteDoc } from '../../Actions/TrainingAction'
import { useDispatch } from 'react-redux'
import SummerNote from './SummerNote'

const WriteDoc = ({ id, fetchFolders }) => {
    const dispatch = useDispatch()
    const [loader, setLoader] = useState(false)
    const [state, setState] = useState({
        folderId: id,
        name: "",
        content: "",
        type: "write"
    })

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        dispatch(onWriteDoc(state, fetchFolders, setLoader))
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setState({
            ...state,
            [name]: value
        })
    }

    useEffect(() => {
        setState({
            ...state,
            folderId: id
        })
    }, [id])

    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="form-group form-group-alt">
                    <label className="form-text text-muted" htmlFor="">Document Title</label>
                    <input
                        className="form-control"
                        type="text"
                        placeholder="Title"
                        name='name'
                        value={state.name}
                        onChange={handleChange}
                        required
                    />
                </div>
                <label className='p-1 mt-3' htmlFor="">Content</label>
                <div className="form-group form-group-alt m-0 text-light" style={{ border: "1px solid #7900b3", borderRadius: 8 }}>
                    <SummerNote
                        state={state}
                        setState={setState}
                    />
                </div>
                <div className="form-group">
                    <button type="submit" className="demoLink">{loader ? <> Creating <i className="fa fa-spinner fa-spin mx-1" /></> : "Create"}</button>
                </div>

            </form>


        </>
    )
}

export default WriteDoc